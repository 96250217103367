import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../SegmentStore';
import { T } from '../../Translations';
import TokenAdvanceButton from '../Segment/SegmentNavigation/TokenAdvanceButton';
import MeetingDetails from './MeetingDetails';
import * as style from './style.module.css';
import { EventDetails } from './Types';

interface Props {
  eventDetails: EventDetails;
  segmentStore: Maybe<SegmentStore>;
}

const SubmittedButton: React.FC<Props> = ({ eventDetails, segmentStore }) => (
  <div className={style.success}>
    <h1>
      <T kind="Your meeting was successfully scheduled" />
    </h1>
    <MeetingDetails eventDetails={eventDetails} />
    <T kind="Please view the invite sent to your calendar for full details" />
    <div>{segmentStore.map((ss) => <TokenAdvanceButton store={ss} />).getOrElseValue(<></>)}</div>
  </div>
);

export default observer(SubmittedButton);
