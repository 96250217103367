import { assertNever } from '@kofno/piper';
import { action, observable } from 'mobx';
import { TPlainTextKey } from '../../Translations';
import {
  CourseModuleProgressResource,
  State,
  error,
  loading,
  ready,
  readyProgressHidden,
} from './Types';

class CourseModuleProgressStore {
  @observable
  state: State;

  constructor() {
    this.state = loading();
  }

  @action
  ready = (resource: CourseModuleProgressResource) => {
    switch (this.state.kind) {
      case 'loading':
      case 'ready':
        this.state = ready(resource);
        break;
      case 'ready-progress-hidden':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyProgressHidden = () => {
    switch (this.state.kind) {
      case 'ready-progress-hidden':
      case 'loading':
        this.state = readyProgressHidden();
        break;
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };
}

export { CourseModuleProgressStore };
