import * as React from 'react';
import { T } from '../../../Translations';
import CompleteSegmentIcon from '../../Cutesies/CompleteSegmentIcon';
import { IconListItemTitle } from '../../../FreeBusyTestStore/Types';
import * as style from '../style.module.css';

interface Props {
  title: IconListItemTitle;
}

const ToolLibraryListItem: React.FC<Props> = ({ title }) => {
  return (
    <>
      <div className={style.toolLibraryListItem}>
        <div className={style.iconStatus}>
          <CompleteSegmentIcon />
        </div>
        <span>
          <T kind={title} />
        </span>
      </div>
    </>
  );
};

export default ToolLibraryListItem;
