import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { CircleIcon, IconButton } from '../../../../../../components/UI';
import ThemeContext from '../../../../../../Themes/ThemeContext';
import { NavDotPages } from '../CarouselStore/Types';
import { CarouselPage } from '../Types';

interface Props<T> {
  dots: Maybe<NavDotPages<T>>;
  onDotClick: (page: CarouselPage<T>) => void;
}

function NavDots<T>({ dots, onDotClick }: Props<T>) {
  const handleDotClick = (page: CarouselPage<T>) => () => {
    onDotClick(page);
  };

  return dots
    .map(({ succeedingPages, precedingPages }) => (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className="mb-1 mt-6 flex w-full justify-center gap-4">
            {precedingPages.map((page, i) => (
              <IconButton
                key={`preceding-${i}`}
                className="h-4 w-4 rounded-full"
                onClick={handleDotClick(page)}
              >
                <CircleIcon
                  style={{ color: theme.colors['carousel-indicator-color'] }}
                  size="xxs"
                />
              </IconButton>
            ))}
            <IconButton
              className="pointer-events-none h-4 w-4 cursor-default rounded-full"
              tabIndex={-1}
            >
              <CircleIcon
                style={{ color: theme.colors['carousel-indicator-active-color'] }}
                size="xxs"
              />
            </IconButton>
            {succeedingPages.map((page, i) => (
              <IconButton
                key={`succeeding-${i}`}
                className="h-4 w-4 rounded-full"
                onClick={handleDotClick(page)}
              >
                <CircleIcon
                  style={{ color: theme.colors['carousel-indicator-color'] }}
                  size="xxs"
                />
              </IconButton>
            ))}
          </div>
        )}
      </ThemeContext.Consumer>
    ))
    .getOrElse(() => <div className="h-[30px]"></div>);
}

export default observer(NavDots);
