import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '../../../components/UI';
import { T } from '../../../Translations';

const Instructions: React.FC = () => (
  <Typography>
    <T kind="Confirm your ExecOnline registration and accept the Terms of Use to continue." />
  </Typography>
);

export default observer(Instructions);
