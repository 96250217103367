import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../../../Common/Experience/Types';
import ExperiencesStore from '../../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { LearnMoreGroupCoachingHero } from '../../../LearnMoreGroupCoaching';
import { LearnMoreLeadershipHero } from '../../../LearnMoreLeadershipCoaching';
import { LearningJourneyHero } from '../../../LearnMoreLearningJourney';

interface Props {
  experienceResource: ExperienceResource;
  experiencesStore: ExperiencesStore;
}

function ExperienceProductDetails({ experienceResource, experiencesStore }: Props) {
  return experienceResource.payload.productDetails
    .map((productDetails) => {
      switch (productDetails.kind) {
        case 'leadership-coaching':
          return (
            <LearnMoreLeadershipHero
              experiencesStore={experiencesStore}
              experienceResource={experienceResource}
            />
          );
        case 'group-coaching':
          return (
            <LearnMoreGroupCoachingHero
              experienceResource={experienceResource}
              productDetails={productDetails}
            />
          );
        case 'program-sequence':
          return <LearningJourneyHero experienceResource={experienceResource} />;
      }
    })
    .getOrElse(emptyFragment);
}

export default observer(ExperienceProductDetails);
