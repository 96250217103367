import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ChatReplyStore from '../../../ChatReplyStore';
import ConversationStore from '../../../ConversationStore';
import { ChatMessageResource } from '../../../ConversationStore/Types';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import { emptyFragment } from '../../../EmptyFragment';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import LastReadMessageStore from '../../../LastReadMessageStore';
import { PersonStore } from '../../../Person';
import MessageContent from './MessageContent';
import MessageReplies from './MessageReplies';

interface Props {
  personStore: PersonStore;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  messageResource: ChatMessageResource;
  conversationStore: ConversationStore;
  lastReadMessageStore: Maybe<LastReadMessageStore>;
}

class Message extends React.Component<Props> {
  chatReplyStore: ChatReplyStore = new ChatReplyStore();
  render() {
    const {
      personStore,
      presenceStore,
      messageResource,
      conversationStore,
      lastReadMessageStore,
    } = this.props;
    const messageReplyStore = conversationStore.messageReplyStore(messageResource.payload.id);
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            <MessageContent
              personStore={personStore}
              presenceStore={presenceStore}
              message={messageResource.payload}
              messageResource={messageResource}
              chatReplyStore={this.chatReplyStore}
              lastReadMessageStore={lastReadMessageStore}
              currentUserResource={currentUserResource}
            />
            {messageReplyStore
              .map((messageReplyStore) => (
                <MessageReplies
                  messageResource={messageResource}
                  presenceStore={presenceStore}
                  messageReplyStore={messageReplyStore}
                  conversationStore={conversationStore}
                  chatReplyStore={this.chatReplyStore}
                  lastReadMessageStore={lastReadMessageStore}
                />
              ))
              .getOrElse(emptyFragment)}
          </>
        )}
      />
    );
  }
}

export default observer(Message);
