import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import Typography from '../Typography';
import { TypographyProps } from '../Typography/Types';

interface Props extends TypographyProps {}

const CardSubtitle: React.FC<Props> = ({ className, children, ...others }) => {
  return (
    <Typography className={twMerge(clsx('mt-3', className))} variant="h6" {...others}>
      {children}
    </Typography>
  );
};

export default observer(CardSubtitle);
