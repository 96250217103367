import { equals, find } from '@execonline-inc/collections';
import { fromNullable } from 'maybeasy';
import React from 'react';
import DropdownContext from '../DropdownContext';
import { emptyFragment } from '../../../../EmptyFragment';
import { DropdownProps } from '../Types';
import { observer } from 'mobx-react';

const DropdownChildrenItem = ({ children, ...others }: DropdownProps) => {
  const returnMultipleValue = (values: string[], selectedItem: string) => {
    return find(equals(selectedItem), values)
      .map(() => values.filter((item) => item !== selectedItem))
      .getOrElse(() => [...values, selectedItem]);
  };

  const currentElement = (child: React.ReactElement, index: number) => {
    const { multiple, value, onChange } = others;
    const selected = find(
      equals(child.props.value),
      Array.isArray(value) ? value : [value],
    ).isJust();
    return {
      'aria-selected': selected,
      selected,
      autoFocus: equals(index, 0),
      onClick: () => {
        if (multiple) {
          onChange(returnMultipleValue(value, child.props.value));
        } else {
          onChange(child.props.value);
        }
        fromNullable(multiple).elseDo(close);
      },
    };
  };
  return (
    <>
      {React.Children.map(children, (child: React.ReactNode, index) => {
        if (React.isValidElement(child)) {
          return (
            <DropdownContext.Provider value={currentElement(child, index)}>
              {child}
            </DropdownContext.Provider>
          );
        }
        return emptyFragment();
      })}
    </>
  );
};

export default observer(DropdownChildrenItem);
