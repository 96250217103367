import { filter } from '@execonline-inc/collections';
import { JsonValue } from '@execonline-inc/decoders';
import { toUrl } from '@execonline-inc/url';
import { assertNever, pipe } from '@kofno/piper';
import { Kettle } from 'kettle-corn';
import { Emptyable, Maybe, just, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { findLink } from '../LinkyLinky';
import { FlashAlert, errorAlert } from '../Notifications/Types';
import { Link } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';
import { FormFieldAssetResource, FormFieldStore } from '../components/EmbeddedFormFieldAsset/Types';
import {
  Advancer,
  AnyAdvancer,
  AttachmentResource,
  CompletabilityValue,
  CompleteAndAdvancer,
  ConfirmationModal,
  ModulePresentationStyle,
  SegmentResource,
  SegmentState,
  SegmentSubmissionParams,
  advancer,
  advancerForResource,
  advancing,
  advancingTo,
  autoSavingFormFields,
  completeAndAdvancer,
  completing,
  completingAndAdvancing,
  loaded,
  loading,
  noAdvancer,
  processingRequest,
  ready,
  reportingResults,
  scheduleSession,
  submitAndAdvancer,
  submittingAndAdvancing,
  waiting,
} from './Types';
import { urlsEq } from './Url';

const empty = <T extends Emptyable>(ts: T) => ts.length === 0;

const segmentCompletability = (
  completability: CompletabilityValue,
  segmentStore: SegmentStore,
): Maybe<CompleteAndAdvancer> => {
  switch (completability) {
    case 'completable':
      return just(completeAndAdvancer(segmentStore));
    case 'not-completable':
      return nothing();
  }
};

const segmentTypeCompleteAndAdvancer = (segmentStore: SegmentStore): Maybe<CompleteAndAdvancer> => {
  return segmentStore.segmentResource.andThen((segmentResource) => {
    switch (segmentResource.payload.type) {
      case 'overview':
      case 'presentation':
        return segmentResource.payload.status.cata({
          Just: (status) =>
            status === 'Complete' ? nothing() : just(completeAndAdvancer(segmentStore)),
          Nothing: () => just(completeAndAdvancer(segmentStore)),
        });
      case 'external-program':
        return segmentCompletability(segmentResource.payload.completability, segmentStore);
      case 'team-discussion': {
        return segmentResource.payload.teamDiscussion.andThen(() =>
          segmentResource.payload.status.cata({
            Just: (status) =>
              status !== 'Complete' ? just(completeAndAdvancer(segmentStore)) : nothing(),
            Nothing: () => just(completeAndAdvancer(segmentStore)),
          }),
        );
      }
      case 'lecture':
      case 'survey':
      case 'assignment-due':
        return nothing();
    }
  });
};

class SegmentStore {
  @observable
  state: SegmentState;

  @observable
  kettle: Kettle;

  @observable
  embeddedFormFieldAssetStores: FormFieldStore[];

  @observable
  presentationStyle: ModulePresentationStyle;

  enclosureLink = computed(() => findLink('enclosure', this.links), {
    equals: urlsEq,
  });

  @computed
  get enclosureOrigin(): Maybe<string> {
    return this.enclosureLink
      .get()
      .andThen(({ href }) => toUrl(href))
      .map(({ origin }) => origin);
  }

  constructor(presentationStyle: ModulePresentationStyle) {
    this.state = waiting();
    this.kettle = new Kettle();
    this.embeddedFormFieldAssetStores = [];
    this.presentationStyle = presentationStyle;
  }

  @action
  loading = (link: Link) => {
    this.state = loading(link);
  };

  @action
  waiting = () => {
    this.state = waiting();
  };

  @action
  ready = (segmentResource: SegmentResource) => {
    this.state = ready(segmentResource);
  };

  @action
  loaded = (segmentResource: SegmentResource) => {
    this.state = loaded(segmentResource);
  };

  @action
  complete = () => {
    switch (this.state.kind) {
      case 'reporting-results':
      case 'ready': {
        this.state = completing(this.state.segmentResource);
        break;
      }
      case 'processing-request':
      case 'waiting':
      case 'loading':
      case 'error':
      case 'completing':
      case 'advancing':
      case 'loaded':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
      case 'auto-saving-form-fields':
      case 'schedule-session':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  advance = () => {
    switch (this.state.kind) {
      case 'reporting-results':
      case 'auto-saving-form-fields':
      case 'schedule-session':
      case 'ready': {
        this.state = advancing(this.state.segmentResource);
        break;
      }
      case 'processing-request':
      case 'waiting':
      case 'loading':
      case 'error':
      case 'completing':
      case 'advancing':
      case 'loaded':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  advanceTo = (link: Link) => {
    switch (this.state.kind) {
      case 'reporting-results':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
        this.state = advancingTo(this.state.segmentResource, link);
        break;
      case 'ready':
      case 'processing-request':
      case 'waiting':
      case 'loading':
      case 'error':
      case 'completing':
      case 'advancing':
      case 'loaded':
      case 'advancing-to':
      case 'auto-saving-form-fields':
      case 'schedule-session':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  completeAndAdvance = () => {
    switch (this.state.kind) {
      case 'auto-saving-form-fields':
      case 'reporting-results':
      case 'schedule-session':
      case 'ready': {
        this.state = completingAndAdvancing(this.state.segmentResource);
        break;
      }
      case 'processing-request':
      case 'waiting':
      case 'loading':
      case 'error':
      case 'completing':
      case 'advancing':
      case 'loaded':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  submitAndAdvance = (submissionParams: SegmentSubmissionParams) => {
    const state = this.state;
    switch (state.kind) {
      case 'auto-saving-form-fields':
      case 'reporting-results':
      case 'schedule-session':
      case 'ready':
        this.state = submittingAndAdvancing(state.segmentResource, submissionParams);
        break;
      case 'processing-request':
      case 'waiting':
      case 'loading':
      case 'error':
      case 'completing':
      case 'advancing':
      case 'loaded':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
        break;
      default:
        assertNever(state);
    }
  };

  @action
  updateResults = (results: JsonValue) => {
    switch (this.state.kind) {
      case 'ready': {
        const segmentResource = this.state.segmentResource;
        switch (segmentResource.payload.type) {
          case 'presentation':
            segmentResource.payload.results = results;
            this.state = reportingResults(segmentResource);
            break;
          case 'overview':
          case 'assignment-due':
          case 'team-discussion':
          case 'lecture':
          case 'survey':
          case 'external-program':
            break;
          default:
            assertNever(segmentResource.payload);
        }
        break;
      }
      case 'processing-request':
      case 'auto-saving-form-fields':
      case 'reporting-results':
      case 'waiting':
      case 'loading':
      case 'error':
      case 'completing':
      case 'advancing':
      case 'loaded':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
      case 'schedule-session':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  refreshKettle = () => {
    switch (this.state.kind) {
      case 'loaded': {
        this.kettle = new Kettle();
        break;
      }
      case 'waiting':
      case 'loading':
      case 'ready':
      case 'processing-request':
      case 'completing':
      case 'advancing':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
      case 'reporting-results':
      case 'auto-saving-form-fields':
      case 'schedule-session':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  addEmbeddedFormFieldAssetStore = (formFieldStore: FormFieldStore) => {
    switch (this.state.kind) {
      case 'ready': {
        this.embeddedFormFieldAssetStores.push(formFieldStore);
        break;
      }
      case 'reporting-results':
      case 'auto-saving-form-fields':
      case 'processing-request':
      case 'schedule-session':
      case 'waiting':
      case 'loading':
      case 'error':
      case 'completing':
      case 'advancing':
      case 'loaded':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  autoSaveFormFields = () => {
    switch (this.state.kind) {
      case 'processing-request':
      case 'ready': {
        this.state = autoSavingFormFields(this.state.segmentResource);
        break;
      }
      case 'reporting-results':
      case 'auto-saving-form-fields':
      case 'schedule-session':
      case 'waiting':
      case 'loading':
      case 'error':
      case 'completing':
      case 'advancing':
      case 'loaded':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  processRequest = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = processingRequest(this.state.segmentResource);
        break;
      case 'reporting-results':
      case 'auto-saving-form-fields':
      case 'processing-request':
      case 'schedule-session':
      case 'waiting':
      case 'loading':
      case 'error':
      case 'completing':
      case 'advancing':
      case 'loaded':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  scheduleSession = () => {
    switch (this.state.kind) {
      case 'ready': {
        this.state = scheduleSession(this.state);
        break;
      }
      case 'reporting-results':
      case 'auto-saving-form-fields':
      case 'processing-request':
      case 'schedule-session':
      case 'waiting':
      case 'loading':
      case 'error':
      case 'completing':
      case 'advancing':
      case 'loaded':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      case 'loaded':
      case 'waiting':
      case 'loading':
      case 'ready':
      case 'completing':
      case 'advancing':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
      case 'reporting-results':
      case 'auto-saving-form-fields':
      case 'processing-request':
      case 'schedule-session':
        return nothing();
    }
  }

  @computed
  get segmentResource(): Maybe<SegmentResource> {
    switch (this.state.kind) {
      case 'auto-saving-form-fields':
      case 'schedule-session':
      case 'reporting-results':
      case 'ready':
      case 'processing-request':
      case 'advancing':
      case 'loaded':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing-to':
      case 'completing': {
        return just(this.state.segmentResource);
      }
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get title(): Maybe<string> {
    switch (this.state.kind) {
      case 'auto-saving-form-fields':
      case 'schedule-session':
      case 'reporting-results':
      case 'ready':
      case 'processing-request':
      case 'loaded':
      case 'completing':
      case 'completing-and-advancing':
      case 'submitting-and-advancing': {
        return just(this.state.segmentResource.payload.title);
      }
      case 'advancing':
      case 'advancing-to':
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get showCompletionCta(): boolean {
    switch (this.state.kind) {
      case 'auto-saving-form-fields':
      case 'schedule-session':
      case 'reporting-results':
      case 'ready':
      case 'loaded':
      case 'completing':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
        return this.state.segmentResource.payload.showCompletionCta;
      case 'advancing':
      case 'processing-request':
      case 'advancing-to':
      case 'waiting':
      case 'loading':
      case 'error':
        return false;
    }
  }

  @computed
  get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'auto-saving-form-fields':
      case 'schedule-session':
      case 'reporting-results':
      case 'ready':
      case 'processing-request':
      case 'loaded':
      case 'completing':
      case 'advancing':
      case 'completing-and-advancing':
      case 'submitting-and-advancing': {
        return this.state.segmentResource.links;
      }
      case 'loading':
      case 'advancing-to': {
        return [this.state.link];
      }
      case 'waiting':
      case 'error':
        return [];
    }
  }

  @computed
  get confirmationModal(): Maybe<ConfirmationModal> {
    return this.segmentResource.andThen((r) => r.payload.confirmationModal);
  }

  @computed
  get segmentAdvancer(): AnyAdvancer {
    return this.segmentResource
      .map((segmentResource) => {
        switch (segmentResource.payload.type) {
          case 'overview':
            return submitAndAdvancer(this);
          case 'presentation':
          case 'external-program':
          case 'team-discussion':
          case 'lecture':
          case 'survey':
          case 'assignment-due':
            return this.completeAndAdvancable
              .map<AnyAdvancer>(completeAndAdvancer)
              .orElse(() => this.advanceable.map<AnyAdvancer>(advancer))
              .orElse(() => advancerForResource(segmentResource))
              .getOrElse(noAdvancer);
        }
      })
      .getOrElse(noAdvancer);
  }

  @computed
  get tokenSegmentAdvancer(): AnyAdvancer {
    return this.tokenCompleteAndAdvancable
      .map<AnyAdvancer>(completeAndAdvancer)
      .orElse(() => this.advanceable.map<AnyAdvancer>(advancer))
      .orElse(() => this.segmentResource.andThen(advancerForResource))
      .getOrElse(noAdvancer);
  }

  @computed
  get tokenCompleteAndAdvancable(): Maybe<CompleteAndAdvancer> {
    return segmentTypeCompleteAndAdvancer(this);
  }

  @computed
  get completeAndAdvancable(): Maybe<CompleteAndAdvancer> {
    if (!this.showCompletionCta) return nothing();
    return segmentTypeCompleteAndAdvancer(this);
  }

  @computed
  get advanceable(): Maybe<Advancer> {
    return this.segmentResource.cata({
      Just: (segmentResource: SegmentResource) => {
        switch (segmentResource.payload.type) {
          case 'overview':
          case 'assignment-due':
          case 'external-program':
          case 'survey':
          case 'lecture':
          case 'presentation':
            return findLink('next', this.links).cata({
              Just: (next: Link) => {
                return just(advancer(this));
              },
              Nothing: () => {
                return nothing();
              },
            });
          case 'team-discussion':
            return nothing();
        }
      },
      Nothing: () => {
        return nothing();
      },
    });
  }

  @computed
  get attachments(): AttachmentResource[] {
    return this.segmentResource.map((sr) => sr.payload.attachments).getOrElseValue([]);
  }

  @computed
  get results(): Maybe<JsonValue> {
    return this.segmentResource.cata({
      Just: (segmentResource: SegmentResource) => {
        switch (segmentResource.payload.type) {
          case 'presentation':
          case 'overview':
            return just(segmentResource.payload.results);
          case 'assignment-due':
          case 'team-discussion':
          case 'external-program':
          case 'lecture':
          case 'survey':
            return nothing();
        }
      },
      Nothing: () => {
        return nothing();
      },
    });
  }

  @computed
  get embeddedFormFieldAssets(): Maybe<FormFieldAssetResource[]> {
    return this.segmentResource.cata({
      Just: (segmentResource: SegmentResource) => {
        switch (segmentResource.payload.type) {
          case 'overview':
            return just(segmentResource.payload.embeddedFormFieldAssets);
          case 'presentation':
          case 'assignment-due':
          case 'team-discussion':
          case 'external-program':
          case 'lecture':
          case 'survey':
            return nothing();
        }
      },
      Nothing: () => {
        return nothing();
      },
    });
  }

  @computed
  get allFieldsAreComplete(): boolean {
    switch (this.state.kind) {
      case 'ready':
      case 'processing-request':
      case 'auto-saving-form-fields':
        const allFieldsAreComplete = pipe(filter(empty), empty);
        const stateValue = (item: FormFieldStore) => item.fieldValue;
        return allFieldsAreComplete(this.embeddedFormFieldAssetStores.map(stateValue));
      case 'schedule-session':
      case 'reporting-results':
      case 'loaded':
      case 'completing':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'advancing':
      case 'advancing-to':
      case 'waiting':
      case 'loading':
      case 'error':
        return false;
    }
  }

  @computed
  get sessionScheduled(): boolean {
    return this.segmentResource.cata({
      Just: (segmentResource: SegmentResource) => {
        switch (segmentResource.payload.type) {
          case 'overview':
            return segmentResource.payload.sessionScheduled;
          case 'presentation':
          case 'assignment-due':
          case 'team-discussion':
          case 'external-program':
          case 'lecture':
          case 'survey':
            return false;
        }
      },
      Nothing: () => {
        return false;
      },
    });
  }

  @computed
  get isFreebusyCoaching(): boolean {
    return this.segmentResource.cata({
      Just: (segmentResource: SegmentResource) => {
        switch (segmentResource.payload.type) {
          case 'overview':
            return segmentResource.payload.isFreebusyCoaching;
          case 'presentation':
          case 'assignment-due':
          case 'team-discussion':
          case 'external-program':
          case 'lecture':
          case 'survey':
            return false;
        }
      },
      Nothing: () => {
        return false;
      },
    });
  }
}

export default SegmentStore;
