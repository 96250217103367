import React, { HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface Props extends HTMLAttributes<HTMLDivElement> {}

const SectionFooter: React.FC<Props> = ({ className, children, ...others }) => {
  return (
    <div className={twMerge(clsx('flex justify-start gap-2', className))} {...others}>
      {children}
    </div>
  );
};

export default observer(SectionFooter);
