import { assertNever } from '@kofno/piper';
import { nothing } from 'maybeasy';
import { err, ok, Result } from 'resulty';
import {
  ConjointAnalysisEmbeddable,
  ConjointAnalysisNode,
} from '../../../components/ConjointAnalysis/Types';
import {
  ConjointAnalysisResultsEmbeddable,
  ConjointAnalysisResultsNode,
} from '../../../components/ConjointAnalysisResults/Types';
import {
  AlertEmbeddable,
  AlertNode,
  AnnouncementVideoNode,
  DigitalCertificateResourceEmbeddable,
  EmbeddableNode,
  EmbeddedDocumentEmbeddable,
  EmbeddedDocumentNode,
  ExpertFeedbackNodeEmbeddable,
  FreebusyEmbeddable,
  LearningPartnerAssignmentEmbeddable,
  LearningPartnersEmebeddable,
  LinkedDocumentEmbeddable,
  LinkedDocumentNode,
  ProgramChatLinkEmbeddable,
  ProgramChatLinkNode,
  ScriptEmbeddable,
  ScriptTag,
  SharedResourceEmbeddable,
  TeamLinkEmbeddable,
  TeamMembersEmebeddable,
} from '../../../components/ContentEmbed/Types';
import {
  DiscoveryPortalOpenInviteUuidEmbeddable,
  DiscoveryPortalOpenInviteUuidNode,
} from '../../../components/DiscoveryPortalOpenInviteUuid/Types';
import {
  CoachingSurveyButtonEmbeddable,
  CoachingSurveyButtonNode,
} from '../../../components/Embeddables/CoachingSurveyButtonEmbeddable/Types';
import { FreebusyConfig } from '../../../components/Freebusy/Types';
import { LearningPartnersNode } from '../../../components/LearningPartners/Types';
import {
  LiveForumDatesEmbeddable,
  LiveForumDatesNode,
} from '../../../components/LiveForumDates/Types';
import {
  NextExperiencesEmbeddable,
  NextExperiencesNode,
} from '../../../components/NextExperiences/Types';
import {
  SectionEventDatesEmbeddable,
  SectionEventDatesNode,
} from '../../../components/SectionEventDates/Types';
import { TeamMembersNode } from '../../../components/TeamMembers/Types';
import { AnnouncementVideoAssetResource } from '../../../components/Video/Types';
import ConjointAnalysisStore from '../../../ConjointAnalysisStore';
import { TeamLinkNode } from '../../../TeamsStore/Types';
import {
  AnnouncementResource,
  CoachingSurveyAnnouncement,
  DigitalCertificateAnnouncement,
  EmbeddableAssetsAnnouncement,
  ExpertFeedbackAnnouncement,
  LearningPartnerAssignmentAnnouncement,
  SharedResourceAnnouncement,
} from '../../Decoders/Types';

export interface LearningPartnerAssignmentNode {
  kind: 'learning-partner-assignment';
}

export interface SharedResourceNode {
  kind: 'shared-resource';
}

export interface DigitalCertificateResourceNode {
  kind: 'digital-certificate-resource';
}

export interface AnnouncementVideoEmbeddable {
  kind: 'announcement-video-embeddable';
  node: AnnouncementVideoNode;
  assets: AnnouncementVideoAssetResource[];
}

export interface ExpertFeedbackNode {
  kind: 'expert-feedback-node';
}

export type AnnouncementEmbeddableNode =
  | AlertNode
  | ConjointAnalysisNode
  | ConjointAnalysisResultsNode
  | DiscoveryPortalOpenInviteUuidNode
  | EmbeddedDocumentNode
  | FreebusyConfig
  | LearningPartnerAssignmentNode
  | LearningPartnersNode
  | ExpertFeedbackNode
  | LinkedDocumentNode
  | LiveForumDatesNode
  | NextExperiencesNode
  | ScriptTag
  | SectionEventDatesNode
  | SharedResourceNode
  | TeamLinkNode
  | TeamMembersNode
  | DigitalCertificateResourceNode
  | AnnouncementVideoNode
  | CoachingSurveyButtonNode
  | ProgramChatLinkNode;

export const whenAnnouncementEmbeddableNode = (
  node: EmbeddableNode
): Result<string, AnnouncementEmbeddableNode> => {
  switch (node.kind) {
    case 'alert-node':
    case 'announcement-video-node':
    case 'conjoint-analysis':
    case 'conjoint-analysis-results':
    case 'discovery-portal-open-invite-uuid':
    case 'embedded-document-node':
    case 'expert-feedback-node':
    case 'freebusy-program':
    case 'freebusy-coaching':
    case 'learning-partner-assignment':
    case 'learning-partners':
    case 'linked-document-node':
    case 'live-forum-dates-node':
    case 'script-tag':
    case 'section-event-dates-node':
    case 'shared-resource':
    case 'digital-certificate-resource':
    case 'team-link-node':
    case 'team-members':
    case 'discovery-portal-choose-next':
    case 'coaching-survey-button-node':
    case 'program-chat-link-node':
      return ok(node);
    case 'embedded-form-field-node':
    case 'coaching-selected-coach-name-node':
    case 'coaching-selected-coach-profile-node':
    case 'display-assigned-coach-node':
    case 'segment-video-node':
      return err(`'${node.kind}' is not an announcement embeddable node`);
    default:
      assertNever(node);
  }
};

export type AnnouncementEmbeddable =
  | AlertEmbeddable
  | AnnouncementVideoEmbeddable
  | ConjointAnalysisEmbeddable
  | ConjointAnalysisResultsEmbeddable
  | DiscoveryPortalOpenInviteUuidEmbeddable
  | EmbeddedDocumentEmbeddable
  | FreebusyEmbeddable
  | LearningPartnerAssignmentEmbeddable
  | LearningPartnersEmebeddable
  | ExpertFeedbackNodeEmbeddable
  | LinkedDocumentEmbeddable
  | LiveForumDatesEmbeddable
  | NextExperiencesEmbeddable
  | ScriptEmbeddable
  | SectionEventDatesEmbeddable
  | SharedResourceEmbeddable
  | TeamLinkEmbeddable
  | DigitalCertificateResourceEmbeddable
  | TeamMembersEmebeddable
  | CoachingSurveyButtonEmbeddable
  | ProgramChatLinkEmbeddable;

const whenEmbeddableAssetAnnouncement = ({
  payload,
}: AnnouncementResource): Result<string, EmbeddableAssetsAnnouncement> => {
  switch (payload.kind) {
    case 'embeddable-assets-announcement':
      return ok(payload);
    case 'learning-partner-assignment-announcement':
    case 'expert-feedback-announcement':
    case 'upload-manifest-announcement':
    case 'shared-to-personalized-resources-announcement':
    case 'shared-to-live-meeting-recordings-announcement':
    case 'digital-certificate-announcement':
    case 'coaching-survey-announcement':
      return err(
        `Expected to find kind embeddable-assets-announcement but found '${payload.kind}'`
      );
  }
};

const whenLearningPartnerAssignmentAnnouncement = ({
  payload,
}: AnnouncementResource): Result<string, LearningPartnerAssignmentAnnouncement> => {
  switch (payload.kind) {
    case 'embeddable-assets-announcement':
    case 'expert-feedback-announcement':
    case 'upload-manifest-announcement':
    case 'shared-to-personalized-resources-announcement':
    case 'digital-certificate-announcement':
    case 'shared-to-live-meeting-recordings-announcement':
    case 'coaching-survey-announcement':
      return err(
        `Expected to find kind learning-partner-assignment-announcement but found '${payload.kind}'`
      );
    case 'learning-partner-assignment-announcement':
      return ok(payload);
  }
};

const whenExpertFeedbackAnnouncement = ({
  payload,
}: AnnouncementResource): Result<string, ExpertFeedbackAnnouncement> => {
  switch (payload.kind) {
    case 'embeddable-assets-announcement':
    case 'learning-partner-assignment-announcement':
    case 'upload-manifest-announcement':
    case 'shared-to-personalized-resources-announcement':
    case 'shared-to-live-meeting-recordings-announcement':
    case 'digital-certificate-announcement':
    case 'coaching-survey-announcement':
      return err(`Expected to find kind expert-feedback-announcement but found '${payload.kind}'`);
    case 'expert-feedback-announcement':
      return ok(payload);
  }
};

const whenSharedResourceAnnouncement = ({
  payload,
}: AnnouncementResource): Result<string, SharedResourceAnnouncement> => {
  switch (payload.kind) {
    case 'embeddable-assets-announcement':
    case 'learning-partner-assignment-announcement':
    case 'upload-manifest-announcement':
    case 'expert-feedback-announcement':
    case 'digital-certificate-announcement':
    case 'coaching-survey-announcement':
      return err(
        `Expected to find kind a shared resource announcement but found '${payload.kind}'`
      );
    case 'shared-to-personalized-resources-announcement':
    case 'shared-to-live-meeting-recordings-announcement':
      return ok(payload);
  }
};

const whenDigitalCertificateResourceAnnouncement = ({
  payload,
}: AnnouncementResource): Result<string, DigitalCertificateAnnouncement> => {
  switch (payload.kind) {
    case 'embeddable-assets-announcement':
    case 'learning-partner-assignment-announcement':
    case 'upload-manifest-announcement':
    case 'expert-feedback-announcement':
    case 'shared-to-live-meeting-recordings-announcement':
    case 'shared-to-personalized-resources-announcement':
    case 'coaching-survey-announcement':
      return err(
        `Expected to find kind a shared resource announcement but found '${payload.kind}'`
      );
    case 'digital-certificate-announcement':
      return ok(payload);
  }
};

const whenCoachingSurveyResourceAnnouncement = ({
  payload,
}: AnnouncementResource): Result<string, CoachingSurveyAnnouncement> => {
  switch (payload.kind) {
    case 'embeddable-assets-announcement':
    case 'learning-partner-assignment-announcement':
    case 'upload-manifest-announcement':
    case 'expert-feedback-announcement':
    case 'shared-to-live-meeting-recordings-announcement':
    case 'shared-to-personalized-resources-announcement':
    case 'digital-certificate-announcement':
      return err(`Expected to find kind coaching-survey-announcement but found '${payload.kind}'`);
    case 'coaching-survey-announcement':
      return ok(payload);
  }
};

export const embeddable = (
  node: AnnouncementEmbeddableNode,
  announcementResource: AnnouncementResource
): Result<string, AnnouncementEmbeddable> => {
  switch (node.kind) {
    case 'alert-node':
      return ok({
        kind: 'alert-embeddable',
        node: node,
      });
    case 'conjoint-analysis':
      return ok({
        kind: 'conjoint-analysis-embeddable',
        node: node,
        segmentStore: nothing(),
        store: new ConjointAnalysisStore(),
      });
    case 'conjoint-analysis-results':
      return ok({
        kind: 'conjoint-analysis-results-embeddable',
        node: node,
      });
    case 'discovery-portal-open-invite-uuid':
      return ok({
        kind: 'discovery-portal-open-invite-uuid-embeddable',
        node: node,
      });
    case 'embedded-document-node':
      return whenEmbeddableAssetAnnouncement(announcementResource).map((payload) => ({
        kind: 'embedded-document-embeddable',
        node: node,
        assets: payload.embeddedDocumentAssets,
      }));
    case 'freebusy-program':
    case 'freebusy-coaching':
      return ok({
        kind: 'freebusy-embeddable',
        node: node,
        segmentStore: nothing(),
        links: nothing(),
      });
    case 'learning-partner-assignment':
      return whenLearningPartnerAssignmentAnnouncement(announcementResource).map((payload) => ({
        kind: 'learning-partner-assignment-embeddable',
        node: node,
        assignment: payload.embeddedAssignment.payload,
      }));
    case 'expert-feedback-node':
      return whenExpertFeedbackAnnouncement(announcementResource).map((payload) => ({
        kind: 'expert-feedback-embeddable',
        node: node,
        feedback: payload.embeddedFeedback,
      }));
    case 'learning-partners':
      return ok({
        kind: 'learning-partners-embeddable',
        node: node,
      });
    case 'linked-document-node':
      return ok({
        kind: 'linked-document-embeddable',
        node: node,
        links: announcementResource.links,
      });
    case 'live-forum-dates-node':
      return ok({
        kind: 'live-forum-dates-embeddable',
        node: node,
      });
    case 'discovery-portal-choose-next':
      return ok({
        kind: 'discovery-portal-choose-next-embeddable',
        node: node,
      });
    case 'script-tag':
      return ok({
        kind: 'script-embeddable',
        node: node,
      });
    case 'section-event-dates-node':
      return ok({
        kind: 'section-event-dates-embeddable',
        node: node,
      });
    case 'shared-resource':
      return whenSharedResourceAnnouncement(announcementResource).map((payload) => ({
        kind: 'shared-resource-embeddable',
        node: node,
        sharedResourceAnnouncement: payload,
      }));
    case 'digital-certificate-resource':
      return whenDigitalCertificateResourceAnnouncement(announcementResource).map((payload) => ({
        kind: 'digital-certificate-resource-embeddable',
        node: node,
        digitalCertificateAnnouncement: payload,
      }));
    case 'team-link-node':
      return ok({
        kind: 'team-link-embeddable',
        node: node,
      });
    case 'team-members':
      return ok({
        kind: 'team-members-embeddable',
        node: node,
      });
    case 'announcement-video-node':
      return whenEmbeddableAssetAnnouncement(announcementResource).map((payload) => ({
        kind: 'announcement-video-embeddable',
        node: node,
        assets: payload.announcementVideoAssets,
      }));
    case 'coaching-survey-button-node':
      return whenCoachingSurveyResourceAnnouncement(announcementResource).map(() => ({
        kind: 'coaching-survey-button-embeddable',
        node: node,
      }));
    case 'program-chat-link-node':
      return ok({
        kind: 'program-chat-link-embeddable',
        node: node,
      });
  }
};
