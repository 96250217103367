import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import I18nTitle from '../../Title/I18nTitle';
import { T } from '../../Translations';
import LogoGraphic from '../Cutesies/LogoGraphic';
import CompleteSegmentIcon from '../Cutesies/CompleteSegmentIcon';
import * as style from './style.module.css';

const Confirmation: React.FC = () => {
  return (
    <>
      <I18nTitle title="FreeBusy Requirements Check" />
      <Main className={style.wrapper}>
        <div className={style.subWrapper}>
          <div className={style.logo}>
            <LogoGraphic />
          </div>
          <div className={style.confirmation}>
            <div className={style.confirmationIconStatus}>
              <CompleteSegmentIcon />
            </div>
            <h1 className={style.confirmationTitle}>
              <T kind="Test Successful" />
            </h1>
            <p>
              <T kind="You have successfully completed the test ..." />
            </p>
          </div>
        </div>
      </Main>
    </>
  );
};

export default Confirmation;
