import { observer } from 'mobx-react';
import * as React from 'react';
import { ExpiredProgram } from '../../ProgramStore/Types';
import { Link } from '../../Resource/Types';
import { AlreadyTranslated } from '../../Translations';
import * as style from './style.module.css';
import Linkable from '../Linkable';

interface Props {
  programDetail: ExpiredProgram;
  links: ReadonlyArray<Link>;
}

const ExpiredProgramOverview: React.FC<Props> = ({ programDetail, links }) => (
  <div className={style.program}>
    <div className={style.container}>
      <h3 className={style.title} data-test-program-title={programDetail.title.text}>
        <AlreadyTranslated content={programDetail.title} />
      </h3>
      <Linkable linkables={programDetail.linkables} />
    </div>
  </div>
);

export default observer(ExpiredProgramOverview);
