import { assertNever } from '@kofno/piper';
import { action, computed, observable } from 'mobx';
import SegmentStore from '../../../../../../../SegmentStore';
import { SelectFieldValue, SelectFormField } from '../../../../../Types';
import { autoSaving, initializing, ready, State, waiting } from './Types';
import { convertToSelectFieldValue } from '../../Helpers';

class CheckboxSelectFieldStore {
  @observable
  state: State = waiting();

  constructor(public readonly formFieldId: string) {}

  @action
  initialize = (segmentStore: SegmentStore, formField: SelectFormField) => {
    this.state = initializing(segmentStore, formField);
  };

  @action
  ready = (formField: SelectFormField, value: SelectFieldValue[]) => {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
      case 'initializing':
        this.state = ready(formField, value);
        break;
      case 'waiting':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  addValue = (value: SelectFieldValue) => {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        const fieldValue = this.fieldValue
          .filter((v) => v.label !== value.label)
          .concat(convertToSelectFieldValue(value.label, value.value));
        this.state = ready(this.state.formField, fieldValue);
        break;
      case 'waiting':
      case 'initializing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  removeValue = (value: SelectFieldValue) => {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        this.state = ready(
          this.state.formField,
          this.fieldValue.filter((v) => v.label !== value.label),
        );
        break;
      case 'waiting':
      case 'initializing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  autoSave = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = autoSaving(this.state.formField, this.state.value);
        break;
      case 'auto-saving':
      case 'waiting':
      case 'initializing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  autoSaveComplete = () => {
    switch (this.state.kind) {
      case 'auto-saving':
        this.state = ready(this.state.formField, this.state.value);
        break;
      case 'ready':
      case 'waiting':
      case 'initializing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get fieldValue(): SelectFieldValue[] {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        return this.state.value;
      case 'waiting':
      case 'initializing':
        return [];
      default:
        assertNever(this.state);
    }
  }

  @computed
  get isRequired(): boolean {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        return this.state.formField.mode === 'required';
      case 'waiting':
      case 'initializing':
        return false;
      default:
        assertNever(this.state);
    }
  }

  fieldHasValue = (value: SelectFieldValue): boolean =>
    this.fieldValue.some((v) => v.label === value.label);
}

export default CheckboxSelectFieldStore;
