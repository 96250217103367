import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../../../../SegmentStore';
import AutoSaveReactions from '../../../../Reactions/AutoSaveReactions';
import { SelectFieldValue, SelectFormField } from '../../../../Types';
import * as style from './style.module.css';
import CheckboxSelectFieldStore from './Store';
import CheckboxSelectReactions from './Reactions';
import Legend from '../../../Legend';
import OptionWithStringInput from './OptionWithStringInput';
import { assertNever } from '@kofno/piper';

interface Props {
  segmentStore: SegmentStore;
  fieldDescription: SelectFormField;
  uuid: string;
}

class CheckboxesSelect extends React.Component<Props> {
  selectFieldStore: CheckboxSelectFieldStore = new CheckboxSelectFieldStore(this.props.uuid);

  componentDidMount() {
    this.selectFieldStore.initialize(this.props.segmentStore, this.props.fieldDescription);
  }

  onValueChange = (option: SelectFieldValue) => {
    this.selectFieldStore.fieldHasValue(option)
      ? this.selectFieldStore.removeValue(option)
      : this.selectFieldStore.addValue(option);
    this.selectFieldStore.autoSave();
  };

  render() {
    switch (this.selectFieldStore.state.kind) {
      case 'waiting':
      case 'initializing':
        return <CheckboxSelectReactions store={this.selectFieldStore} fireImmediately={true} />;
      case 'ready':
      case 'auto-saving':
        const { fieldDescription } = this.props;
        return (
          <fieldset className={style.checkboxFieldset}>
            <Legend label={fieldDescription.label} />
            {fieldDescription.options.map((option) => {
              switch (option.kind) {
                case 'string':
                  return (
                    <OptionWithStringInput
                      key={option.name}
                      selectFieldStore={this.selectFieldStore}
                      stringFormField={option}
                    />
                  );
                case 'base-option':
                case 'coach-option':
                  return (
                    <label key={option.value} className={style.checkboxLabel}>
                      <input
                        name={option.value}
                        type="checkbox"
                        data-test-checkbox={option.value}
                        onChange={() => this.onValueChange(option)}
                        checked={this.selectFieldStore.fieldHasValue(option)}
                        className={style.checkboxField}
                      />
                      {option.label}
                    </label>
                  );
                default:
                  assertNever(option);
              }
            })}
            <AutoSaveReactions
              store={this.selectFieldStore}
              segmentStore={this.props.segmentStore}
              debounceDelay={1000}
            />
            <CheckboxSelectReactions store={this.selectFieldStore} />
          </fieldset>
        );
    }
  }
}

export default observer(CheckboxesSelect);
