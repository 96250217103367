import { always, identity } from '@kofno/piper';
import { whenAny } from '../../../../../Collections';
import { Experience } from '../Types';

export const getTimeCommitment = (experience: Experience) => {
  const { duration, hoursPerWeek, totalHours } = experience;

  return whenAny(identity, [duration, hoursPerWeek, totalHours]).map(
    always({ duration, hoursPerWeek, totalHours }),
  );
};
