import { observer } from 'mobx-react';
import * as React from 'react';
import ChannelAvailabilityStore from '../../../../../../../Socket/ChannelAvailabilityStore';
import PresenceChannel from '../../../../../../../Socket/PresenceChannel';
import PaymentConfirmationStore from '../../../Store';
import PresenceHandler from './PresenceHandler';

interface Props {
  channelName: string;
  store: PaymentConfirmationStore;
}

class PaymentPresence extends React.Component<Props, {}> {
  private channelAvailabilityStore: ChannelAvailabilityStore = new ChannelAvailabilityStore();

  render() {
    return (
      <>
        <PresenceChannel
          name={this.props.channelName}
          channelAvailabilityStore={this.channelAvailabilityStore}
        />
        <PresenceHandler
          channelAvailabilityStore={this.channelAvailabilityStore}
          store={this.props.store}
        />
        {this.props.children}
      </>
    );
  }
}

export default observer(PaymentPresence);
