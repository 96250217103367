import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import Certificate from './Certificate';
import CourseAvailabilityPerWeek from './CourseAvailabilityPerWeek';
import DownloadOverview from './DownloadOverview';
import TotalHour from './TotalHour';
import { getTitleVariant } from './Types';
import { ExperienceResource } from '../../../Common/Experience/Types';
import { useMediaQuery } from '../../../../../components/UI';
import LanguageAvailability from '../LanguageAvailability';
import { T } from '../../../../../Translations';
import { getTimeCommitment } from '../../../Common/Experience/TimeCommitment/Types';

interface Props {
  experienceResource: ExperienceResource;
}

function OverView({ experienceResource }: Props) {
  const breakPoint = useMediaQuery();
  const timeCommitment = getTimeCommitment(experienceResource.payload);

  return (
    <div className="flex flex-col gap-y-4 md:gap-y-6">
      <Typography className="text-gray-900" variant={getTitleVariant(breakPoint)} fontWeight="bold">
        <T kind="Overview" />
      </Typography>
      <div className="flex flex-wrap gap-x-14 gap-y-5">
        {timeCommitment
          .map(({ totalHours, duration, hoursPerWeek }) => {
            return (
              <div className="flex flex-col gap-y-5">
                <>
                  <CourseAvailabilityPerWeek duration={duration} hoursPerWeek={hoursPerWeek} />
                  <TotalHour totalHours={totalHours} />
                </>
                <Certificate experienceResource={experienceResource} />
              </div>
            );
          })
          .getOrElse(() => (
            <Certificate experienceResource={experienceResource} />
          ))}
        <div className="flex flex-col gap-y-5">
          <DownloadOverview experienceResource={experienceResource} />
          <LanguageAvailability experienceResource={experienceResource} className="text-gray-600" />
        </div>
      </div>
    </div>
  );
}
export default observer(OverView);
