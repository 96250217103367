import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import ListItem from './ListItem';
import ListItemAvatar from './ListItemAvatar';
import ListItemDivider from './ListItemDivider';
import ListItemText from './ListItemText';

interface Props extends HTMLAttributes<HTMLUListElement> {}

const List: React.FC<Props> = ({ className, children, ...others }) => {
  return (
    <ul className={twMerge(clsx('flex flex-col list-none m-0', className))} {...others}>
      {children}
    </ul>
  );
};

export default Object.assign(List, {
  Item: ListItem,
  ItemAvatar: ListItemAvatar,
  ItemDivider: ListItemDivider,
  ItemText: ListItemText,
});
