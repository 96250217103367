import { observer } from 'mobx-react';
import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import {
  BeginnableAction,
  ResumableAction,
  ReturnableAction,
  EnrollableAction,
  GraduatedAction,
  NotEnrollableAction,
  UpcomingAction,
} from '../../Experience/ExperienceActions';
import { useExperiencePopoverContext } from '../ExperiencePopoverContext';

interface Props {
  buttonProps?: ButtonProps;
}

function ExperienceActions({ buttonProps }: Props) {
  const {
    experienceResource: { payload: experience },
    invitationUuid,
    experiencesStore,
    onClose,
  } = useExperiencePopoverContext();

  switch (experience.kind) {
    case 'beginnable':
      return <BeginnableAction experience={experience} buttonProps={buttonProps} />;
    case 'commerce-enrollable':
    case 'enrollable':
      return (
        <EnrollableAction
          experience={experience}
          buttonProps={{ onClick: onClose, ...buttonProps }}
        />
      );
    case 'not-enrollable':
      return <NotEnrollableAction experience={experiencesStore.notEnrollableAlert} />;
    case 'resumable':
      return <ResumableAction experience={experience} buttonProps={buttonProps} />;
    case 'upcoming':
      return (
        <UpcomingAction
          invitationUuid={invitationUuid}
          experience={experience}
          buttonProps={buttonProps}
        />
      );
    case 'graduated':
      return <GraduatedAction />;
    case 'returnable':
      return <ReturnableAction experience={experience} buttonProps={buttonProps} />;
    case 'not-licensed':
      return emptyFragment();
  }
}

export default observer(ExperienceActions);
