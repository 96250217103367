import { Maybe } from 'maybeasy';
import { AlreadyTranslatedText, TPlainTextKey } from '../../../../Translations';

export interface Waiting {
  kind: 'waiting';
}

export interface Ready {
  kind: 'ready';
  password: string;
  passwordConfirmation: string;
  termsAcceptance: boolean;
  message: Maybe<TPlainTextKey>;
}

export interface ReadyWithClientErrors {
  kind: 'ready-with-client-errors';
  password: string;
  passwordConfirmation: string;
  termsAcceptance: boolean;
  message: TPlainTextKey;
}

export interface ReadyWithServerErrors {
  kind: 'ready-with-server-errors';
  password: string;
  passwordConfirmation: string;
  termsAcceptance: boolean;
  message: AlreadyTranslatedText;
}

export interface Creating {
  kind: 'creating';
  password: string;
}

export interface Created {
  kind: 'created';
}

export interface Error {
  kind: 'error';
  message: TPlainTextKey;
  termsAcceptance: boolean;
}

export interface ServerError {
  kind: 'server-error';
  message: AlreadyTranslatedText;
  termsAcceptance: boolean;
}

export type State =
  | Waiting
  | Ready
  | ReadyWithClientErrors
  | ReadyWithServerErrors
  | Creating
  | Created
  | Error
  | ServerError;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const ready = (termsAcceptance: boolean, message: Maybe<TPlainTextKey>): Ready => ({
  kind: 'ready',
  password: '',
  passwordConfirmation: '',
  termsAcceptance,
  message,
});

export const readyWithClientErrors = (
  termsAcceptance: boolean,
  message: TPlainTextKey
): ReadyWithClientErrors => ({
  kind: 'ready-with-client-errors',
  password: '',
  passwordConfirmation: '',
  termsAcceptance,
  message,
});

export const readyWithServerErrors = (
  termsAcceptance: boolean,
  message: AlreadyTranslatedText
): ReadyWithServerErrors => ({
  kind: 'ready-with-server-errors',
  password: '',
  passwordConfirmation: '',
  termsAcceptance,
  message,
});

export const creating = (password: string): Creating => ({
  kind: 'creating',
  password,
});

export const created = (): Created => ({
  kind: 'created',
});

export const error = (message: TPlainTextKey, termsAcceptance: boolean): Error => ({
  kind: 'error',
  message,
  termsAcceptance,
});

export const serverError = (
  message: AlreadyTranslatedText,
  termsAcceptance: boolean
): ServerError => ({
  kind: 'server-error',
  message,
  termsAcceptance,
});
