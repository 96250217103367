import { Maybe } from 'maybeasy';
import * as React from 'react';
import { NotTranslated } from '../../../Translations';
import * as style from '../style.module.css';

interface Props {
  title: Maybe<string>;
}

const Title: React.FC<Props> = ({ title }) => {
  return title
    .map((t) => {
      return (
        <h1 className={style.title} data-test-segment-title={t}>
          <NotTranslated text={t} />
        </h1>
      );
    })
    .getOrElseValue(<span />);
};
export default Title;
