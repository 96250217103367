import { PopoverPlacement } from '@execonline-inc/execonline-ui';
import { useMediaQuery } from '../../../../../components/UI';

function useProgramPopoverPlacement(): PopoverPlacement {
  const breakPoint = useMediaQuery();

  switch (breakPoint) {
    case 'xl':
    case 'lg':
    case 'md':
      return 'right';
    case 'sm':
    case 'xs':
      return 'top';
  }
}

export default useProgramPopoverPlacement;
