import { assertNever } from '@kofno/piper';
import { just, Maybe } from 'maybeasy';
import { err, ok, Result } from 'resulty';
import ConjointAnalysisStore from '../../ConjointAnalysisStore';
import { Link } from '../../Resource/Types';
import SegmentStore from '../../SegmentStore';
import { EmbeddableSegment, LegacyAndNewVideoAssetResource } from '../../SegmentStore/Types';
import { TeamLinkNode } from '../../TeamsStore/Types';
import { ConjointAnalysisEmbeddable, ConjointAnalysisNode } from '../ConjointAnalysis/Types';
import {
  ConjointAnalysisResultsEmbeddable,
  ConjointAnalysisResultsNode,
} from '../ConjointAnalysisResults/Types';
import {
  AlertEmbeddable,
  AlertNode,
  AnnouncementVideoNode,
  CoachingSelectedCoachNameEmbeddable,
  CoachingSelectedCoachNameNode,
  CoachingSelectedCoachProfileEmbeddable,
  CoachingSelectedCoachProfileNode,
  DisplayAssignedCoachEmbeddable,
  DisplayAssignedCoachNode,
  EmbeddableNode,
  EmbeddedDocumentEmbeddable,
  EmbeddedDocumentNode,
  EmbeddedFormFieldEmbeddable,
  EmbeddedFormFieldNode,
  FreebusyEmbeddable,
  LearningPartnersEmebeddable,
  LinkedDocumentEmbeddable,
  LinkedDocumentNode,
  ProgramChatLinkEmbeddable,
  ProgramChatLinkNode,
  ScriptEmbeddable,
  ScriptTag,
  SegmentVideoNode,
  TeamLinkEmbeddable,
  TeamMembersEmebeddable,
} from '../ContentEmbed/Types';
import {
  DiscoveryPortalOpenInviteUuidEmbeddable,
  DiscoveryPortalOpenInviteUuidNode,
} from '../DiscoveryPortalOpenInviteUuid/Types';
import { FreebusyConfig } from '../Freebusy/Types';
import { LearningPartnersNode } from '../LearningPartners/Types';
import { LiveForumDatesEmbeddable, LiveForumDatesNode } from '../LiveForumDates/Types';
import { NextExperiencesEmbeddable, NextExperiencesNode } from '../NextExperiences/Types';
import { SectionEventDatesEmbeddable, SectionEventDatesNode } from '../SectionEventDates/Types';
import { TeamMembersNode } from '../TeamMembers/Types';
import { VideoAssetResource } from '../Video/Types';

export interface SegmentVideoEmbeddable {
  kind: 'segment-video-embeddable';
  node: SegmentVideoNode;
  assets: LegacyAndNewVideoAssetResource[];
}
export interface AnnouncementVideoEmbeddable {
  kind: 'announcement-video-embeddable';
  node: AnnouncementVideoNode;
  assets: VideoAssetResource[];
}

export type SegmentEmbeddableNode =
  | AlertNode
  | ConjointAnalysisNode
  | ConjointAnalysisResultsNode
  | DiscoveryPortalOpenInviteUuidNode
  | EmbeddedDocumentNode
  | FreebusyConfig
  | LearningPartnersNode
  | LinkedDocumentNode
  | LiveForumDatesNode
  | NextExperiencesNode
  | ScriptTag
  | SectionEventDatesNode
  | TeamLinkNode
  | TeamMembersNode
  | SegmentVideoNode
  | EmbeddedFormFieldNode
  | CoachingSelectedCoachNameNode
  | CoachingSelectedCoachProfileNode
  | DisplayAssignedCoachNode
  | ProgramChatLinkNode;

export type SegmentEmbeddable =
  | AlertEmbeddable
  | ConjointAnalysisEmbeddable
  | ConjointAnalysisResultsEmbeddable
  | DiscoveryPortalOpenInviteUuidEmbeddable
  | EmbeddedDocumentEmbeddable
  | FreebusyEmbeddable
  | LearningPartnersEmebeddable
  | LinkedDocumentEmbeddable
  | LiveForumDatesEmbeddable
  | NextExperiencesEmbeddable
  | ScriptEmbeddable
  | SectionEventDatesEmbeddable
  | SegmentVideoEmbeddable
  | TeamLinkEmbeddable
  | TeamMembersEmebeddable
  | EmbeddedFormFieldEmbeddable
  | CoachingSelectedCoachNameEmbeddable
  | CoachingSelectedCoachProfileEmbeddable
  | DisplayAssignedCoachEmbeddable
  | ProgramChatLinkEmbeddable;

export interface Props {
  segment: EmbeddableSegment;
  node: SegmentEmbeddableNode;
  segmentStore: Maybe<SegmentStore>;
  links: ReadonlyArray<Link>;
}

export const embeddable = ({ segment, node, segmentStore, links }: Props): SegmentEmbeddable => {
  switch (node.kind) {
    case 'alert-node':
      return {
        kind: 'alert-embeddable',
        node: node,
      };
    case 'conjoint-analysis':
      return {
        kind: 'conjoint-analysis-embeddable',
        node: node,
        segmentStore: segmentStore,
        store: new ConjointAnalysisStore(),
      };
    case 'conjoint-analysis-results':
      return {
        kind: 'conjoint-analysis-results-embeddable',
        node: node,
      };
    case 'discovery-portal-open-invite-uuid':
      return {
        kind: 'discovery-portal-open-invite-uuid-embeddable',
        node: node,
      };
    case 'embedded-document-node':
      return {
        kind: 'embedded-document-embeddable',
        node: node,
        assets: segment.embeddedDocumentAssets,
      };
    case 'freebusy-program':
    case 'freebusy-coaching':
      return {
        kind: 'freebusy-embeddable',
        node: node,
        segmentStore: segmentStore,
        links: just(links),
      };
    case 'learning-partners':
      return {
        kind: 'learning-partners-embeddable',
        node: node,
      };
    case 'linked-document-node':
      return {
        kind: 'linked-document-embeddable',
        node: node,
        links: links,
      };
    case 'live-forum-dates-node':
      return {
        kind: 'live-forum-dates-embeddable',
        node: node,
      };
    case 'discovery-portal-choose-next':
      return {
        kind: 'discovery-portal-choose-next-embeddable',
        node: node,
      };
    case 'script-tag':
      return {
        kind: 'script-embeddable',
        node: node,
      };
    case 'section-event-dates-node':
      return {
        kind: 'section-event-dates-embeddable',
        node: node,
      };
    case 'team-link-node':
      return {
        kind: 'team-link-embeddable',
        node: node,
      };
    case 'team-members':
      return {
        kind: 'team-members-embeddable',
        node: node,
      };
    case 'segment-video-node':
      return {
        kind: 'segment-video-embeddable',
        node: node,
        assets: segment.embeddedVideoAssets,
      };
    case 'embedded-form-field-node':
      return {
        kind: 'embedded-form-field-embeddable',
        node: node,
        assets: segment.embeddedFormFieldAssets,
        segmentStore: segmentStore,
      };
    case 'coaching-selected-coach-name-node':
      return {
        kind: 'coaching-selected-coach-name-embeddable',
        node: node,
      };
    case 'coaching-selected-coach-profile-node':
      return {
        kind: 'coaching-selected-coach-profile-embeddable',
        node: node,
      };
    case 'display-assigned-coach-node':
      return {
        kind: 'display-assigned-coach-embeddable',
        node: node,
      };
    case 'program-chat-link-node':
      return {
        kind: 'program-chat-link-embeddable',
        node: node,
      };
  }
};

export const whenSegmentEmbeddable = (
  node: EmbeddableNode
): Result<string, SegmentEmbeddableNode> => {
  switch (node.kind) {
    case 'alert-node':
    case 'conjoint-analysis':
    case 'conjoint-analysis-results':
    case 'embedded-document-node':
    case 'freebusy-coaching':
    case 'freebusy-program':
    case 'learning-partners':
    case 'linked-document-node':
    case 'live-forum-dates-node':
    case 'script-tag':
    case 'section-event-dates-node':
    case 'segment-video-node':
    case 'team-link-node':
    case 'team-members':
    case 'discovery-portal-open-invite-uuid':
    case 'discovery-portal-choose-next':
    case 'embedded-form-field-node':
    case 'coaching-selected-coach-name-node':
    case 'coaching-selected-coach-profile-node':
    case 'display-assigned-coach-node':
    case 'program-chat-link-node':
      return ok(node);
    case 'shared-resource':
    case 'learning-partner-assignment':
    case 'expert-feedback-node':
    case 'digital-certificate-resource':
    case 'announcement-video-node':
    case 'coaching-survey-button-node':
      return err(`Node of type ${node.kind} is not segment embeddable`);
    default:
      return assertNever(node);
  }
};
