import { action, observable } from 'mobx';

export interface ImageSize {
  width: number;
  height: number
}
interface Waiting {
  kind: 'waiting';
}

interface Ready {
  kind: 'ready';
  size: ImageSize
}

interface LoadImageError {
  kind: 'error'
}

export type ImageState = Waiting | Ready | LoadImageError;

const waiting = (): Waiting => ({
  kind: 'waiting',
});

const ready = (size: ImageSize): Ready => ({
  kind: 'ready',
  size,
});

const error = (): LoadImageError => ({
  kind: 'error'
})

export class ImageStore {
  @observable
  state: ImageState = waiting();

  constructor() {
    this.state = waiting();
  }

  @action
  setImageSize = (width: number, height: number) => {
    this.state = ready({ width, height })
  }

  @action
  setError = () => {
    this.state = error()
  }
}
