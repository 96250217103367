import { observer } from 'mobx-react';
import * as React from 'react';
import SetCurrentUser from '../../../CurrentUser/Context/SetCurrentUser';
import { currentUserStore } from '../../../CurrentUser/Store';
import ThemeContext, { orgSpecificLoginPageTheme } from '../../../Themes/ThemeContext';
import AutoLaunchable from '../../../components/AutoLaunchable';
import {
  AutoLaunchableContext,
  AutoLaunchableSource,
} from '../../../components/AutoLaunchable/AutoLaunchableContext';
import Loader from '../../../components/Loader';
import RegisterResourceStore from '../DiscoveryPortal/RegisterResourceStore';
import RequireAuthentication from '../RequireAuthentication';
import Enrollment from './Enrollment';
import { RegisterResource } from './RegisterResourceStore/Types';

interface Props {
  registerResource: RegisterResource;
  registerResourceStore: RegisterResourceStore;
}

const DiscoveryPortalImpl: React.FC<Props> = ({ registerResource, registerResourceStore }) => {
  switch (currentUserStore.state.kind) {
    case 'waiting':
    case 'loading':
    case 'logging-out':
      return <Loader delay={0} />;
    case 'anonymous':
    case 'errored':
      return (
        <ThemeContext.Provider
          value={orgSpecificLoginPageTheme(registerResource.payload.sharedInvitation)}
        >
          <RequireAuthentication
            registerResource={registerResource}
            registerResourceStore={registerResourceStore}
          />
        </ThemeContext.Provider>
      );
    case 'ready':
    case 'refreshing':
      const contextValue: AutoLaunchableSource = registerResource.payload.autoEnrollProgramId
        .map<AutoLaunchableSource>(() => 'share_url_with_auto_enroll_program')
        .getOrElse(() => 'discovery_portal');
      return (
        <SetCurrentUser currentUserResource={currentUserStore.state.currentUserResource}>
          <AutoLaunchableContext.Provider value={contextValue}>
            <AutoLaunchable />
          </AutoLaunchableContext.Provider>
          <Enrollment
            registerResource={registerResource}
            registerResourceStore={registerResourceStore}
          />
        </SetCurrentUser>
      );
  }
};

export default observer(DiscoveryPortalImpl);
