import { MediaQueryBreakPoint } from '../../../../../../../components/UI';
import { Experience } from '../../../../Experience/Types';

function getSchoolPartnerLogoHeight(breakPoint: MediaQueryBreakPoint) {
  switch (breakPoint) {
    case 'xl':
    case 'lg':
    case 'md':
      return 54;
    case 'sm':
    case 'xs':
      return 40;
  }
}

export const schoolPartnerLogoProps = (
  experience: Experience,
  breakPoint: MediaQueryBreakPoint,
): { height: number; logoLinkType: 'logo-on-white' | 'logo-on-dark' } => {
  switch (experience.offeringType) {
    case 'coaching':
    case 'program-sequence':
      return { logoLinkType: 'logo-on-white', height: 22 };
    case 'aep':
    case 'epc':
    case 'msuite':
    case 'group-coaching':
      return { logoLinkType: 'logo-on-white', height: getSchoolPartnerLogoHeight(breakPoint) };
  }
};
