import { ExperienceResource } from '../../../Common/Experience/Types';
import { fromEmpty } from 'maybeasy';
import { when } from '@execonline-inc/maybe-adapter';
import { equals } from '@execonline-inc/collections';

export function formatLanguagesList(languages: string[]) {
  return fromEmpty(languages)
    .andThen((l) => when(equals(1 || 2, l.length), l))
    .getOrElseValue([languages.slice(0, -1).join(', '), languages[languages.length - 1]]);
}

export interface LanguageAvailabilityProps {
  experienceResource: ExperienceResource;
  className?: string;
}
