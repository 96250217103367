import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import BeginnableCardFooter from './BeginnableCardFooter';
import ResumableCardFooter from './ResumableCardFooter';
import ReturnableCardFooter from './ReturnableCardFooter';
import { ExperienceResource } from '../../../../Common/Experience/Types';

interface Props {
  experienceResource: ExperienceResource;
}

function EnrollmentCardFooter({ experienceResource }: Props) {
  switch (experienceResource.payload.kind) {
    case 'beginnable':
      return <BeginnableCardFooter experience={experienceResource.payload} />;
    case 'resumable':
      return <ResumableCardFooter experience={experienceResource.payload} />;
    case 'returnable':
      return <ReturnableCardFooter experience={experienceResource.payload} />;
    case 'commerce-enrollable':
    case 'upcoming':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed':
    case 'graduated':
      return emptyFragment();
  }
}

export default observer(EnrollmentCardFooter);
