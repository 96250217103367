import { useMediaQuery } from '../../../UI';

function useMobileNavbarSwitcher(): 'desktop' | 'mobile' {
  const breakPoint = useMediaQuery();

  switch (breakPoint) {
    case 'xl':
    case 'lg':
      return 'desktop';
    case 'md':
    case 'sm':
    case 'xs':
      return 'mobile';
  }
}

export default useMobileNavbarSwitcher;
