import { assertNever } from '@kofno/piper';
import { array } from 'jsonous';
import { just, Maybe, nothing } from 'maybeasy';
import { getFieldValue } from '../../../../../../FormFields';
import ReactionComponent, { RCProps } from '../../../../../../ReactionComponent';
import { FormFieldOutputValue, SelectFieldValue } from '../../../../Types';
import CoachSelectStore from '../Store';
import { State } from '../Store/Types';
import { selectValueDecoder } from '../../../../Decoders';

interface Props extends RCProps<CoachSelectStore> {}

const validateValue = (value: FormFieldOutputValue): Maybe<SelectFieldValue[]> =>
  array(selectValueDecoder).decodeAny(value).map(just).getOrElse(nothing);

class CoachSelectReactions extends ReactionComponent<CoachSelectStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'initializing':
        state.segmentStore.addEmbeddedFormFieldAssetStore(store);
        getFieldValue(state.segmentStore.results, store.formFieldId)
          .andThen(validateValue)
          .do((value) => store.ready(state.formField, value))
          .elseDo(() => store.ready(state.formField, []));
        break;
      case 'waiting':
      case 'ready':
      case 'auto-saving':
        break;
      default:
        assertNever(state);
    }
  };
}

export default CoachSelectReactions;
