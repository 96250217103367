import { assertNever } from '@kofno/piper';
import { Result } from 'resulty';
import { Task } from 'taskarian';
import { AppyError, postToApi, putToApi } from '../../../../../Appy';
import { warnAndNotify } from '../../../../../Honeybadger';
import { Link } from '../../../../../Resource/Types';
import { fromNullableT } from '../../../../../TaskExt';
import { TPlainTextKey } from '../../../../../Translations';
import { enrollmentResourceDecoder } from '../../Enrollment/Store/Decoders';
import { EnrollmentResource } from '../../Enrollment/Store/Types';
import { paymentIntentErrorDecoder } from '../Decoders';
import PaymentConfirmationStore from '../Store';
import { MessageReceived, PaymentIntentError } from '../Types';

const WAIT_DURATION = 15000;

const paymentIntentStatusLink = (): Link => ({
  rel: 'view',
  href: `/aep/payment_intent_statuses`,
  method: 'get',
  type: 'text/html',
});

const paymentStateLink = (paymentIntentId: string): Link => ({
  rel: 'update',
  href: `/aep/payment_states/${paymentIntentId}`,
  method: 'put',
  type: 'text/html',
});

const decodeAndHandlePusherSuccessMessage = (
  store: PaymentConfirmationStore,
  responseBody: unknown,
  paymentIntentId: string,
): Result<string, EnrollmentResource> =>
  enrollmentResourceDecoder
    .decodeAny(responseBody)
    .do(store.paidAndEnrolled(paymentIntentId))
    .elseDo((err) => {
      warnAndNotify('DecodePusherSuccessMessageFailed', err, {});
      store.error('We are experiencing an issue');
    });

const handlePusherErrorByType =
  (store: PaymentConfirmationStore) =>
  (body: PaymentIntentError): void => {
    switch (body.type) {
      case 'enrollment-error':
        return store.enrollmentError(
          'We are experiencing an issue',
          'Your payment was successful but there is a problem with your enrollment. Please contact support@execonline.com for assistance.',
        );
      case 'payment-declined-error':
        return store.paymentError(
          'We could not process your payment',
          'Your payment was declined by the credit card provider. Please go back to the previous page and try submitting payment again using a different credit card, or contact support@execonline.com for assistance.',
        );
      case 'internal-server-error':
        return store.error('We are experiencing an issue');
      default:
        assertNever(body.type);
    }
  };
const handlePusherErrorFailure =
  (store: PaymentConfirmationStore) =>
  (error: string): void => {
    warnAndNotify('DecodePusherErrorMessageFailed', error, {});
    store.error('We are experiencing an issue');
  };

const decodeAndHandlePusherErrorMessage = (
  store: PaymentConfirmationStore,
  responseBody: unknown,
): Result<string, PaymentIntentError> =>
  paymentIntentErrorDecoder
    .decodeAny(responseBody)
    .do(handlePusherErrorByType(store))
    .elseDo(handlePusherErrorFailure(store));

const createPaymentIntentStatusRequest = (paymentIntentId: string): Task<AppyError, {}> =>
  postToApi({ payment_intent_id: paymentIntentId })(paymentIntentStatusLink());

export const setPaymentStateProcessing = (paymentIntentId: string) =>
  putToApi({ state: 'processing' }, paymentStateLink(paymentIntentId));

const handlePaymentIntentRequestError =
  (store: PaymentConfirmationStore) =>
  (error: AppyError): void => {
    warnAndNotify('MissingLinkError', String(error.kind), {});
    store.error('We are experiencing an issue');
  };

const raisePusherError = (store: PaymentConfirmationStore, paymentIntentId: string) => {
  store.pusherError(
    <TPlainTextKey>'Payment Not Confirmed',
    <TPlainTextKey>(
      'Your payment could not be confirmed due to a service interruption. Please contact support@execonline.com for assistance.'
    ),
    paymentIntentId,
  );
};

export const raisePusherErrorAfterTimeout = (
  store: PaymentConfirmationStore,
  paymentIntentId: string,
): void => {
  setTimeout(() => raisePusherError(store, paymentIntentId), WAIT_DURATION);
};

export const raiseAfterProcessingTimeout = (store: PaymentConfirmationStore): void => {
  setTimeout(
    () =>
      store.paymentError(
        <TPlainTextKey>'Payment Not Confirmed',
        <TPlainTextKey>(
          'Your payment cannot be confirmed at this time. Please contact support@execonline.com for assistance.'
        ),
      ),
    WAIT_DURATION,
  );
};

export const getPaymentIntentId = (store: PaymentConfirmationStore): void => {
  fromNullableT(new URLSearchParams(window.location.search).get('payment_intent')).fork(
    (err) => store.error(err.message),
    (id) => store.waitingForMessage(id),
  );
};

export const requestPaymentIntentStatus = (
  store: PaymentConfirmationStore,
  paymentIntentId: string,
): void => {
  createPaymentIntentStatusRequest(paymentIntentId).fork(
    handlePaymentIntentRequestError(store),
    () => store.paymentStatusRequested(paymentIntentId),
  );
};

export const fetchContextAfterTimeout = (
  store: PaymentConfirmationStore,
  paymentIntentId: string,
) => setTimeout(() => store.requestPaymentStatus(paymentIntentId), WAIT_DURATION);

export const handleMessageReceived = (
  state: MessageReceived,
  store: PaymentConfirmationStore,
): void => {
  switch (state.eventName) {
    case 'processing':
      store.paymentStillProcessing(state.paymentIntentId);
      break;
    case 'processing-timed-out':
      store.error('We are experiencing an issue');
      break;
    case 'succeeded':
      decodeAndHandlePusherSuccessMessage(store, state.responseBody, state.paymentIntentId);
      break;
    case 'error':
      decodeAndHandlePusherErrorMessage(store, state.responseBody);
      break;
    default:
      assertNever(state.eventName);
  }
};
