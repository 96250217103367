import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '../../../../../components/UI';
import { L, T } from '../../../../../Translations';
import { EnrollmentPayload } from '../../Enrollment/Store/Types';

interface Props {
  enrollment: EnrollmentPayload;
}

const ExperienceDetailMessage: React.FC<Props> = ({ enrollment }) => {
  switch (enrollment.program.payload.kind) {
    case 'active':
    case 'completed':
    case 'expired':
    case 'inactive':
      return (
        <Typography className="leading-tight text-center" variant="subtitle2" component="p">
          <T kind="Your experience is available now! Click the button below to get started." />
        </Typography>
      );
    case 'upcoming':
      return (
        <>
          <Typography className="leading-tight text-center" variant="subtitle2" component="p">
            <T
              kind="Your experience will start on <date/>."
              date={
                <span>
                  <L
                    localizeable={enrollment.program.payload.startsOn}
                    format="long-month-day-year"
                  />
                </span>
              }
            />
          </Typography>
          <Typography className="leading-tight text-center" variant="subtitle2" component="p">
            <T kind="However, you may already have access to your team and resources." />
          </Typography>
        </>
      );
  }
};

export default observer(ExperienceDetailMessage);
