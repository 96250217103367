import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';
import { useExperienceContext } from '../ExperienceContext';

function ExperienceHeader() {
  const { experienceResource } = useExperienceContext();

  switch (experienceResource.payload.offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite':
    case 'program-sequence':
      return emptyFragment();
    case 'coaching':
      return (
        <Typography className="lowercase text-[#824A7B]" fontWeight="semibold" variant="caption">
          <T kind="Coaching" />
        </Typography>
      );
    case 'group-coaching':
      return (
        <Typography className="lowercase text-[#3E5B66]" fontWeight="semibold" variant="caption">
          <T kind="Coaching" />
        </Typography>
      );
  }
}

export default observer(ExperienceHeader);
