import { observer } from 'mobx-react';
import { identity } from '@kofno/piper';
import { CheckIcon, MenuItem, Typography } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { emptyFragment } from '../../../../../../EmptyFragment';
import { T } from '../../../../../../Translations';
import { WaffleIconListItem } from '../../../../../Dropdown/Types';
import { NavigationMenuItemProps } from '../Types';

function ApplicationMenuItem({ item }: NavigationMenuItemProps<WaffleIconListItem>) {
  const { href, icon, name, check } = item;

  const handleMenuItemClick = (href: string) => (e: React.KeyboardEvent | React.MouseEvent) => {
    when(check, item)
      .do(() => e.preventDefault())
      .elseDo(() => window.open(href, '_self', 'noopener noreferrer'));
  };

  return (
    <MenuItem
      className="hover:text-teal-600"
      startContent={icon}
      endContent={when(identity, check)
        .map(() => <CheckIcon color="teal" />)
        .getOrElse(emptyFragment)}
      onClick={handleMenuItemClick(href)}
    >
      <Typography color="inherit" fontWeight="semibold" variant="subtitleMedium" as="span">
        <T kind={name} />
      </Typography>
    </MenuItem>
  );
}

export default observer(ApplicationMenuItem);
