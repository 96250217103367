import { observer } from 'mobx-react';
import * as React from 'react';
import ExperienceBody from './ExperienceBody';
import { ExternalProgramResource } from '../../../../../../SegmentStore/Types';
import { emptyFragment } from '../../../../../../EmptyFragment';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const HeroBody: React.FC<Props> = ({ externalProgramResource }) => {
  switch (externalProgramResource.payload.offeringType) {
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      // A program sequence should not be nested within a program sequence
      return emptyFragment();
    case 'aep':
    case 'epc':
    case 'msuite':
      return <ExperienceBody externalProgramResource={externalProgramResource} />;
  }
};

export default observer(HeroBody);
