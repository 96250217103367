import { observer } from 'mobx-react';
import { ActiveProgram, CompletedProgram } from '../../ProgramStore/Types';
import CompletedSessionCount from './CompletedSesssionCount';
import { emptyFragment } from '../../EmptyFragment';

interface Props {
  programDetail: ActiveProgram | CompletedProgram;
}

const ProgramSessionsDetails: React.FC<Props> = ({ programDetail }) => {
  switch (programDetail.productDetails.payload.kind) {
    case 'coaching':
      return (
        <CompletedSessionCount
          title="Sessions Completed"
          completedSessionCount={programDetail.productDetails.payload.pastSessionsCount}
        />
      );
    case 'program':
    case 'program-sequence':
    case 'group-coaching':
      return emptyFragment();
  }
};

export default observer(ProgramSessionsDetails);
