import { fromBool, toResult } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Result, ok } from 'resulty';
import WithCurrentUser from '../../../../CurrentUser/Context/WithCurrentUser';
import { CurrentUserResource } from '../../../../CurrentUser/Types';
import { MissingLinkError, findLinkByR } from '../../../../Links';
import { Link } from '../../../../Resource/Types';
import ErrorMessage from '../../../ErrorMessage';
import { none } from '../../DiscoveryContext';
import DirectEnrollment from '../DirectEnrollment';
import ExperiencesStore from '../ExperienceSelection/Experiences/ExperiencesStore';
import { RegisterResource } from '../RegisterResourceStore/Types';
import CommerceDirectEnrollment from './CommerceDirectEnrollment';
import Routes from './Routes';
import EnrollmentStore from './Store';

const authorizeUser =
  (currentUserResource: CurrentUserResource) =>
  (registerResource: RegisterResource): Result<UnauthorizedUserError, ReadonlyArray<Link>> =>
    toResult(
      { kind: 'unauthorized-user-error' },
      fromBool(
        currentUserResource.payload.id === registerResource.payload.userId,
        registerResource.links,
      ),
    );

interface UnauthorizedUserError {
  kind: 'unauthorized-user-error';
}

type RegisterResourceError = UnauthorizedUserError | MissingLinkError;

interface Props {
  registerResource: RegisterResource;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
}

class EnrollmentImpl extends React.Component<Props> {
  render() {
    const { registerResource, enrollmentStore, experiencesStore } = this.props;

    return (
      <WithCurrentUser
        children={(currentUserResource) =>
          ok<RegisterResourceError, RegisterResource>(registerResource)
            .andThen(authorizeUser(currentUserResource))
            .andThen(findLinkByR({ rel: 'enroll' }))
            .cata({
              Err: (err): React.ReactElement => {
                switch (err.kind) {
                  case 'unauthorized-user-error':
                    return <ErrorMessage message="You are not authorized to view this page." />;
                  case 'missing-link-error':
                    return (
                      <Routes
                        registerResource={registerResource}
                        enrollmentStore={enrollmentStore}
                        experiencesStore={experiencesStore}
                      />
                    );
                }
              },
              Ok: () =>
                registerResource.payload.commerceDirectEnrollmentResource
                  .map((commerceDirectEnrollmentResource) => {
                    return (
                      <CommerceDirectEnrollment
                        experienceId={String(commerceDirectEnrollmentResource.payload.experienceId)}
                        registerResource={registerResource}
                        enrollmentStore={enrollmentStore}
                        experiencesStore={experiencesStore}
                        discoveryReferenceState={none()}
                      />
                    );
                  })
                  .getOrElse(() => (
                    <DirectEnrollment
                      registerResource={registerResource}
                      enrollmentStore={enrollmentStore}
                    />
                  )),
            })
        }
      />
    );
  }
}

export default observer(EnrollmentImpl);
