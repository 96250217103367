import { observer } from 'mobx-react';
import * as React from 'react';
import DynamicHeading from '../../Accessibility/DynamicHeading';
import CalendarStore from '../../CalendarStore';
import { CalendarResource } from '../../CalendarStore/Types';
import { L, T, translation, TranslationsContext } from '../../Translations';
import ArrowLeftIcon from '../Cutesies/ArrowLeftIcon';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import * as style from './style.module.css';

interface Props {
  calendarResource: CalendarResource;
  store: CalendarStore;
  headingLevel?: 2 | 3 | 4 | 5 | 6;
}

const Header: React.FC<Props> = ({ calendarResource, store, headingLevel = 2 }) => (
  <TranslationsContext.Consumer>
    {(ts) => (
      <div className={style.header} data-test-calendar-header={true}>
        <button
          onClick={() => store.navigating('prev')}
          className={style.previous}
          data-test-prev-button={true}
          aria-label={translation('Previous Month')(ts)}
          role="button"
        >
          <div className={style.previousIcon}>
            <ArrowLeftIcon />
          </div>
          <span className={style.previousLabel}>
            <T kind="previous" />
          </span>
        </button>
        <DynamicHeading
          level={headingLevel}
          onClick={() => store.navigating('self')}
          data-test-refresh-button={true}
          aria-live="polite"
        >
          <span className={style.monthAndYear} data-test-calendar-month-and-year={true}>
            <L localizeable={calendarResource.payload.firstDayOfMonth} format="month-and-year" />
          </span>
        </DynamicHeading>
        <button
          onClick={() => store.navigating('next')}
          className={style.next}
          data-test-next-button={true}
          aria-label={translation('Next Month')(ts)}
          role="button"
        >
          <span className={style.nextLabel}>
            <T kind="Next" />
          </span>
          <div className={style.nextIcon}>
            <ArrowRightIcon />
          </div>
        </button>
      </div>
    )}
  </TranslationsContext.Consumer>
);

export default observer(Header);
