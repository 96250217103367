import { byPayloadId, find } from '@execonline-inc/collections';
import { parseIntM } from '@execonline-inc/numbers';
import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import { segmentRoute } from '../../ClientRoutes';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { findLink } from '../../LinkyLinky';
import { lightTheme } from '../../Native/styles';
import { nav } from '../../Navigation';
import NotificationSource from '../../NotificationSource';
import Notifications from '../../Notifications';
import ProgramStore from '../../ProgramStore';
import { programsStore } from '../../ProgramsStore';
import SegmentStore from '../../SegmentStore';
import { SegmentResource } from '../../SegmentStore/Types';
import ThemeContext from '../../Themes/ThemeContext';
import SiteTitle from '../../Title';
import { TranslationsContext, translation } from '../../Translations';
import AutoLaunchable from '../AutoLaunchable';
import Footer from '../Footer';
import * as platformStyle from '../Platform/style.module.css';
import ProgramReactions from '../ProgramReactions';
import SegmentPanel from '../SegmentPanel';
import SegmentReactions from '../SegmentReactions';
import SkipNavLink from '../SkipNavLink';
import Toasts from '../Toasts';
import CancelRegistration from './CancelRegistration';
import SegmentContent from './SegmentContent';
import SegmentNavigation from './SegmentNavigation';
import ExitButton from './SegmentNavigation/ExitButton';
import Title from './Title';
import * as style from './style.module.css';
import NavigationBar from '../Platform/NavigationBar';

export interface Params {
  programId: string;
  moduleId: string;
  segmentId: string;
}

export interface Props {
  match: { params: Params };
}

class Segment extends React.Component<Props, {}> {
  segmentStore = new SegmentStore('Full');
  programIdM = parseIntM(this.props.match.params.programId);

  programStore = new ProgramStore();

  componentDidMount() {
    just({})
      .assign(
        'programs',
        programsStore.resource.map(({ payload }) => payload.programs),
      )
      .assign('programId', this.programIdM)
      .andThen(({ programs, programId }) => find(byPayloadId(programId), programs))
      .do(this.programStore.loading);
  }

  handleSegmentPathMismatch = (): void => {
    this.segmentStore.segmentResource.do((segmentResource) => {
      if (window.location.pathname.split('/').pop() !== segmentResource.payload.id.toString()) {
        nav(this.redirectPath());
      }
    });
  };

  redirectPath = (): string =>
    this.segmentStore.segmentResource
      .map((segmentResource: SegmentResource) => segmentRoute(segmentResource.payload))
      .getOrElseValue('/');

  componentDidUpdate() {
    this.handleSegmentPathMismatch();
  }

  render() {
    return (
      <>
        {this.segmentStore.title.map((t) => <SiteTitle title={t} />).getOrElseValue(<></>)}
        <TranslationsContext.Consumer>
          {(ts) => (
            <>
              <div className={platformStyle.containerFull}>
                <SkipNavLink />
                <Toasts teamsStore={nothing()} />
                <NavigationBar />
              </div>
              <div className="flex">
                <div className={platformStyle.container}>
                  <Notifications />
                  <AutoLaunchable />
                  <Main id="main" className={style.container}>
                    <ThemeContext.Provider value={lightTheme}>
                      <div className={style.header}>
                        <Title title={this.segmentStore.title} />
                        <SegmentNavigation
                          store={this.segmentStore}
                          programStore={this.programStore}
                        />
                      </div>
                      <div className={style.content}>
                        <SegmentContent store={this.segmentStore} />
                        <NotificationSource store={this.segmentStore} />
                      </div>
                      <div className={style.footer}>
                        {findLink('delete', this.programStore.programLinks)
                          .map((l) => (
                            <CancelRegistration cancelLink={l} programStore={this.programStore} />
                          ))
                          .orElse(() => this.programStore.whenRegistration.map(() => <span />))
                          .orElse(() =>
                            this.programIdM.map((programId) => (
                              <ExitButton programId={programId} />
                            )),
                          )
                          .getOrElseValue(<span />)}
                        <SegmentNavigation
                          store={this.segmentStore}
                          programStore={this.programStore}
                        />
                      </div>
                    </ThemeContext.Provider>
                  </Main>

                  <Footer />
                </div>
                <aside
                  className={platformStyle.supPanel}
                  aria-label={translation('Segment Panel')(ts)}
                >
                  <SegmentPanel segmentStore={this.segmentStore} programStore={this.programStore} />
                </aside>
                <SegmentReactions
                  store={this.segmentStore}
                  params={this.props.match.params}
                  advanceHook={() => {}}
                  fireImmediately={true}
                />
                <WithCurrentUser
                  children={(currentUserResource) => (
                    <ProgramReactions
                      store={this.programStore}
                      currentUserResource={currentUserResource}
                    />
                  )}
                />
              </div>
            </>
          )}
        </TranslationsContext.Consumer>
      </>
    );
  }
}

export default observer(Segment);
