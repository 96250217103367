import { createContext } from 'react';

interface DropdownContextProps {
  'aria-selected': boolean;
  selected: boolean;
  autoFocus: boolean;
  onClick?: (e: React.KeyboardEvent | React.MouseEvent) => void;
}

const dropdownContext = createContext<DropdownContextProps>({
  'aria-selected': false,
  selected: false,
  autoFocus: false,
  onClick: () => null,
});

export default dropdownContext;
