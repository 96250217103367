import { observer } from 'mobx-react';
import { just } from 'maybeasy';
import { ExperienceResource } from '../../../Common/Experience/Types';
import ExperiencesStore from '../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { ExperienceTitle, ExperiencesLogo, LanguageAvailability } from '../../Common';
import ExperienceVideo from '../../Common/ExperienceVideo';
import ExperienceActions from './ExperienceActions';
import ExperienceDescription from './ExperienceDescription';
import ExperienceOverview from './ExperienceOverview';

interface Props {
  experienceResource: ExperienceResource;
  experiencesStore: ExperiencesStore;
}

function LearnMoreLeadershipHero({ experienceResource, experiencesStore }: Props) {
  return (
    <>
      <ExperiencesLogo experienceResource={experienceResource} />
      <div className="flex w-full flex-col gap-x-28 lg:flex-row">
        <div className="w-full lg:w-3/5">
          <ExperienceTitle className="text-white" title={just(experienceResource.payload.title)} />
          <ExperienceDescription
            className="mb-10 w-full sm:mb-12 sm:w-[85%]"
            description={experienceResource.payload.description}
          />
          <LanguageAvailability
            experienceResource={experienceResource}
            className="mb-4 text-white sm:mb-8"
          />
          <ExperienceOverview experienceResource={experienceResource} />
        </div>
        <div className="mx-auto flex empty:hidden">
          <ExperienceVideo experiencesStore={experiencesStore} />
        </div>
      </div>

      <ExperienceActions experienceResource={experienceResource} />
    </>
  );
}

export default observer(LearnMoreLeadershipHero);
