import { useConst } from '@execonline-inc/react-hooks.private';
import { observer } from 'mobx-react';
import { ExperienceFiltersResource } from '../../Common/Experience/Types';
import EnrollmentStore from '../Enrollment/Store';
import EnrollableExperience from './EnrollableExperience';
import Experiences from './Experiences';
import ExperiencesStore from './Experiences/ExperiencesStore';
import CompetencyFilterStore from './Filters/CompetencyFilter/Store';
import DurationFilterStore from './Filters/DurationFilter/Store';
import FilterSection from './Filters/FilterSection';
import FilterStore from './Filters/FilterStore';
import LanguageFilterStore from './Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from './Filters/SchoolPartnerFilter/Store';
import WelcomeMessage from './Header/WelcomeMessage';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
  experienceFilters: ExperienceFiltersResource;
}

function ExperiencesWithFilters(props: Props) {
  const { enrollmentStore, experienceFilters, experiencesStore, invitationUuid } = props;

  const languageFilterStore = useConst(() => new LanguageFilterStore(experienceFilters.payload));
  const durationFilterStore = useConst(() => new DurationFilterStore(experienceFilters.payload));
  const schoolPartnerFilterStore = useConst(
    () => new SchoolPartnerFilterStore(experienceFilters.payload),
  );
  const competencyFilterStore = useConst(
    () => new CompetencyFilterStore(experienceFilters.payload),
  );
  const filterStore = useConst(() => new FilterStore(experienceFilters.payload));

  return (
    <>
      <WelcomeMessage experiencesStore={experiencesStore} />
      <FilterSection
        experiencesStore={experiencesStore}
        languageFilterStore={languageFilterStore}
        durationFilterStore={durationFilterStore}
        schoolPartnerFilterStore={schoolPartnerFilterStore}
        competencyFilterStore={competencyFilterStore}
      />
      <Experiences
        invitationUuid={invitationUuid}
        store={experiencesStore}
        filterStore={filterStore}
        languageFilterStore={languageFilterStore}
        durationFilterStore={durationFilterStore}
        schoolPartnerFilterStore={schoolPartnerFilterStore}
        competencyFilterStore={competencyFilterStore}
        enrollmentStore={enrollmentStore}
      />
      <EnrollableExperience
        invitationUuid={invitationUuid}
        notEnrollableAlert={experiencesStore.notEnrollableAlert}
        enrollmentStore={enrollmentStore}
      />
    </>
  );
}

export default observer(ExperiencesWithFilters);
