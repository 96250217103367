import * as React from 'react';
import { CompleteAndAdvancer } from '../../../../../SegmentStore/Types';
import { T, TPlainTextKey, translation, TranslationsContext } from '../../../../../Translations';
import ArrowRightIcon from '../../../../Cutesies/ArrowRightIcon';
import * as style from '../../../style.module.css';

interface Props {
  completeAndAdvancer: CompleteAndAdvancer;
  text: TPlainTextKey;
}

const CompleteAndAdvanceButton: React.FC<Props> = ({ completeAndAdvancer, text }) => (
  <TranslationsContext.Consumer>
    {(ts) => (
      <button
        className={style.nextButton}
        onClick={completeAndAdvancer.completeAndAdvance}
        role="button"
        title={translation('Complete and Advance')(ts)}
        data-test-next-button={text}
      >
        <T kind={text} />
        <div className={style.buttonIcon}>
          <ArrowRightIcon />
        </div>
      </button>
    )}
  </TranslationsContext.Consumer>
);

export default CompleteAndAdvanceButton;
