import { observer } from 'mobx-react';
import { Card, CardBody, CardFooter, CardHeader, Typography } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import ExperiencesStore from '../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { ExperienceResource } from '../Experience/Types';
import {
  ExperienceActionHint,
  ExperienceActions,
  ExperienceAlert,
  ExperienceAvailableLanguages,
  ExperienceCompetencies,
  ExperienceDescription,
  ExperiencePopoverContext,
  ExperienceTimeCommitment,
  ExperienceUpcomingDates,
} from '../PopoverProgramExperience';
import { useExperienceEnrollmentContext } from '../ProgramExperienceEnrollment';

interface Props {
  experienceResource: ExperienceResource;
  invitationUuid: string;
  experiencesStore: ExperiencesStore;
  onClose: () => void;
}

function ExperienceVideoDetailsModalBody({
  experienceResource,
  invitationUuid,
  experiencesStore,
  onClose,
}: Props) {
  const { onOpen } = useExperienceEnrollmentContext();

  return (
    <ExperiencePopoverContext.Provider
      value={{
        invitationUuid,
        experienceResource,
        experiencesStore,
        onClose: () => {
          onOpen();
          onClose();
        },
      }}
    >
      <Card className="absolute inset-0 shadow-none" radius="none">
        <CardHeader className="px-6 pt-8 shadow-none sm:pt-0">
          <div className="flex flex-grow flex-col gap-y-3">
            <ExperienceAlert />
            <Typography
              className="text-gray-800"
              variant="subtitleMedium"
              as="h5"
              fontWeight="medium"
            >
              <AlreadyTranslated content={experienceResource.payload.title} />
            </Typography>
          </div>
        </CardHeader>
        <CardBody className="gap-y-4 px-6">
          <ExperienceTimeCommitment />
          <ExperienceAvailableLanguages />
          <ExperienceDescription />
          <ExperienceCompetencies />
          <ExperienceUpcomingDates />
        </CardBody>
        <CardFooter className="gap-y-2 px-6 pb-8 empty:hidden">
          <ExperienceActions />
          <ExperienceActionHint />
        </CardFooter>
      </Card>
    </ExperiencePopoverContext.Provider>
  );
}

export default observer(ExperienceVideoDetailsModalBody);
