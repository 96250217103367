import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import ConjointAnalysis from '../ConjointAnalysis';
import ConjointAnalysisResults from '../ConjointAnalysisResults';
import DigitalCertificateSharedResourcePreview from '../DigitalCertificateSharedResourcePreview';
import DiscoveryPortalOpenInviteUuid from '../DiscoveryPortalOpenInviteUuid';
import CoachingSurveyButtonEmbeddable from '../Embeddables/CoachingSurveyButtonEmbeddable';
import DisplayAssignedCoachEmbeddable from '../Embeddables/DisplayAssignedCoachEmbeddable';
import ProgramChatLinkEmbeddable from '../Embeddables/ProgramChatLinkEmbeddable';
import SelectedCoachNameEmbeddable from '../Embeddables/SelectedCoachNameEmbeddable';
import SelectedCoachProfileEmbeddable from '../Embeddables/SelectedCoachProfileEmbeddable';
import EmbeddedDocumentAsset from '../EmbeddedDocumentAsset';
import EmbeddedFormFieldAsset from '../EmbeddedFormFieldAsset';
import ExpertFeedbackContent from '../ExpertFeedback';
import Freebusy from '../Freebusy';
import LearningPartnerUploads from '../LearningPartnerAssignment/LearningPartnerUploads';
import LearningPartners from '../LearningPartners';
import LinkedCourseDocument from '../LinkedCourseDocument';
import LiveForumDates from '../LiveForumDates';
import NextExperiences from '../NextExperiences';
import ReqHlsEmbeddedableVideoWrapper from '../ReqHlsVideo/ReqHlsEmbeddedableVideoWrapper';
import SectionEventDates from '../SectionEventDates';
import Alert from '../SegmentEmbed/Alert';
import Script from '../SegmentEmbed/Script';
import SharedResourcePreview from '../SharedResourcePreview';
import TeamLink from '../Team/TeamLink';
import TeamMembers from '../TeamMembers';
import AnnouncementEmbeddedableVideoWrapper from '../Video/AnnouncementEmbeddableVideoWrapper';
import { Embeddable } from './Types';

interface Props {
  embeddable: Embeddable;
}

const ContentEmbed: React.FC<Props> = ({ embeddable }) => (
  <WithCurrentUser
    children={(currentUserResource) => {
      switch (embeddable.kind) {
        case 'alert-embeddable':
          return <Alert node={embeddable.node} />;
        case 'conjoint-analysis-embeddable':
          return (
            <ConjointAnalysis
              segmentStore={embeddable.segmentStore}
              node={embeddable.node}
              store={embeddable.store}
            />
          );
        case 'conjoint-analysis-results-embeddable':
          return <ConjointAnalysisResults node={embeddable.node} />;
        case 'discovery-portal-open-invite-uuid-embeddable':
          return <DiscoveryPortalOpenInviteUuid node={embeddable.node} />;
        case 'embedded-document-embeddable':
          return (
            <EmbeddedDocumentAsset
              uuid={embeddable.node.uuid}
              embeddedDocumentAssets={embeddable.assets}
            />
          );
        case 'freebusy-embeddable':
          return (
            <Freebusy
              segmentStore={embeddable.segmentStore}
              links={embeddable.links}
              node={embeddable.node}
              currentUserResource={currentUserResource}
            />
          );
        case 'learning-partner-assignment-embeddable':
          return <LearningPartnerUploads assignment={embeddable.assignment} />;
        case 'learning-partners-embeddable':
          return <LearningPartners node={embeddable.node} />;
        case 'expert-feedback-embeddable':
          return <ExpertFeedbackContent feedback={embeddable.feedback} />;
        case 'linked-document-embeddable':
          return <LinkedCourseDocument node={embeddable.node} links={embeddable.links} />;
        case 'live-forum-dates-embeddable':
          return <LiveForumDates node={embeddable.node} />;
        case 'discovery-portal-choose-next-embeddable':
          return <NextExperiences node={embeddable.node} />;
        case 'script-embeddable':
          return <Script node={embeddable.node} />;
        case 'section-event-dates-embeddable':
          return <SectionEventDates node={embeddable.node} />;
        case 'shared-resource-embeddable':
          return (
            <SharedResourcePreview
              sharedResourceAnnouncement={embeddable.sharedResourceAnnouncement}
            />
          );
        case 'digital-certificate-resource-embeddable':
          return (
            <DigitalCertificateSharedResourcePreview
              digitalCertificateAnnouncement={embeddable.digitalCertificateAnnouncement}
            />
          );
        case 'team-link-embeddable':
          return <TeamLink node={embeddable.node} />;
        case 'team-members-embeddable':
          return <TeamMembers node={embeddable.node} />;
        case 'segment-video-embeddable':
          return <ReqHlsEmbeddedableVideoWrapper embeddable={embeddable} />;
        case 'announcement-video-embeddable':
          return <AnnouncementEmbeddedableVideoWrapper embeddable={embeddable} />;
        case 'embedded-form-field-embeddable':
          return (
            <EmbeddedFormFieldAsset
              uuid={embeddable.node.uuid}
              embeddedFormFieldAssets={embeddable.assets}
              segmentStore={embeddable.segmentStore}
            />
          );
        case 'coaching-selected-coach-name-embeddable':
          return <SelectedCoachNameEmbeddable node={embeddable.node} />;
        case 'coaching-selected-coach-profile-embeddable':
          return <SelectedCoachProfileEmbeddable node={embeddable.node} />;
        case 'display-assigned-coach-embeddable':
          return <DisplayAssignedCoachEmbeddable node={embeddable.node} />;
        case 'coaching-survey-button-embeddable':
          return <CoachingSurveyButtonEmbeddable node={embeddable.node} />;
        case 'program-chat-link-embeddable':
          return <ProgramChatLinkEmbeddable node={embeddable.node} />;
      }
    }}
  />
);

export default observer(ContentEmbed);
