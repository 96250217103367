import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import { CompleteAndAdvancer, ConfirmationModal } from '../../../../../SegmentStore/Types';
import { NotTranslated, T, TPlainTextKey } from '../../../../../Translations';
import { useTranslationsContext } from '../../../../../Translations/UseTranslationsContext';
import Confirm from '../../../../Confirm';
import ArrowRightIcon from '../../../../Cutesies/ArrowRightIcon';
import { useOpenable } from '../../../../Openable';
import * as style from '../../../style.module.css';

interface Props {
  completeAndAdvancer: CompleteAndAdvancer;
  text: TPlainTextKey;
  confirmationModal: ConfirmationModal;
}

function ConfirmCompleteAndAdvanceButton({ completeAndAdvancer, text, confirmationModal }: Props) {
  const { translate } = useTranslationsContext();
  const { openableState, open, close } = useOpenable();

  return (
    <>
      <button
        className={style.nextButton}
        onClick={open}
        title={translate('Complete and Advance')}
        role="button"
        data-test-next-button={text}
      >
        <T kind={text} />
        <div className={style.buttonIcon}>
          <ArrowRightIcon />
        </div>
      </button>
      <Confirm
        state={openableState}
        close={close}
        title={<NotTranslated text={confirmationModal.title} />}
        content={ReactHtmlParser(confirmationModal.content)}
        confirmText={<NotTranslated text={confirmationModal.button} />}
        onConfirm={completeAndAdvancer.completeAndAdvance}
      />
    </>
  );
}

export default observer(ConfirmCompleteAndAdvanceButton);
