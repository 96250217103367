import { observer } from 'mobx-react';
import { fromNullable } from 'maybeasy';
import { Stripe } from '@stripe/stripe-js';
import { Button, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../../Translations';
import { PaymentCheckoutFormState } from '../../../Common';

interface Props {
  stripe: Stripe;
  state: PaymentCheckoutFormState;
}

const submitPaymentDisabled = (stripe: Stripe): boolean => fromNullable(stripe).isNothing();

function PaymentCheckoutButton({ stripe, state }: Props) {
  switch (state.kind) {
    case 'waiting':
      return emptyFragment();
    case 'confirming-payment':
      return (
        <Button
          className="mt-6 !bg-[#B7B7B7] !text-white !opacity-100"
          size="md"
          fullWidth
          isLoading
          disabled
        >
          <T kind="Loading..." />
        </Button>
      );
    case 'payment-declined':
    case 'ready-for-payment-confirmation':
      return (
        <Button
          type="submit"
          className="mt-6"
          size="md"
          color="gray"
          fullWidth
          disabled={submitPaymentDisabled(stripe)}
        >
          <T kind="Confirm And Pay" />
        </Button>
      );
  }
}

export default observer(PaymentCheckoutButton);
