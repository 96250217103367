import { Maybe, just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Filters, { Props } from '.';
import { discoveryStore } from '../../../../../DiscoveryStore';
import { DiscoveryResource } from '../../../../../DiscoveryStore/Types';
import { emptyFragment } from '../../../../../EmptyFragment';

const FilterSection: React.FC<Props> = (props) => {
  return discoveryStore.resource
    .andThen(whenFilterable)
    .map(() => (
      <div className="sm:px-0">
        <Filters {...props} />
      </div>
    ))
    .getOrElse(emptyFragment);
};

export default observer(FilterSection);

const whenFilterable = (discoveryResource: DiscoveryResource): Maybe<DiscoveryResource> => {
  switch (discoveryResource.payload.filtering) {
    case 'filterable':
      return just(discoveryResource);
    case 'non-filterable':
      return nothing();
  }
};
