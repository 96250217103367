import { observer } from 'mobx-react';
import ErrorMessage from '../../../ErrorMessage';
import ExperiencesStore from '../ExperienceSelection/Experiences/ExperiencesStore';
import { emptyFragment } from '../../../../EmptyFragment';
import { findLink } from '../../../../LinkyLinky';
import EnrollmentStore from '../Enrollment/Store';
import Loading from '../../../../components/Loading';
import PromotablesWrapper from './Promotables';

interface Props {
  store: ExperiencesStore;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
}

function PromotablesSection({ store, enrollmentStore, invitationUuid }: Props) {
  switch (store.state.kind) {
    case 'waiting':
    case 'loading':
      return <Loading />;
    case 'searching':
      return emptyFragment();
    case 'experiences':
    case 'product-collections':
    case 'product-collections-and-experiences':
      return findLink('promotables', store.state.resource.links)
        .map((link) => {
          return (
            <PromotablesWrapper
              link={link}
              enrollmentStore={enrollmentStore}
              experiencesStore={store}
              invitationUuid={invitationUuid}
            />
          );
        })
        .getOrElse(emptyFragment);

    case 'error':
      return <ErrorMessage message="Unable to load your experiences" />;
  }
}

export default observer(PromotablesSection);
