import { observer } from 'mobx-react';
import * as React from 'react';
import { T, TPlainTextKey, translation, TranslationsContext } from '../../../../../Translations';
import { Maybe } from 'maybeasy';
import { emptyFragment } from '@execonline-inc/execonline-ui';

interface Props {
  text: TPlainTextKey;
  btnStyle: string;
  btnIcon: Maybe<JSX.Element>;
}

const DisabledButton: React.FC<Props> = ({ text, btnStyle, btnIcon }) => (
  <TranslationsContext.Consumer>
    {(ts) => (
      <button
        className={btnStyle}
        title={translation('Advance')(ts)}
        role="button"
        aria-disabled={true}
        data-test-next-button={text}
        disabled={true}
      >
        <T kind={text} />
        {btnIcon.getOrElse(emptyFragment)}
      </button>
    )}
  </TranslationsContext.Consumer>
);

export default observer(DisabledButton);
