import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoomResource } from '../../../Conference/Types';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import ConferenceRosterReactions from '../../../ConferenceRosterStore/ConferenceRosterReactions';
import ConversationPresence from '../../../ConversationPresence';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import { EventResource } from '../../../EventsStore/Types';
import * as style from '../style.module.css';
import MainChat from './MainChat';
import { emptyFragment } from '../../../EmptyFragment';

interface Props {
  eventResource: EventResource;
  currentUserResource: CurrentUserResource;
  conferenceRoomResource: LocalConferenceRoomResource;
}

@observer
class MainConversation extends React.Component<Props> {
  conferenceRosterStore = new ConferenceRosterStore(
    this.props.currentUserResource,
    this.props.conferenceRoomResource.payload
  );

  componentDidMount() {
    this.conferenceRosterStore.loading();
  }

  render() {
    return (
      <div className={style.room}>
        {this.conferenceRosterStore.chatablePersonResources
          .map((chatablePersonResources) => (
            <ConversationPresence
              conversationResource={this.props.conferenceRoomResource.payload.conversationResource}
              currentUserResource={this.props.currentUserResource}
              channelName={
                this.props.conferenceRoomResource.payload.conversationResource.payload.channelName
              }
              chatablePersonResourceList={nothing()}
              client={(presenceMembersStore) => (
                <MainChat
                  conversationStore={presenceMembersStore.conversationStore}
                  chatablePersonResourceList={chatablePersonResources}
                  eventResource={this.props.eventResource}
                  conferenceRoomResource={this.props.conferenceRoomResource}
                  polls={this.props.eventResource.payload.polls}
                />
              )}
            />
          ))
          .getOrElse(emptyFragment)}
        <ConferenceRosterReactions
          store={this.conferenceRosterStore}
          conferenceableResource={this.props.eventResource}
          fireImmediately={true}
        />
      </div>
    );
  }
}

export default MainConversation;
