import { forwardRef } from 'react';
import { observer } from 'mobx-react';
import { Card, CardProps } from '@execonline-inc/execonline-ui';
import { learningJourneyExperience } from '../../../Experience/Types';
import { useExperienceContext } from '../../../Experience/ExperienceContext';

const ProgramExperienceCard = forwardRef<HTMLDivElement, CardProps>(
  function ProgramExperienceCard(props, ref) {
    const { experienceResource } = useExperienceContext();

    return learningJourneyExperience(experienceResource.payload)
      .map(() => (
        <div className="relative flex w-full flex-col overflow-visible">
          <Card ref={ref} {...props} />
          <div className="flex w-full flex-col items-center">
            <div className="h-2 w-[96%] rounded-b-[3px] bg-[#F4F4F4] shadow-program-card" />
            <div className="h-2 w-[93%] rounded-b-[3px] bg-[#F4F4F4] shadow-program-card" />
          </div>
        </div>
      ))
      .getOrElse(() => <Card ref={ref} {...props} />);
  },
);

export default observer(ProgramExperienceCard);
