import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import ProgramStore from '../../../ProgramStore';
import { ProgramDetailResource, ProgramSegment } from '../../../ProgramStore/Types';
import { emptyFragment } from '../../../EmptyFragment';
import ActiveProgramSequenceOverview from '../../ActiveProgramOverview/ActiveProgramSequenceOverview';
import ProgramOverview from './ProgramOverview';

interface Props {
  programDetailResource: ProgramDetailResource;
  currentSegment: Maybe<ProgramSegment>;
  programStore: ProgramStore;
  currentUserResource: CurrentUserResource;
}

const ProgramOverviewDashboard: React.FC<Props> = ({
  programDetailResource,
  currentSegment,
  programStore,
  currentUserResource,
}) => {
  switch (programDetailResource.payload.productDetails.payload.kind) {
    case 'coaching':
    case 'group-coaching':
      return emptyFragment();
    case 'program':
      return (
        <ProgramOverview
          programDetailResource={programDetailResource}
          currentSegment={currentSegment}
          programStore={programStore}
          currentUserResource={currentUserResource}
        />
      );
    case 'program-sequence':
      return (
        <ActiveProgramSequenceOverview
          programDetailResource={programDetailResource}
          store={programStore}
        />
      );
  }
};

export default observer(ProgramOverviewDashboard);
