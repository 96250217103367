import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { fromArrayMaybe } from 'nonempty-list';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { AlreadyTranslatedText } from '@execonline-inc/translations';
import ThemeContext from '../../Themes/ThemeContext';
import { tailwindTheme } from '../tailwind-theme';
import { HtmlParserNode } from '../../SegmentStore/Types';
import { emptyFragment } from '../../EmptyFragment';
import { htmlANodeDecoder, htmlImgNodeDecoder } from './HtmlDecoders';
import HtmlImageView from './HtmlImageView';
import './styles.css';

interface Props {
  contrast?: boolean;
  className?: string;
  description: AlreadyTranslatedText;
}

const HtmlView: React.FC<Props> = ({ description, contrast, className }) => {
  const transform = (node: HtmlParserNode, index: number) => {
    switch (node.name) {
      case 'img':
        return htmlImgNodeDecoder.decodeAny(node).cata({
          Err: emptyFragment,
          Ok: (data) => {
            return <HtmlImageView key={index} src={data.attribs.src} />;
          },
        });
      case 'a':
        return htmlANodeDecoder.decodeAny(node).cata({
          Err: emptyFragment,
          Ok: (data) => (
            <a key={index} className="htmlParserLink" href={data.attribs.href} target="_blank">
              {fromArrayMaybe(node.children)
                .map((children) => children.first.data)
                .getOrElseValue(data.attribs.href)}
            </a>
          ),
        });
    }
  };

  return fromEmpty(description.text)
    .map((text) => (
      <ThemeContext.Consumer>
        {(theme) => (
          <div
            className={twMerge(
              clsx('htmlParserWrapper', 'text-font-size-md xl:text-font-size-lg', className),
            )}
            style={{
              color: contrast ? tailwindTheme.colors.white : theme.colors['body-text'],
            }}
          >
            {ReactHtmlParser(text, { transform })}
          </div>
        )}
      </ThemeContext.Consumer>
    ))
    .getOrElse(emptyFragment);
};

export default observer(HtmlView);
