import { observer } from 'mobx-react';
import PromotablesStore from './PromotablesStore';
import PromotablesReactions from './PromotablesReactions';
import EnrollmentStore from '../Enrollment/Store';
import { Link } from '../../../../Resource/Types';
import ExperiencesStore from '../ExperienceSelection/Experiences/ExperiencesStore';
import { useMemo } from 'react';
import PromotablesState from './PromotablesState';

interface Props {
  link: Link;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
  invitationUuid: string;
}

function Promotables({ enrollmentStore, link, invitationUuid, experiencesStore }: Props) {
  const store = useMemo(() => new PromotablesStore(link), [link]);
  return (
    <>
      <PromotablesReactions store={store} fireImmediately={true} />
      <PromotablesState
        enrollmentStore={enrollmentStore}
        experiencesStore={experiencesStore}
        store={store}
        invitationUuid={invitationUuid}
      />
    </>
  );
}

export default observer(Promotables);
