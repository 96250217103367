import { observer } from 'mobx-react';
import * as React from 'react';
import { toolingsStore } from '.';
import { emptyFragment } from '../EmptyFragment';
import { pusherSettings } from '../Pusher';
import { PusherSettingsResource } from './Types';

interface Props {
  children: (resource: PusherSettingsResource) => React.ReactElement;
}

const WithPusherSettings: React.FC<Props> = ({ children }) =>
  toolingsStore.resource
    .map(({ payload }) => payload)
    .andThen(pusherSettings)
    .map(children)
    .getOrElse(emptyFragment);

export default observer(WithPusherSettings);
