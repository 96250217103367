import { observer } from 'mobx-react';
import { Maybe, nothing } from 'maybeasy';
import EnrollmentStore from '../../../Enrollment/Store';
import FilterStore from '../../Filters/FilterStore';
import LanguageFilterStore from '../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../Filters/SchoolPartnerFilter/Store';
import DurationFilterStore from '../../Filters/DurationFilter/Store';
import CompetencyFilterStore from '../../Filters/CompetencyFilter/Store';
import {
  UseCaseDisplayType,
  ExperienceDisplayTypeKind,
  ExperienceResources,
  ProductCollection,
} from '../../../../Common/Experience/Types';
import ExperienceTiles from './TileViewExperiences';
import ListViewExperiences from './ListViewExperiences';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  experienceResources: ExperienceResources;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  displayType: UseCaseDisplayType;
  kind: ExperienceDisplayTypeKind;
  productCollection: Maybe<ProductCollection>;
  competencyFilterStore: CompetencyFilterStore;
  searchValue: Maybe<string>;
}

export const ExperienceDisplayType: React.FC<Props> = ({
  invitationUuid,
  enrollmentStore,
  experienceResources,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  displayType,
  kind,
  competencyFilterStore,
  searchValue,
}) => {
  switch (displayType) {
    case 'grid':
      return (
        <ExperienceTiles
          invitationUuid={invitationUuid}
          experienceResources={experienceResources}
          enrollmentStore={enrollmentStore}
          productCollections={nothing()}
          filterStore={filterStore}
          languageFilterStore={languageFilterStore}
          durationFilterStore={durationFilterStore}
          schoolPartnerFilterStore={schoolPartnerFilterStore}
          competencyFilterStore={competencyFilterStore}
          searchValue={searchValue}
          kind={kind}
          productCollection={nothing()}
        />
      );
    case 'list':
      return (
        <ListViewExperiences
          kind={kind}
          invitationUuid={invitationUuid}
          experienceResources={experienceResources}
          enrollmentStore={enrollmentStore}
          filterStore={filterStore}
          languageFilterStore={languageFilterStore}
          durationFilterStore={durationFilterStore}
          schoolPartnerFilterStore={schoolPartnerFilterStore}
          productCollection={nothing()}
          competencyFilterStore={competencyFilterStore}
          searchValue={searchValue}
        />
      );
  }
};

export default observer(ExperienceDisplayType);
