import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';
import { Typography, cn, emptyFragment } from '@execonline-inc/execonline-ui';
import { useMediaQuery } from '../../../../../components/UI';
import { getTitleVariant } from './Types';

interface Props {
  title: Maybe<AlreadyTranslatedText>;
  className?: string;
}

function ExperienceTitle({ title, className }: Props) {
  const breakPoint = useMediaQuery();

  return title
    .map((title) => (
      <Typography
        className={cn('mb-4 leading-normal text-black sm:mb-8', className)}
        variant={getTitleVariant(breakPoint)}
        as="h3"
      >
        <AlreadyTranslated content={title} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ExperienceTitle);
