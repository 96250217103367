import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../SegmentStore';
import FullSurveySegment from './FullSurveySegment';
import StreamlinedSurveySegment from './StreamlinedSurveySegment';

interface Props {
  segmentStore: SegmentStore;
}

const SurveySegment: React.FC<Props> = ({ segmentStore }) => {
  switch (segmentStore.presentationStyle) {
    case 'Full':
      return <FullSurveySegment segmentStore={segmentStore} />;
    case 'Streamlined':
      return <StreamlinedSurveySegment segmentStore={segmentStore} />;
  }
};

export default observer(SurveySegment);
