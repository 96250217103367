import * as React from 'react';
import { just } from 'maybeasy';
import { T } from '../../../Translations';
import TimeStamp from '../../TimeStamp';
import ToolLibraryListItem from './ToolLibraryListItem';
import * as style from '../style.module.css';

const NumberedInstructions: React.FC = () => {
  return (
    <div className={style.action}>
      <div>
        <h2 className={style.subTitle}>
          <T kind="1. Schedule Test" />
        </h2>
        <p>
          <T kind="Click on Select a time and the scheduling window ..." />
        </p>
      </div>
      <div>
        <h2 className={style.subTitle}>
          <T kind="2. Date and Time Settings" />
        </h2>
        <TimeStamp timestamp={just(new Date())} />
        <p className={style.dateTimeDescription}>
          <T kind="The date and time above should match your computer's system" />
        </p>
      </div>
      <div>
        <h2 className={style.subTitle}>
          <T kind="3. Tool Library Test" />
        </h2>
        <ToolLibraryListItem title="Scheduling library loaded" />
        <ToolLibraryListItem title="Web Worker library loaded" />
        <ToolLibraryListItem title="Cookies set correctly" />
      </div>
    </div>
  );
};

export default NumberedInstructions;
