import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import Avatar, { Props as AvatarProps } from '../Avatar';

interface Props extends AvatarProps {}

const ListItemAvatar: React.FC<Props> = ({ className, children, ...others }) => {
  return (
    <Avatar className={twMerge(clsx('mr-4', className))} {...others}>
      {children}
    </Avatar>
  );
};

export default observer(ListItemAvatar);
