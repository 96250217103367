import { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { CarouselStore, CarouselWithButtons } from '../../../../../../Common/Experience/Carousel';
import ShownExperiences from '../ShownExperiences';
import { ExperienceCarouselProps } from '../Types';

interface Props<T> {
  carouselStore: CarouselStore<T>;
  children: (props: ExperienceCarouselProps<T>) => ReactNode;
}

function TileCarouselExperience<T>({ carouselStore, children }: Props<T>) {
  return (
    <CarouselWithButtons carouselStore={carouselStore}>
      <ShownExperiences carouselStore={carouselStore} children={children} />
    </CarouselWithButtons>
  );
}

export default observer(TileCarouselExperience);
