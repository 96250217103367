import { take } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import { nothing } from 'maybeasy';
import { ProfileResource } from '../../../ProfileStore/Types';
import { OpenableState, useOpenable } from '../../Openable';
import { PersonState, TeamMemberPerson } from '../../../Person/types';
import { findLink } from '../../../LinkyLinky';
import { PersonStore } from '../../../Person';
import Person from '../../Person';
import { emptyFragment } from '../../../EmptyFragment';
import style from './styles.module.css';
interface Props {
  members: ProfileResource[];
}

const MAX_MEMBERS_COUNT: number = 7;

const getMembers = (members: ProfileResource[], state: OpenableState) => {
  switch (state.kind) {
    case 'closed':
      return [...take(MAX_MEMBERS_COUNT, members)];
    case 'open':
      return members;
  }
};

const MembersList: React.FC<Props> = ({ members }) => {
  const { openableState, open, close } = useOpenable();
  const listOfMembers = getMembers(members, openableState);

  const extraMembersCount = members.length - MAX_MEMBERS_COUNT;

  const handleShowMore = () => {
    switch (openableState.kind) {
      case 'closed':
        return open();
      case 'open':
        return close();
    }
  };

  const membersList = listOfMembers.map((member) => {
    const memberType: TeamMemberPerson = {
      kind: 'team-member',
      profileResource: {
        id: member.payload.userId,
        email: member.payload.email,
        profile: member,
      },
    };
    const memberState: PersonState = {
      photo: findLink('avatar', member.links),
      person: memberType,
      id: member.payload.userId,
    };

    const memberStore = new PersonStore(memberState);

    return (
      <Person key={member.payload.userId} personStore={memberStore} presenceStore={nothing()} />
    );
  });

  switch (openableState.kind) {
    case 'open':
      return <>{membersList}</>;
    case 'closed':
      return (
        <>
          {membersList}
          {when(members.length > MAX_MEMBERS_COUNT, {})
            .map(() => (
              <button className={style.membersCount} onClick={handleShowMore}>
                {`+${extraMembersCount}`}
              </button>
            ))
            .getOrElse(emptyFragment)}
        </>
      );
  }
};

export default observer(MembersList);
