import { mergeObjectDecoders } from '@execonline-inc/decoders';
import Decoder, { field, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { baseResourceDecoder, documentPartsDecoder } from '../../ResourceStore/Decoders';
import { DocumentResource, Linkable } from './Types';
import { alreadyTranslatedText } from '@execonline-inc/translations';

const documentPartsResourceDecoder: Decoder<DocumentResource> = resourceDecoder(
  mergeObjectDecoders(baseResourceDecoder, documentPartsDecoder),
);

export const linkableDecoder: Decoder<Linkable> = succeed({})
  .assign('label', field('label', alreadyTranslatedText))
  .assign('resource', field('resource', documentPartsResourceDecoder));
