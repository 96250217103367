import { observer } from 'mobx-react';
import React from 'react';
import { findLink } from '../../../../../LinkyLinky';
import { ExternalProgramResource } from '../../../../../SegmentStore/Types';
import { emptyFragment } from '../../../../../EmptyFragment';
import * as style from './../style.module.css';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const DownloadProgramOverviewLink: React.FC<Props> = ({ externalProgramResource }) => {
  return findLink('download-overview-pdf', externalProgramResource.links)
    .map((pdfLink) => {
      return (
        <a className={style.downloadLink} href={pdfLink.href} rel="noreferrer noopener">
          Download Overview
        </a>
      );
    })
    .getOrElse(emptyFragment);
};

export default observer(DownloadProgramOverviewLink);
