import { observer } from 'mobx-react';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { None, none } from '../../../DiscoveryContext';
import ExperienceDetailView from '../../../ExperienceDetailView';
import ExperiencesStore from '../../ExperienceSelection/Experiences/ExperiencesStore';
import { RegisterResource } from '../../RegisterResourceStore/Types';
import EnrollmentStore from '../Store';

interface Props {
  registerResource: RegisterResource;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
  experienceId: string;
  discoveryReferenceState: None;
}

const CommerceDirectEnrollment: React.FC<Props> = ({
  registerResource,
  enrollmentStore,
  experiencesStore,
  experienceId,
}) => (
  <Route
    path="/register/:uuid"
    exact={true}
    render={(props) => (
      <ExperienceDetailView
        experienceId={experienceId}
        invitationUuid={props.match.params.uuid}
        registerResource={registerResource}
        enrollmentStore={enrollmentStore}
        experiencesStore={experiencesStore}
        discoveryReferenceState={none()}
      />
    )}
  />
);

export default observer(CommerceDirectEnrollment);
