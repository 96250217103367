import React from 'react';
import { observer } from 'mobx-react';
import { fromEmpty } from 'maybeasy';
import { equals } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';
import { Checkbox, Dropdown, DropdownMenuItem, Typography } from '../../../../../../components/UI';
import { T, TPlainTextKey } from '../../../../../../Translations';
import ClassicFilterPlaceholder from './ClassicFilterPlaceholder';
import CommonFilterStore from './Store';
import { FilterOption, isFilterItemSelected } from './Types';

interface Props {
  label: TPlainTextKey;
  filterOptions: FilterOption[];
  className?: string;
  store: CommonFilterStore;
}

const isAllSelected = (filterOptions: FilterOption[], selectedValues: string[]) =>
  fromEmpty(filterOptions)
    .map((options) => equals(options.length, selectedValues.length))
    .getOrElseValue(true);

const whenValueEqualsAll = (filterOptions: FilterOption[], selectedValues: string[]) =>
  when(equals(selectedValues.length, filterOptions.length), {})
    .map<string[]>(() => [])
    .getOrElseValue(filterOptions.map((option) => option.value));

const calculateValues = (
  filterOptions: FilterOption[],
  values: string[],
  selectedValues: string[],
) =>
  when(equals(values[values.length - 1], 'All'), {})
    .map(() => whenValueEqualsAll(filterOptions, selectedValues))
    .getOrElseValue(values);

const handleChange = (
  filterOptions: FilterOption[],
  selectedValues: string[],
  store: CommonFilterStore,
  value: string[],
) => {
  store.appendSelectedValues(calculateValues(filterOptions, value, selectedValues));
  store.applySelectedValues();
};

const renderedValue = (items: string[]) => (value: string[]) => {
  return <ClassicFilterPlaceholder selectedOptions={value} allOptions={items} />;
};

const ClassicDropdownFilter: React.FC<Props> = ({
  store,
  label,
  filterOptions,
  className = 'w-48',
}) => {
  const filterAll = filterOptions.map((option) => option.value);
  const selectedValues = [...store.state.filter.appliedValues];

  return (
    <>
      <Dropdown
        className={className}
        placeholder={<T kind="Selected: ({{count}})" count={0} />}
        label={<T kind={label} />}
        value={selectedValues}
        multiple
        renderValue={renderedValue(filterAll)}
        onChange={(value) => {
          handleChange(filterOptions, selectedValues, store, value);
        }}
      >
        <DropdownMenuItem value="All" aria-selected={isAllSelected(filterOptions, selectedValues)}>
          <Checkbox className="mr-1" checked={isAllSelected(filterOptions, selectedValues)} />
          <Typography className="!text-black" variant="body2" component="p">
            <T kind="All" />
          </Typography>
        </DropdownMenuItem>
        {filterOptions.map(({ value, label }) => {
          return (
            <DropdownMenuItem value={value} key={value}>
              <Checkbox className="mr-1" checked={isFilterItemSelected(selectedValues, value)} />
              <Typography className="!text-black" variant="body2" component="p">
                {label}
              </Typography>
            </DropdownMenuItem>
          );
        })}
      </Dropdown>
    </>
  );
};

export default observer(ClassicDropdownFilter);
