import React, { HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { AvatarSize, avatarSizeClassNameMapping } from './Types';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  size?: AvatarSize;
}

const Avatar: React.FC<Props> = ({ className, children, size = 'md', ...others }) => {
  return (
    <div>
      <div
        className={twMerge(
          clsx(
            avatarSizeClassNameMapping[size],
            'rounded-full overflow-hidden flex shrink-0',
            avatarSizeClassNameMapping[size],
            className,
          ),
        )}
        {...others}
      >
        {children}
      </div>
    </div>
  );
};

export default observer(Avatar);
