import { toSeconds } from '@execonline-inc/time';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import { Connecting, Unavailable } from '../Types';

interface Props {
  socket: Connecting | Unavailable;
}

const UnavailableMessage: React.FC<Props> = ({ socket }) => (
  <>
    <div>
      <T kind="Realtime connection not available." />
    </div>
    {socket.connectingIn
      .map((time) => (
        <div>
          <T kind="Retrying in {{count}} second" count={toSeconds(time).seconds} />
        </div>
      ))
      .getOrElseValue(<span />)}
  </>
);

export default observer(UnavailableMessage);
