import * as React from 'react';
import { observer } from 'mobx-react';
import { ExternalProgramResource } from '../../../../../../SegmentStore/Types';
import { T } from '../../../../../../Translations';
import { emptyFragment } from '../../../../../../EmptyFragment';
import CalendarClockIcon from '../../../../../Cutesies/CalendarClockIcon';
import LighOnEmptyIcon from '../../../../../Cutesies/LighOnEmptyIcon';
import * as styles from '../../style.module.css';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const ExperienceInfo: React.FC<Props> = ({ externalProgramResource }) => {
  const competenciesAmount = externalProgramResource.payload.primaryCompetencies.length;
  return externalProgramResource.payload.timeCommitment
    .andThen(({ duration }) => duration)
    .map((duration) => (
      <>
        <div className={styles.competenciesQuantity}>
          <LighOnEmptyIcon />
          <T kind="{{count}} Competency" count={competenciesAmount} />
        </div>
        <div className={styles.competenciesQuantity}>
          <CalendarClockIcon />
          <T kind="{{count}} week" count={duration} />
        </div>
      </>
    ))
    .getOrElse(emptyFragment);
};

export default observer(ExperienceInfo);
