import { Maybe, just, nothing } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import { always } from '@kofno/piper';
import { first, mapMaybe } from '@execonline-inc/collections';
import { findLink } from '../../../../../LinkyLinky';
import { AvailabilityResource, AvailabilityResources, ConflictingProgram } from '../Types';

export const scheduledAvailability = (
  availability: AvailabilityResource,
): Maybe<AvailabilityResource> => {
  switch (availability.payload.kind) {
    case 'on-demand':
      return nothing();
    case 'scheduled':
      return just(availability);
  }
};

export const whenNoConflictAvailability = (
  availability: AvailabilityResource,
): Maybe<AvailabilityResource> => {
  return getConflictAvailability(availability)
    .andThen<Maybe<AvailabilityResource>>(nothing)
    .getOrElse(always(just(availability)));
};

export const getConflictAvailability = (
  availability: AvailabilityResource,
): Maybe<ConflictingProgram> => {
  return availability.payload.conflictingProgram;
};

export const getEnrollAvailability = (availability: AvailabilityResource) => {
  return findLink('enroll', availability.links).map(() => availability);
};

function maybeFilteredAvailability(
  availability: AvailabilityResource,
): Maybe<AvailabilityResource> {
  return getEnrollAvailability(availability).andThen(whenNoConflictAvailability);
}

function maybeFilteredScheduledAvailabilities(
  availability: AvailabilityResource,
): Maybe<AvailabilityResource> {
  return maybeFilteredAvailability(availability).andThen(scheduledAvailability);
}

export function getScheduledAvailabilities(
  availabilities: AvailabilityResources,
): AvailabilityResources {
  return mapMaybe(maybeFilteredScheduledAvailabilities, availabilities);
}

export const getFirstNonConflictAvailability = (
  availabilities: NonEmptyList<AvailabilityResource>,
): Maybe<AvailabilityResource> => {
  const collection = mapMaybe(maybeFilteredAvailability, availabilities.toArray());

  return first(collection);
};
