import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../../Common/Experience/Types';
import { ExperienceDetailsSection, ExperienceDetailsSectionHeader } from '../ExperienceDetails';
import ExperienceHtmlView from '../ExperienceHtmlView';

interface Props {
  experienceResource: ExperienceResource;
}

function WhatYouWillLearn({ experienceResource }: Props) {
  return experienceResource.payload.keyTakeawaysHtml
    .map((data) => (
      <ExperienceDetailsSection>
        <ExperienceDetailsSectionHeader title="What you will learn" />
        <ExperienceHtmlView
          className="!text-sm [&>ul]:grid [&>ul]:gap-3 sm:[&>ul]:grid-cols-2 sm:[&>ul]:gap-8"
          data={data}
        />
      </ExperienceDetailsSection>
    ))
    .getOrElse(emptyFragment);
}

export default observer(WhatYouWillLearn);
