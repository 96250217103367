import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Typography } from '../../../components/UI';
import { profileStore } from '../../../ProfileStore';
import { T } from '../../../Translations';
import EmailField from '../RequireAuthentication/EmailField';
import TermsAndConditionsFormField from '../TermsAndConditionsFormField';
import TermsAndConditionsStore from './Store';
import Loading from '../../../components/Loading';

interface Props {
  store: TermsAndConditionsStore;
}

const TermsAndConditionsView: React.FC<Props> = ({ store }) => {
  const { state } = store;
  switch (state.kind) {
    case 'updating':
    case 'loading':
    case 'waiting':
    case 'updated':
      return <Loading />;
    case 'ready':
      return (
        <div className="flex w-[600px] max-w-full flex-col gap-4 md:gap-6">
          <div className="flex sm:gap-6">
            <div className="basis-full sm:basis-1/2">
              <EmailField
                value={profileStore.resource.map(({ payload }) => payload.email).getOrElseValue('')}
              />
            </div>
            <div className="sm:basis-1/2" />
          </div>
          <TermsAndConditionsFormField
            value={state.termsAccepted}
            onValueChange={store.setTermsAcceptance}
            disabled={!store.formInputsEnabled}
          />
          <div className="mt-6">
            <Button size="xl" onClick={store.updating}>
              <T kind="Confirm Registration" />
            </Button>
          </div>
        </div>
      );
    case 'error':
      return (
        <Typography variant="body2" color="black">
          Error: {state.message}
        </Typography>
      );
  }
};

export default observer(TermsAndConditionsView);
