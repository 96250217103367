import * as React from 'react';
import Main from '../../../BrowserSupport/Main';
import I18nTitle from '../../../Title/I18nTitle';
import TechCheckOverview from './TechCheckOverview';
import NumberedInstructions from './NumberedInstructions';
import SelectTime from './SelectTime';
import * as style from '../style.module.css';

const Schedule: React.FC = () => {
  return (
    <>
      <I18nTitle title="FreeBusy Requirements Check" />
      <Main className={style.wrapper}>
        <div className={style.subWrapper}>
          <TechCheckOverview />
          <NumberedInstructions />
          <SelectTime />
        </div>
      </Main>
    </>
  );
};

export default Schedule;
