import { observer } from 'mobx-react';
import * as React from 'react';
import { Alert, Typography } from '../../../../components/UI';
import { NotifiableStore } from '../../../../ErrorActionableReaction';
import { T } from '../../../../Translations';
import { emptyFragment } from '../../../../EmptyFragment';
import FlashMessage from '../../../FlashMessage';
import { InvitationStore } from '../../SharedOpenEnrollment/InvitationStore';

interface Props {
  notifiableStore: NotifiableStore;
  invitationStore: InvitationStore;
}

const AlertMessage: React.FC<Props> = ({ notifiableStore, invitationStore }) => {
  const state = invitationStore.state;
  switch (state.kind) {
    case 'pending-email-confirmation':
      return (
        <Alert className="mb-3 sm:mb-7" variant="outlined" status="success">
          <Typography className="mb-3" variant="body2" color="black">
            <T kind="Please check your email for instructions ..." />
          </Typography>
          <Typography variant="body2" color="black">
            <T kind="You may now close this window." />
          </Typography>
        </Alert>
      );
    case 'requesting-email-address':
    case 'requesting-profile-info':
    case 'waiting':
    case 'authenticating-email-domain':
    case 'validating-email-from-identified-user':
    case 'registering':
    case 'requesting-profile-info-locked-access':
    case 'requesting-profile-info-password-backend':
    case 'registered':
    case 'authenticating-user':
    case 'instant-sso':
    case 'error':
      return notifiableStore.notification
        .map((alert) => <FlashMessage alert={alert} />)
        .getOrElse(emptyFragment);
  }
};

export default observer(AlertMessage);
