import { observer } from 'mobx-react';
import * as React from 'react';
import { T, TPlainTextKey, TranslationsContext, translation } from '../../../../../Translations';
import ArrowRightIcon from '../../../../Cutesies/ArrowRightIcon';
import * as style from '../../../style.module.css';

interface Props {
  text: TPlainTextKey;
}

const DisabledAdvanceButton: React.FC<Props> = ({ text }) => (
  <TranslationsContext.Consumer>
    {(ts) => (
      <button
        className={style.nextButton}
        title={translation('Advance')(ts)}
        role="button"
        aria-disabled={true}
        data-test-next-button={text}
        disabled={true}
      >
        <T kind={text} />
        <div className={style.buttonIcon}>
          <ArrowRightIcon />
        </div>
      </button>
    )}
  </TranslationsContext.Consumer>
);

export default observer(DisabledAdvanceButton);
