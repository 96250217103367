import { observer } from 'mobx-react';
import { ListViewProgramExperience } from '../../../../../../Common/ProgramExperience';
import { useExperiencesContext } from '../../../ExperiencesContext';
import { ShowExperienceItemProps } from './Types';

// Remove this factory and alternates
function ListViewProgramExperienceSwitcher({
  invitationUuid,
  experience,
  enrollmentStore,
}: ShowExperienceItemProps) {
  const experiencesStore = useExperiencesContext();

  return (
    <ListViewProgramExperience
      invitationUuid={invitationUuid}
      experienceResource={experience}
      experiencesStore={experiencesStore}
      enrollmentStore={enrollmentStore}
    />
  );
}

export default observer(ListViewProgramExperienceSwitcher);
