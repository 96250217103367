import { observer } from 'mobx-react';
import { LearnMoreProgramDetails } from '../LearnMoreProgram';
import { ExperienceResource } from '../../Common/Experience/Types';
import ExperienceProductDetails from './ExperienceProductDetails';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceDetailsBody({ experienceResource }: Props) {
  switch (experienceResource.payload.offeringType) {
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      return <ExperienceProductDetails experienceResource={experienceResource} />;
    case 'epc':
    case 'aep':
    case 'msuite':
      return <LearnMoreProgramDetails experienceResource={experienceResource} />;
  }
}

export default observer(ExperienceDetailsBody);
