import { observer } from 'mobx-react';
import { cn } from '@execonline-inc/execonline-ui';
import { SchoolPartnerLogo } from '../../../Common/Experience/SchoolPartner';
import { ExperienceResource } from '../../../Common/Experience/Types';
import ExperiencesLogoSuffix from './ExperiencesLogoSuffix';
import { schoolPartnerLogoProps } from './Types';

interface Props {
  experienceResource: ExperienceResource;
  className?: string;
}

function ExperiencesLogo({ experienceResource, className }: Props) {
  return (
    <div className={cn('mb-4 flex gap-x-3 empty:hidden sm:mb-6', className)}>
      <SchoolPartnerLogo
        {...schoolPartnerLogoProps(experienceResource.payload)}
        data={experienceResource.payload.schoolPartner}
      />
      <ExperiencesLogoSuffix experience={experienceResource.payload} />
    </div>
  );
}

export default observer(ExperiencesLogo);
