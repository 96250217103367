import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import SectionBody from './SectionBody';
import SectionTitle from './SectionTitle';
import SectionSubtitle from './SectionSubtitle';
import SectionHeader from './SectionHeader';
import SectionFooter from './SectionFooter';

interface Props extends HTMLAttributes<HTMLDivElement> {}

const Section: React.FC<Props> = ({ className, children, ...others }) => {
  return (
    <section className={twMerge(clsx('flex flex-col gap-6', className))} {...others}>
      {children}
    </section>
  );
};

export default Object.assign(Section, {
  Header: SectionHeader,
  Title: SectionTitle,
  Subtitle: SectionSubtitle,
  Body: SectionBody,
  Footer: SectionFooter,
});
