import { PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { fromEmpty, isNothing } from 'maybeasy';
import { ArrowBackIcon, ArrowForwardIcon } from '../../../../../../components/UI';
import CarouselStore from '../CarouselStore';
import { CarouselPage } from '../Types';
import NavigationButton from './NavigationButton';
import NavigationDots from './NavigationDots';

interface Props<T> {
  carouselStore: CarouselStore<T>;
}

function ClassicCarouselWithButtons<T>({ carouselStore, children }: PropsWithChildren<Props<T>>) {
  const getNavigationButtonStatus = (items: ReadonlyArray<CarouselPage<T>>) => {
    return isNothing(fromEmpty(items));
  };

  return (
    <div className="relative -mx-11 overflow-visible">
      <div className="flex justify-between gap-x-1">
        <NavigationButton
          icon={ArrowBackIcon}
          disabled={getNavigationButtonStatus(carouselStore.precedingPages)}
          onClick={carouselStore.retreat}
        />
        {children}
        <NavigationButton
          icon={ArrowForwardIcon}
          disabled={getNavigationButtonStatus(carouselStore.succeedingPages)}
          onClick={carouselStore.advance}
        />
      </div>
      <NavigationDots dots={carouselStore.navDots} onDotClick={carouselStore.gotoPage} />
    </div>
  );
}

export default observer(ClassicCarouselWithButtons);
