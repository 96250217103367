import { observer } from 'mobx-react';
import * as React from 'react';
import { handleEnterKeyPress } from '../../../../../Accessibility/KeyboardEvents';
import FormField from '../../../../Form/FormField';
import TextField from '../../../../Form/TextField';
import UsePasswordStore from '../../UsePasswordStore';

interface Props {
  store: UsePasswordStore;
}

const PasswordField: React.FC<Props> = ({ store }) => (
  <FormField label="Password">
    <TextField
      type="password"
      value={store.password.getOrElseValue('')}
      onKeyDown={handleEnterKeyPress(store.creating)}
      onChange={(e) => store.setPassword(e.target.value)}
    />
  </FormField>
);

export default observer(PasswordField);
