import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';

import SegmentStore from '../../../../../SegmentStore';
import DisabledButton from './DisabledButton';
import EnabledButton from './EnabledButton';
import { Maybe } from 'maybeasy';
import { TPlainTextKey } from '../../../../../Translations';

interface Props {
  storeState: SegmentStore['state']['kind'];
  submit: () => void;
  btnStyle: string;
  btnIcon: Maybe<JSX.Element>;
  text: TPlainTextKey;
}

const StateDependentButton: React.FC<Props> = ({ storeState, submit, btnStyle, btnIcon, text }) => {
  switch (storeState) {
    case 'waiting':
    case 'loading':
    case 'processing-request':
    case 'auto-saving-form-fields':
      return <DisabledButton text="Saving..." btnStyle={btnStyle} btnIcon={btnIcon} />;
    case 'reporting-results':
    case 'ready':
    case 'error':
    case 'completing':
    case 'advancing':
    case 'loaded':
    case 'completing-and-advancing':
    case 'submitting-and-advancing':
    case 'advancing-to':
    case 'schedule-session':
      return <EnabledButton text={text} submit={submit} btnStyle={btnStyle} btnIcon={btnIcon} />;
    default:
      assertNever(storeState);
  }
};

export default observer(StateDependentButton);
