import { observer } from 'mobx-react';
import { ProgramSequenceProductDetails } from '../../../../Common/Experience/Types';
import { SchoolPartnerLogo } from '../../../../Common/Experience/SchoolPartner';
import { T } from '../../../../../../Translations';
import { Typography } from '@execonline-inc/execonline-ui';

interface Props {
  className?: string;
  productDetails: ProgramSequenceProductDetails;
}

function SchoolPartners({ productDetails }: Props) {
  return (
    <div>
      <Typography variant="h5" fontWeight="bold">
        <T kind="School Partner" />
      </Typography>
      <Typography variant="subtitleSmall" className="mt-4">
        <T kind="As part of this learning journey you will complete programs from the following partners." />
      </Typography>
      <div className="mt-8 flex flex-row flex-wrap gap-4 sm:mt-12 lg:gap-12">
        {productDetails.schoolPartnerResources.map((schoolPartnerLogo, index) => {
          return (
            <SchoolPartnerLogo
              logoLinkType="logo-on-white"
              data={schoolPartnerLogo}
              height={50}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}

export default observer(SchoolPartners);
