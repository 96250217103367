import { observer } from 'mobx-react';
import { ExperienceResource } from '../../../Common/Experience/Types';
import ExperiencesStore from '../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { LearnMoreProgramHero } from '../../LearnMoreProgram';
import ExperienceProductDetails from './ExperienceProductDetails';

interface Props {
  experienceResource: ExperienceResource;
  experiencesStore: ExperiencesStore;
}

function HeroBody({ experienceResource, experiencesStore }: Props) {
  switch (experienceResource.payload.offeringType) {
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      return (
        <ExperienceProductDetails
          experiencesStore={experiencesStore}
          experienceResource={experienceResource}
        />
      );
    case 'aep':
    case 'epc':
    case 'msuite':
      return (
        <LearnMoreProgramHero
          experiencesStore={experiencesStore}
          experienceResource={experienceResource}
        />
      );
  }
}

export default observer(HeroBody);
