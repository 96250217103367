import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../../Translations';
import { Button } from '../../../../../components/UI';
import TermsAndConditionsFormField from '../../../TermsAndConditionsFormField';
import { InvitationStore } from '../../InvitationStore';
import { LockedAccessState, State } from '../../InvitationStore/Types';
import EmailField from '../EmailField';
import ProfileFields from './ProfileFields';
import LockedUserStatusMessage from './LockedUserStatusMessage';
import { emptyFragment } from '../../../../../EmptyFragment';

interface Props {
  store: InvitationStore;
  state: LockedAccessState;
}

interface LockedUserMessageProps {
  state: State;
}

const LockedUserMessage: React.FC<LockedUserMessageProps> = ({ state }): JSX.Element => {
  switch (state.kind) {
    case 'registering':
    case 'requesting-profile-info':
    case 'authenticating-email-domain':
    case 'authenticating-user':
    case 'error':
    case 'instant-sso':
    case 'pending-email-confirmation':
    case 'requesting-profile-info-password-backend':
    case 'registered':
    case 'requesting-email-address':
    case 'validating-email-from-identified-user':
    case 'waiting':
      return emptyFragment();
    case 'requesting-profile-info-locked-access':
      return <LockedUserStatusMessage />;
  }
};

const ProfileForm: React.FC<Props> = ({ store, state }) => {
  return (
    <>
      <LockedUserMessage state={store.state} />
      <div className="w-[600px] max-w-full">
        <div className="mb-4">
          <EmailField store={store} value={state.emailAddress} />
          <ProfileFields store={store} state={state} />
        </div>
        <div>
          <TermsAndConditionsFormField
            value={state.termsAccepted}
            onValueChange={store.setTermsAcceptance}
            disabled={!store.formInputsEnabled}
          />
        </div>
        <div className="mt-6">
          <Button size="xl" onClick={store.registering}>
            <T kind="Confirm Registration" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default observer(ProfileForm);
