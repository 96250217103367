import { observer } from 'mobx-react';
import clsx from 'clsx';
import { when } from '@execonline-inc/maybe-adapter';
import { emptyFragment } from '../../../EmptyFragment';
import CheckIcon from '../../Cutesies/CheckIcon';

interface CheckboxProps {
  checked: boolean;
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, className }) => {
  return (
    <div
      className={clsx(
        'h-5 w-5 rounded-sm',
        'flex-shrink-0 border border-solid',
        when(checked, true)
          .map(() => 'border-[#0066ff] bg-[#0066ff]')
          .getOrElseValue('border-black'),
        className,
      )}
    >
      {when(checked, true)
        .map(() => <CheckIcon fillColor="white" />)
        .getOrElse(emptyFragment)}
    </div>
  );
};
export default observer(Checkbox);
