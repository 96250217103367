import { observer } from 'mobx-react';
import { T, TPlainTextKey } from '../../../../../../../../Translations';
import { Maybe, just } from 'maybeasy';
import GoBackButton from '../GoBackButton';
import { Typography } from '@execonline-inc/execonline-ui';
import { useExperiencesContext } from '../../../ExperiencesContext';

interface Props {
  searchValue: Maybe<string>;
  headerTitle: TPlainTextKey;
}

function ExperienceTitleWithSearch({ searchValue, headerTitle }: Props) {
  const experienceStore = useExperiencesContext();

  return just({})
    .assign('search', searchValue)
    .assign('useCase', experienceStore.useCase)
    .map(({ useCase, search }) => (
      <>
        <Typography variant="h5" className="mb-4">
          <T
            kind={`Search Results for "{{term}}" in {{useCase}}`}
            term={search}
            useCase={useCase.name.text}
          />
        </Typography>
        <GoBackButton />
      </>
    ))
    .getOrElse(() => <T kind={headerTitle} />);
}

export default observer(ExperienceTitleWithSearch);
