import { observer } from 'mobx-react';
import React from 'react';
import { emptyFragment } from '../../../../EmptyFragment';
import { ExperienceResource } from '../../Common/Experience/Types';
import Content from './Content';

interface Props {
  experienceResource: ExperienceResource;
}

const DeliveryChannelSection: React.FC<Props> = ({ experienceResource }) => {
  switch (experienceResource.payload.registrationInvitationKind) {
    case 'new-program-family-shared-open-enrollment':
      return experienceResource.payload.deliveryChannel
        .map((deliveryChannel) => (
          <Content deliveryChannel={deliveryChannel} experienceResource={experienceResource} />
        ))
        .getOrElse(emptyFragment);
    case 'program-family-shared-open-enrollment':
    case 'shared-open-enrollment':
    case 'open-enrollment':
    case 'direct-enrollment':
    case 'preview-only':
      return <></>;
  }
};

export default observer(DeliveryChannelSection);
