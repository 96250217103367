import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import React from 'react';
import { FlashAlert } from '../../../Notifications/Types';
import FlashMessage from '../../FlashMessage';

interface Props {
  notification: Maybe<FlashAlert>;
}

const Notification: React.FC<Props> = ({ notification }) =>
  notification
    .map((alert) => (
      <div className="flex justify-center">
        <FlashMessage alert={alert} />
      </div>
    ))
    .getOrElse(emptyFragment);

export default observer(Notification);
