import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { RegistrationType } from '../../../../../ProgramsStore/Types';
import { T } from '../../../../../Translations';

interface Props {
  type: RegistrationType;
}

const RegistrationTypeBadge: React.FC<Props> = ({ type }) => {
  switch (type) {
    case 'Auditor':
      return (
        <div
          className={clsx(
            'bg-primary',
            'flex justify-center',
            'leading-5 text-white uppercase',
            'px-1 mx-2 rounded-sm',
            'text-4xs'
          )}
        >
          <T kind={type} />
        </div>
      );
    case 'Coach':
    case 'Professor':
    case 'Student':
      return <></>;
  }
};

export default observer(RegistrationTypeBadge);
