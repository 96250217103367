import { observer } from 'mobx-react';
import PromotablesStore from './PromotablesStore';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ListViewProgramExperience } from '../../Common/ProgramExperience';
import ExperiencesStore from '../ExperienceSelection/Experiences/ExperiencesStore';
import EnrollmentStore from '../Enrollment/Store';
import FlashMessage from '../../../FlashMessage';
import { errorAlert } from '../../../../Notifications/Types';
import Loading from '../../ExperienceDetailView/Common/Loading';

interface Props {
  invitationUuid: string;
  store: PromotablesStore;
  experiencesStore: ExperiencesStore;
  enrollmentStore: EnrollmentStore;
}

function PromotablesState({ store, experiencesStore, enrollmentStore, invitationUuid }: Props) {
  switch (store.state.kind) {
    case 'loading':
      return <Loading className="bg-transparent" />;
    case 'error': {
      return <FlashMessage alert={errorAlert(store.state)} />;
    }
    case 'no-promotables':
      return emptyFragment();
    case 'ready':
      return (
        <div
          data-testid="learning-collection-promotables-section"
          className="mx-auto flex flex-col bg-inherit px-0 py-10 pb-6 pt-0 !shadow-none xl:container sm:py-8"
        >
          <div data-testid="learning-collection-experience-body" className="px-6 md:px-16">
            {store.state.promotablesResource.payload.map((promotableResource) => {
              switch (promotableResource.payload.style) {
                case 'carousel':
                  // Carousel is not supported yet
                  return emptyFragment();
                case 'stacked':
                  return (
                    <div className="mt-3" key={promotableResource.payload.id}>
                      <ListViewProgramExperience
                        invitationUuid={invitationUuid}
                        experienceResource={promotableResource.payload.experienceResource}
                        experiencesStore={experiencesStore}
                        enrollmentStore={enrollmentStore}
                      />
                    </div>
                  );
              }
            })}
          </div>
        </div>
      );
  }
}

export default observer(PromotablesState);
