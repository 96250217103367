import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as style from './style.module.css';

interface Props {
  total: number;
  currentStepIndex: number;
}

const ProgressBar: React.FC<Props> = ({ total, currentStepIndex }) => {
  const range = (start: number, stop: number, step: number) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

  return (
    <div className={style.root}>
      {range(1, total, 1).map((step) =>
        when(step <= currentStepIndex, true)
          .map(() => <div key={step} className={style.active}></div>)
          .getOrElse(() => <div key={step} className={style.inactive}></div>),
      )}
    </div>
  );
};

export default observer(ProgressBar);
