import {
  base64ToUTF8Decoder,
  eql,
  explicitMaybe,
  jsonParserDecoder,
  pipeD,
  regexDecoder,
  stringLiteral,
  stringToNumberDecoder,
} from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, at, fail, field, number, oneOf, string, succeed } from 'jsonous';
import { fromEmpty } from 'maybeasy';
import {
  DigitalCertificateResourceNode,
  ExpertFeedbackNode,
  LearningPartnerAssignmentNode,
  SharedResourceNode,
} from '../../Announcements/Announcement/Content/Types';
import { resourceDecoder } from '../../Resource/Decoders';
import { coachProfileResourceDecoder } from '../EmbeddedFormFieldAsset/Decoders';
import {
  AlertNode,
  AnnouncementVideoNode,
  CoachingSelectedCoach,
  CoachingSelectedCoachAvatar,
  CoachingSelectedCoachNameNode,
  CoachingSelectedCoachProfileNode,
  CoachingSelectedCoachResource,
  DisplayAssignedCoachNode,
  EmbeddedDocumentNode,
  EmbeddedFormFieldNode,
  ImageType,
  LinkedDocumentNode,
  NodeContext,
  ProgramChatLinkNode,
  ScriptTag,
  SegmentVideoNode,
} from './Types';

const scriptTag = (tags: string[]): ScriptTag => ({
  kind: 'script-tag',
  content: tags.join(';'),
});

const childDecoder: Decoder<string> = field('data', string).andThen((data) => succeed(data));

export const scriptTagDecoder: Decoder<ScriptTag> = succeed({})
  .assign('type', field('type', stringLiteral('script')))
  .assign('tags', field('children', array(childDecoder)))
  .map(({ tags }) => scriptTag(tags));

export const alertDecoder: Decoder<AlertNode> = at(['attribs', 'data-alert-embed'], eql('true'))
  .map(() => {})
  .assign('kind', succeed<'alert-node'>('alert-node'))
  .assign('content', at(['attribs', 'data-alert-content'], string))
  .map<AlertNode>(identity);

export const embeddedDocumentNodeDecoder: Decoder<EmbeddedDocumentNode> = at(
  ['attribs', 'data-document-identity'],
  eql('true')
)
  .map(() => {})
  .assign('kind', succeed<'embedded-document-node'>('embedded-document-node'))
  .assign('uuid', at(['attribs', 'data-asset-uuid'], string))
  .map<EmbeddedDocumentNode>(identity);

export const embeddedFormFieldNodeDecoder: Decoder<EmbeddedFormFieldNode> = at(
  ['attribs', 'data-form-field-identity'],
  eql('true')
)
  .map(() => {})
  .assign('kind', succeed<'embedded-form-field-node'>('embedded-form-field-node'))
  .assign('uuid', at(['attribs', 'data-asset-uuid'], string))
  .map<EmbeddedFormFieldNode>(identity);

export const coachingSelectedCoachNameNodeDecoder: Decoder<CoachingSelectedCoachNameNode> = succeed(
  {}
)
  .assign('kind', succeed<'coaching-selected-coach-name-node'>('coaching-selected-coach-name-node'))
  .assign('name', at(['attribs', 'data-attr-coaching-selected-coach-name'], string))
  .map<CoachingSelectedCoachNameNode>(identity);

export const imageTypeDecoder: Decoder<ImageType> = oneOf([
  stringLiteral<ImageType>('image/jpeg'),
  stringLiteral<ImageType>('image/png'),
]);

export const coachingSelectedCoachAvatarDecoder: Decoder<CoachingSelectedCoachAvatar> = succeed({})
  .assign('type', field('type', imageTypeDecoder))
  .assign('data', field('data', string))
  .assign('src', field('src', string))
  .assign('alt', field('alt', string));

export const coachingSelectedCoachDecoder: Decoder<CoachingSelectedCoach> = succeed({})
  .assign('id', field('id', number))
  .assign('name', field('name', string))
  .assign('initials', field('initials', explicitMaybe(string)))
  .assign('coachAvatar', field('coach_avatar', explicitMaybe(coachingSelectedCoachAvatarDecoder)));

const coachingSelectedCoachResourceDecoder: Decoder<CoachingSelectedCoachResource> = resourceDecoder(
  coachingSelectedCoachDecoder
);

export const coachingSelectedCoachProfileNodeDecoder: Decoder<CoachingSelectedCoachProfileNode> = succeed(
  {}
)
  .assign(
    'kind',
    succeed<'coaching-selected-coach-profile-node'>('coaching-selected-coach-profile-node')
  )
  .assign(
    'coach',
    at(
      ['attribs', 'data-attr-coaching-selected-coach-profile'],
      pipeD(jsonParserDecoder(base64ToUTF8Decoder), coachingSelectedCoachResourceDecoder)
    )
  );

export const displayAssignedCoachNodeDecoder: Decoder<DisplayAssignedCoachNode> = succeed({})
  .assign('kind', succeed<'display-assigned-coach-node'>('display-assigned-coach-node'))
  .assign(
    'coach',
    at(
      ['attribs', 'data-attr-display-assigned-coach'],
      pipeD(jsonParserDecoder(base64ToUTF8Decoder), coachProfileResourceDecoder)
    )
  );

export const videoNodeDecoder = (
  context: NodeContext
): Decoder<AnnouncementVideoNode | SegmentVideoNode> => {
  const base = at(['attribs', 'data-video-identity'], eql('true')).assign(
    'uuid',
    at(['attribs', 'data-asset-uuid'], string)
  );
  switch (context) {
    case 'announcement':
      return base
        .assign('kind', succeed<'announcement-video-node'>('announcement-video-node'))
        .map<AnnouncementVideoNode | SegmentVideoNode>(identity);
    case 'overview':
    case 'agenda':
    case 'assignment-due':
      return base
        .assign('kind', succeed<'segment-video-node'>('segment-video-node'))
        .map<AnnouncementVideoNode | SegmentVideoNode>(identity);
  }
};

export const learningPartnerAssignmentDecoder: Decoder<LearningPartnerAssignmentNode> = at(
  ['attribs', 'data-learning-partner-assignment-identity'],
  eql('true')
).map(() => ({ kind: 'learning-partner-assignment' }));

export const sharedResourceDecoder: Decoder<SharedResourceNode> = at(
  ['attribs', 'data-shared-resource-identity'],
  eql('true')
).map(() => ({ kind: 'shared-resource' }));

export const digitalCertificateResourceDecoder: Decoder<DigitalCertificateResourceNode> = at(
  ['attribs', 'data-digital-certificate-resource-identity'],
  eql('true')
).map(() => ({ kind: 'digital-certificate-resource' }));

export const expertFeedbackDecoder: Decoder<ExpertFeedbackNode> = at(
  ['attribs', 'data-expert-feedback-identity'],
  eql('true')
).map(() => ({ kind: 'expert-feedback-node' }));

const uuidFromCourseDocumentUrlDecoder = regexDecoder(/(?:\/course_documents\/)(.*)/).andThen(
  (result) =>
    fromEmpty(result[1])
      .map((uuid) => succeed(uuid))
      .getOrElse(() => fail('Could not find UUID from the course_documents URL'))
);

export const linkedDocumentNodeDecoder: Decoder<LinkedDocumentNode> = at(
  ['attribs', 'href'],
  uuidFromCourseDocumentUrlDecoder
)
  .map((uuid) => ({ uuid }))
  .assign('kind', succeed<'linked-document-node'>('linked-document-node'))
  .assign(
    'description',
    field('children', array(childDecoder)).map((description) => description.join(' '))
  )
  .assign('href', at(['attribs', 'href'], string));

export const programChatLinkNodeDecoder: Decoder<ProgramChatLinkNode> = succeed({})
  .assign('kind', succeed<'program-chat-link-node'>('program-chat-link-node'))
  .assign('programId', at(['attribs', 'data-attr-program-id'], stringToNumberDecoder));
