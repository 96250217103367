import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import * as React from 'react';

import SegmentStore from '../../../../../SegmentStore';
import DisabledButton from './DisabledButton';
import StateDependentButton from './StateDependentButton';
import { Maybe } from 'maybeasy';
import { TPlainTextKey } from '../../../../../Translations';

interface Props {
  segmentStore: SegmentStore;
  submit: () => void;
  btnStyle: string;
  btnIcon: Maybe<JSX.Element>;
  text: TPlainTextKey;
}

const FormFieldButton: React.FC<Props> = ({ segmentStore, submit, btnStyle, btnIcon, text }) =>
  when(segmentStore.allFieldsAreComplete, {})
    .map(() => (
      <StateDependentButton
        submit={submit}
        storeState={segmentStore.state.kind}
        btnStyle={btnStyle}
        btnIcon={btnIcon}
        text={text}
      />
    ))
    .getOrElse(() => <DisabledButton text={text} btnStyle={btnStyle} btnIcon={btnIcon} />);

export default observer(FormFieldButton);
