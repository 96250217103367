import { useRef } from 'react';
import { observer } from 'mobx-react';
import { IconButton, Menu, useOpenable } from '@execonline-inc/execonline-ui';
import { TranslationsContext } from '@execonline-inc/translations';
import { nothing } from 'maybeasy';
import { emptyFragment } from '../../../../EmptyFragment';
import { profileStore } from '../../../../ProfileStore';
import { translation } from '../../../../Translations';
import Person from '../../../Person';
import { NavigationMenuItems } from '../Common';
import { NavigationBarMenuItemProps } from '../Types';
import { dropdownMenuItems } from './Types';

const menuId = 'accountNavigationMenu';

function AccountMenu({ user }: NavigationBarMenuItemProps) {
  const anchorElementRef = useRef<HTMLButtonElement>(null);
  const { isOpen, onClose, onOpenChange } = useOpenable();

  return profileStore.personStoreContract
    .map((personStore) => (
      <TranslationsContext.Consumer>
        {(ts) => (
          <>
            <IconButton
              id={menuId}
              aria-haspopup="menu"
              aria-expanded={isOpen}
              ref={anchorElementRef}
              className="ml-auto"
              radius="full"
              title={translation('Account Navigation Dropdown Menu')(ts)}
              aria-label={translation('Account Navigation')(ts)}
              onClick={onOpenChange}
            >
              <Person personStore={personStore} presenceStore={nothing()} />
            </IconButton>
            <Menu
              aria-labelledby={menuId}
              open={isOpen}
              anchorElement={anchorElementRef.current}
              placement="bottom-end"
              onClose={onClose}
            >
              <NavigationMenuItems items={dropdownMenuItems(user)} />
            </Menu>
          </>
        )}
      </TranslationsContext.Consumer>
    ))
    .getOrElse(emptyFragment);
}

export default observer(AccountMenu);
