import { observer } from 'mobx-react';
import { ExperienceResources } from '../../../../../../Common/Experience/Types';
import EnrollmentStore from '../../../../../Enrollment/Store';
import FilterStore from '../../../../Filters/FilterStore';
import LanguageFilterStore from '../../../../Filters/LanguageFilter/Store';
import DurationFilterStore from '../../../../Filters/DurationFilter/Store';
import SchoolPartnerFilterStore from '../../../../Filters/SchoolPartnerFilter/Store';
import CompetencyFilterStore from '../../../../Filters/CompetencyFilter/Store';
import ListViewProgramExperience from '../ListViewProgramExperience';

interface Props {
  experiences: ExperienceResources;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
}

function ShowExperiences({
  experiences,
  invitationUuid,
  enrollmentStore,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
}: Props) {
  return (
    <div className="mt-6 flex flex-col gap-3 sm:mt-14">
      {experiences
        .filter((experience) =>
          filterStore.showFilteredExperience(
            experience,
            languageFilterStore,
            durationFilterStore,
            schoolPartnerFilterStore,
            competencyFilterStore,
          ),
        )
        .map((experience) => (
          <ListViewProgramExperience
            key={experience.payload.experienceId}
            invitationUuid={invitationUuid}
            experience={experience}
            enrollmentStore={enrollmentStore}
          />
        ))}
    </div>
  );
}

export default observer(ShowExperiences);
