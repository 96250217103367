import { stringLiteral } from '@execonline-inc/decoders';
import Decoder, { field, oneOf, succeed } from 'jsonous';
import { PaymentIntentError, PaymentIntentErrorType } from './Types';

const paymentIntentErrorTypeDecoder: Decoder<PaymentIntentErrorType> = oneOf([
  stringLiteral<PaymentIntentErrorType>('enrollment-error'),
  stringLiteral<PaymentIntentErrorType>('internal-server-error'),
  stringLiteral<PaymentIntentErrorType>('payment-declined-error'),
]);

export const paymentIntentErrorDecoder: Decoder<PaymentIntentError> = succeed({}).assign(
  'type',
  field('type', paymentIntentErrorTypeDecoder)
);
