import { observer } from 'mobx-react';
import * as React from 'react';
import ThemeContext, { currentTheme } from '../../../../Themes/ThemeContext';
import ConfirmWrapper from './ConfirmWrapper';
import PaymentConfirmationReactions from './Reactions';
import PaymentConfirmationStore from './Store';

interface Props {
  uuid: string;
  paymentIntentIdParam: 'payment_intent';
  clientSecretParam: 'payment_intent_client_secret';
}

class PaymentConfirmation extends React.Component<Props, {}> {
  private store: PaymentConfirmationStore = new PaymentConfirmationStore();

  render() {
    const theme = currentTheme();
    return (
      <ThemeContext.Provider value={theme}>
        <ConfirmWrapper store={this.store} />
        <PaymentConfirmationReactions store={this.store} fireImmediately={true} />
      </ThemeContext.Provider>
    );
  }
}

export default observer(PaymentConfirmation);
