import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

interface Props {
  fillColor: string;
}
const CheckIcon: React.FC<Props> = ({ fillColor }) => {
  return (
    <SVGIcon viewBox="0 0 48 48">
      <path fill={fillColor} d="M40.6 12.1 17 35.7l-9.6-9.6L4.6 29 17 41.3l26.4-26.4" />
    </SVGIcon>
  );
};

export default observer(CheckIcon);
