import { observer } from 'mobx-react';
import { ExperienceResource } from '../../../Common/Experience/Types';
import { just } from 'maybeasy';
import {
  LanguageAvailability,
  ExperienceActions,
  EnrollmentCard,
  ExperienceDescription,
  ExperienceCompetencies,
  ExperienceTitle,
  ExperiencesLogo,
} from '../../Common';

interface Props {
  experienceResource: ExperienceResource;
}

function LearningJourneyHero({ experienceResource }: Props) {
  return (
    <>
      <div className="flex w-full flex-col gap-x-28 lg:flex-row">
        <div className="w-full lg:w-3/5">
          <ExperiencesLogo experienceResource={experienceResource} />
          <ExperienceTitle className="text-white" title={just(experienceResource.payload.title)} />
          <ExperienceCompetencies
            className="mb-4 text-white sm:mb-7"
            textColor="white"
            chipColor="white"
            competencies={experienceResource.payload.primaryCompetencies}
          />
          <ExperienceDescription
            className="mb-10 !text-white sm:mb-12"
            description={experienceResource.payload.publicDetailedDescriptionHtml}
          />
          <LanguageAvailability
            className="text-white sm:mb-8"
            experienceResource={experienceResource}
          />
        </div>
        <div className="mx-auto mt-20 empty:hidden lg:mt-0">
          <EnrollmentCard experienceResource={experienceResource} />
        </div>
      </div>
      <ExperienceActions experienceResource={experienceResource} />
    </>
  );
}
export default observer(LearningJourneyHero);
