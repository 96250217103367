import { observer } from 'mobx-react';
import NotSupportedInLanguageStore from '../../../../../../NotSupportedInLanguageStore';
import { T } from '../../../../../../Translations';
import {
  CommentLanguageIcon,
  Typography,
  CloseIcon,
  IconButton,
} from '@execonline-inc/execonline-ui';

interface Props {
  experienceNotSupportedInLanguageStore: NotSupportedInLanguageStore;
}

function LanguageUnsupportedMessage({ experienceNotSupportedInLanguageStore }: Props) {
  return (
    <div className="relative">
      <div className="absolute h-full w-full bg-black opacity-25" />
      <div className="mx-auto box-border flex w-full justify-between px-8 py-6 xl:container">
        <div className="relative flex h-5 items-center">
          <CommentLanguageIcon color="white" />
          <div className="ml-2.5">
            <Typography variant="bodyMedium" color="white">
              <T kind="This experience is not available in this language." />
            </Typography>
          </div>
        </div>
        <IconButton className="z-[1]" onClick={experienceNotSupportedInLanguageStore.hide}>
          <CloseIcon color="white" />
        </IconButton>
      </div>
    </div>
  );
}

export default observer(LanguageUnsupportedMessage);
