import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Loader from '../../../../components/Loader';
import { findLink } from '../../../../LinkyLinky';
import ErrorMessage from '../../../ErrorMessage';
import Exit from '../../../Exit';
import {
  ExitToDashboard,
  ExitToDiscoveryPortal,
  exitToDashboard,
  exitToDiscoveryPortal,
} from '../../Common/Experience/Actions/Exitable/Types';
import EnrollmentStore from '../Enrollment/Store';
import { RegisterResource } from '../RegisterResourceStore/Types';

interface Props {
  registerResource: RegisterResource;
  enrollmentStore: EnrollmentStore;
}

class DirectEnrollment extends React.Component<Props> {
  componentDidMount() {
    this.props.enrollmentStore.creating(this.props.registerResource, { kind: 'direct' }, nothing());
  }

  render() {
    const { state } = this.props.enrollmentStore;
    switch (state.kind) {
      case 'waiting':
      case 'setting-up':
      case 'waiting-commerce':
      case 'initializing-payment':
      case 'ready-for-payment':
      case 'creating':
      case 'created':
        return <Loader delay={0} />;
      case 'payment-loading-error':
        return <ErrorMessage message={'Unable to fulfill load payment form'} />;
      case 'server-error':
        return <ErrorMessage message={'Unable to fulfill your enrollment request'} />;
      case 'error':
        return <ErrorMessage message={state.message} />;
      case 'exiting':
        const action: ExitToDiscoveryPortal | ExitToDashboard = findLink(
          'direct-enrollment-discovery-portal',
          this.props.registerResource.links,
        )
          .andThen(() => this.props.registerResource.payload.guid)
          .map<ExitToDiscoveryPortal | ExitToDashboard>(exitToDiscoveryPortal)
          .getOrElse(() => exitToDashboard());

        return <Exit exitContext={action} />;
    }
  }
}

export default observer(DirectEnrollment);
