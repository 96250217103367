import React, { HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { fromNullable } from 'maybeasy';
import { DividerOrientation, dividerOrientationClassNameMapping } from './Types';

export interface Props extends HTMLAttributes<HTMLLIElement> {
  orientation?: DividerOrientation;
  component?: React.ElementType;
}

const getComponentName = (component?: React.ElementType): React.ElementType => {
  return fromNullable(component).getOrElseValue('div');
};

const Divider: React.FC<Props> = ({
  className,
  orientation = 'horizontal',
  component,
  ...others
}) => {
  const Component = getComponentName(component);

  return (
    <Component
      className={twMerge(
        clsx('border-b-gray-200-old', dividerOrientationClassNameMapping[orientation], className),
      )}
      role="separator"
      {...others}
    />
  );
};

export default observer(Divider);
