import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../ProgramStore';
import SegmentStore from '../../../SegmentStore';
import * as style from '../style.module.css';
import AdvanceButton from './AdvanceButton';

interface Props {
  store: SegmentStore;
  programStore: ProgramStore;
}

const SegmentNavigation: React.FC<Props> = ({ store, programStore }) =>
  programStore.progress
    .map((progress) => {
      switch (progress.kind) {
        case 'program-progress':
        case 'module-progress':
          return (
            <div className={style.segmentButtons}>
              <AdvanceButton store={store} />
            </div>
          );
        case 'registration-segment-progress':
          return (
            <div className={style.segmentButtons}>
              <AdvanceButton store={store} text="Next Step" />
            </div>
          );
      }
    })
    .getOrElseValue(<></>);

export default observer(SegmentNavigation);
