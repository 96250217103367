import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../../../../SegmentStore';
import { AutoLaunchableStore } from '../../../../../AutoLaunchable/AutoLaunchableStore';
import Loader from '../../../../../Loader';
import * as style from '../style.module.css';
import SegmentContent from './SegmentContent';

interface Props {
  longTitle: string;
  store: SegmentStore;
  autoLaunchableStore: Maybe<AutoLaunchableStore>;
}

const ContentStates: React.FC<Props> = ({ store, longTitle, autoLaunchableStore }) => {
  switch (store.state.kind) {
    case 'ready':
    case 'processing-request':
    case 'completing':
    case 'reporting-results':
    case 'auto-saving-form-fields':
    case 'schedule-session':
      return (
        <>
          <SegmentContent
            store={store}
            segmentResource={store.state.segmentResource}
            longTitle={longTitle}
            autoLaunchableStore={autoLaunchableStore}
          />
        </>
      );
    case 'completing-and-advancing':
    case 'submitting-and-advancing':
    case 'advancing':
    case 'advancing-to':
    case 'error':
    case 'waiting':
    case 'loading':
    case 'loaded':
      return (
        <div className={style.content}>
          <Loader color="black" type="spin" />
        </div>
      );
  }
};

export default observer(ContentStates);
