import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { directSegmentRoute, experienceDetailsRoute } from '../../../../ClientRoutes';
import DiscoveryContext from '../../DiscoveryContext';
import ExperienceDetailViewStore from '../../ExperienceDetailView/ExperienceDetailViewStore';
import ExperienceDetailViewReactions from '../../ExperienceDetailView/ExperienceDetailViewStore/ExperienceDetailViewReactions';
import ExperiencesStore from '../ExperienceSelection/Experiences/ExperiencesStore';
import { RegisterResource } from '../RegisterResourceStore/Types';
import EnrollmentStore from './Store';

interface Props {
  experienceId: string;
  invitationUuid: string;
  registerResource: RegisterResource;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
}

class GoToProgram extends React.Component<Props> {
  experienceDetailViewStore = new ExperienceDetailViewStore();

  componentDidMount() {
    this.experienceDetailViewStore.loading(this.props.experienceId, this.props.registerResource);
  }

  render() {
    return this.experienceDetailViewStore.experienceResource
      .map((r) => {
        switch (r.payload.kind) {
          case 'resumable':
          case 'beginnable':
            return <Redirect to={directSegmentRoute(r.payload)} />;
          case 'upcoming':
          case 'enrollable':
          case 'graduated':
          case 'returnable':
          case 'not-enrollable':
          case 'not-licensed':
          case 'commerce-enrollable':
            return (
              <DiscoveryContext.Consumer>
                {(discoveryContextState) => (
                  <Redirect
                    to={experienceDetailsRoute(
                      this.props.invitationUuid,
                      r.payload.experienceId,
                      discoveryContextState,
                    )}
                  />
                )}
              </DiscoveryContext.Consumer>
            );
        }
      })
      .getOrElse(() => (
        <ExperienceDetailViewReactions
          store={this.experienceDetailViewStore}
          discoveryPortal={this.props.experiencesStore.discoveryPortal}
        />
      ));
  }
}

export default observer(GoToProgram);
