import React from 'react';
import ExperiencesStore from '../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { ExperienceResource } from '../../Experience/Types';

export type ExperiencePopoverContextState = {
  invitationUuid: string;
  experienceResource: ExperienceResource;
  experiencesStore: ExperiencesStore;
  onClose: () => void;
};

export const ExperiencePopoverContext = React.createContext<ExperiencePopoverContextState | null>(
  null,
);
