import { find } from '@execonline-inc/collections';
import { always, assertNever, identity } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { fromArrayMaybe, NonEmptyList } from 'nonempty-list';
import { whenAny } from '../../../../Collections';
import {
  AvailabilityResource,
  ExperienceResource,
  KeyTakeaways,
  TimeCommitment,
} from '../../Common/Experience/Types';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import { error, loading, ready, State, waiting } from './Types';

class ExperienceDetailViewStore {
  @observable
  state: State = waiting();

  public displayAvailabilityBorder = createTransformer((availabilityIndex: number): boolean =>
    this.scheduledAvailabilities
      .map((scheduledAvailabilities) => scheduledAvailabilities.length !== availabilityIndex + 1)
      .getOrElseValue(false),
  );

  @action
  loading = (experienceId: string, registerResource: RegisterResource): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        this.state = loading(experienceId, registerResource);
        break;
      case 'loading':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (experienceResource: ExperienceResource): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading':
        this.state = ready(experienceResource);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: string): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading':
        this.state = error(message);
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get experienceResource(): Maybe<ExperienceResource> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.experienceResource);
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get currentProgramModuleSegment(): Maybe<{
    programId: number;
    moduleId: number;
    segmentId: number;
  }> {
    return this.experienceResource.andThen((r) => {
      switch (r.payload.kind) {
        case 'resumable':
        case 'beginnable':
          return just(r.payload);
        case 'upcoming':
        case 'enrollable':
        case 'graduated':
        case 'commerce-enrollable':
        case 'returnable':
        case 'not-enrollable':
        case 'not-licensed':
          return nothing();
      }
    });
  }

  @computed
  get onDemandAvailability(): Maybe<AvailabilityResource> {
    switch (this.state.kind) {
      case 'ready':
        return find(
          (a: AvailabilityResource) => a.payload.kind === 'on-demand',
          this.state.experienceResource.payload.availabilities,
        );
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get scheduledAvailabilities(): Maybe<NonEmptyList<AvailabilityResource>> {
    switch (this.state.kind) {
      case 'ready':
        return fromArrayMaybe(
          this.state.experienceResource.payload.availabilities.filter(
            (a) => a.payload.kind === 'scheduled',
          ),
        );
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get timeCommitment(): Maybe<TimeCommitment> {
    switch (this.state.kind) {
      case 'ready': {
        const { duration, hoursPerWeek, totalHours } = this.state.experienceResource.payload;
        return whenAny(identity, [duration, hoursPerWeek, totalHours]).map(
          always({ duration, hoursPerWeek, totalHours }),
        );
      }
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get keyTakeaways(): Maybe<KeyTakeaways> {
    switch (this.state.kind) {
      case 'ready':
        const { keyTakeawaysHtml, programStructureAndFeaturesHtml, howItWorksHtml } =
          this.state.experienceResource.payload;
        return whenAny(identity, [
          keyTakeawaysHtml,
          programStructureAndFeaturesHtml,
          howItWorksHtml,
        ]).map(always({ keyTakeawaysHtml, programStructureAndFeaturesHtml, howItWorksHtml }));
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }
}

export default ExperienceDetailViewStore;
