import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../../../Translations';
import { EnrollmentPayload } from '../../../Enrollment/Store/Types';

interface Props {
  enrollment: EnrollmentPayload;
}

const ModalButtonLabel: React.FC<Props> = ({ enrollment }) => {
  switch (enrollment.program.payload.kind) {
    case 'active':
    case 'completed':
    case 'expired':
    case 'inactive':
      return <T kind="Continue" />;
    case 'upcoming':
      return <T kind="Go to dashboard" />;
  }
};

export default observer(ModalButtonLabel);
