import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../../SegmentStore';
import { CoachSelectFormField, SelectFormField } from '../../Types';
import Select from './Select';
import CoachSelect from './CoachSelect';

interface Props {
  segmentStore: SegmentStore;
  fieldDescription: SelectFormField | CoachSelectFormField;
  uuid: string;
}

const SelectField: React.FC<Props> = ({ segmentStore, fieldDescription, uuid }) => {
  switch (fieldDescription.name) {
    case 'coach-select':
      const coachSelectFieldDescription = fieldDescription as CoachSelectFormField;
      return (
        <CoachSelect
          segmentStore={segmentStore}
          fieldDescription={coachSelectFieldDescription}
          uuid={uuid}
        />
      );
    default:
      return <Select segmentStore={segmentStore} fieldDescription={fieldDescription} uuid={uuid} />;
  }
};

export default observer(SelectField);
