import { fromNullable, just } from 'maybeasy';
import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from '../../../Resource/Types';
import SegmentStore from '../../../SegmentStore';
import { HtmlNode, WebPageSegment } from '../../../SegmentStore/Types';
import { nodeDecoder } from '../../ContentEmbed/Types';
import SegmentEmbed from '../../SegmentEmbed';
import { whenSegmentEmbeddable } from '../../SegmentEmbed/Types';
import * as style from './style.module.css';

interface Props {
  segment: WebPageSegment;
  segmentStore: SegmentStore;
  links: ReadonlyArray<Link>;
}

const hasNoDataPrefix = (obj: object | undefined) =>
  fromNullable(obj)
    .map((obj) => Object.keys(obj).every((key) => !key.startsWith('data-')))
    .getOrElse(() => true);

function OverviewSegment({ segment, segmentStore, links }: Props) {
  const transform = (htmlNode: HtmlNode, index: number) => {
    if (hasNoDataPrefix(htmlNode.attribs)) return;

    return nodeDecoder('overview')
      .decodeAny(htmlNode)
      .andThen(whenSegmentEmbeddable)
      .cata({
        Err: (err) => undefined, // react-html-parser requires we return undefined here.
        Ok: (node) => (
          <SegmentEmbed
            segmentStore={just(segmentStore)}
            key={index}
            segment={segment}
            node={node}
            links={links}
          />
        ),
      });
  };

  return (
    <div data-test-overview-segment={segment.id} className={style.overviewSegment}>
      <div className={segment.showVideosFullWidth === 'full-width' ? 'p3-video-full-width' : ''}>
        {ReactHtmlParser(segment.content, { transform: transform })}
      </div>
    </div>
  );
}

export default observer(OverviewSegment);
