import { observer } from 'mobx-react';
import * as React from 'react';
import SVGIcon from '../SVGIcon';

const SuccessIcon: React.FC = () => (
  <SVGIcon style={{ width: 163, height: 163 }} viewBox="0 0 163 163">
    <path
      fill="#1A8927"
      d="M81.5 15.281c-36.512 0-66.219 29.707-66.219 66.219 0 36.512 29.707 66.219 66.219 66.219 36.512 0 66.219-29.707 66.219-66.219 0-36.512-29.707-66.219-66.219-66.219Zm0 10.188c31 0 56.031 25.03 56.031 56.031 0 31-25.031 56.031-56.031 56.031S25.469 112.5 25.469 81.5 50.499 25.469 81.5 25.469Zm31.995 31.995L76.406 94.553 54.6 72.745l-7.323 7.322 25.47 25.469 3.66 3.502 3.661-3.502 40.75-40.75-7.322-7.322Z"
    />
  </SVGIcon>
);

export default observer(SuccessIcon);
