import { explicitMaybe, mergeObjectDecoders, stringLiteral } from '@execonline-inc/decoders';
import { alreadyTranslatedText } from '@execonline-inc/translations';
import { identity } from '@kofno/piper';
import Decoder, {
  array,
  date,
  dateISO,
  fail,
  field,
  number,
  oneOf,
  string,
  succeed,
} from 'jsonous';
import {
  languageResourceDecoder,
  languagesResourceDecoder,
} from '../../../../../ProfileFormStore/Decoders';
import { resourceDecoder } from '../../../../../Resource/Decoders';
import { Resource } from '../../../../../Resource/Types';
import { commerceTypeDecoder } from '../../../SharedOpenEnrollment/SharedInvitationResourceStore/Decoders';
import { SchoolPartner, SchoolPartnerType } from '../../../Common/Experience/SchoolPartner';
import {
  Availability,
  AvailabilityResource,
  AvailabilityResources,
  BeginnableExperienceParts,
  CoachingProductDetails,
  CommerceEnrollableExperienceParts,
  Competency,
  CompetencyResource,
  ConflictingProgram,
  ConflictingProgramKind,
  DeliveryChannel,
  DiscoveryPortalPayload,
  DiscoveryPortalResource,
  Duration,
  DurationResource,
  Durations,
  EnrollableExperienceParts,
  Experience,
  ExperienceBase,
  ExperienceFilters,
  ExperienceFiltersResource,
  ExperienceParts,
  ExperienceResource,
  GraduatedExperienceParts,
  NotEnrollableAlert,
  NotEnrollableAlertResource,
  NotEnrollableExperienceParts,
  OfferingType,
  OnDemandAvailability,
  PerPageCount,
  ProductCollection,
  ProductCollectionKind,
  RegistrationInvitationKind,
  ResumableExperienceParts,
  ReturnableExperienceParts,
  ScheduledAvailability,
  UpcomingExperienceParts,
  UseCase,
  ProductCollectionDisplayType,
  UseCaseDisplayType,
  ProgramSequenceProductDetails,
  ProductDetails,
  CoachingProfile,
  CoachingProfileResource,
  GroupCoachingProductDetails,
  GroupCoachingSessionDetails,
  GroupCoachingSessionResource,
  GroupCoachingSessionResources,
  ProgramSequenceProductDetailsSections,
  NotLicensedExperienceParts,
  ExperienceResourceFilter,
} from '../../../Common/Experience/Types';

const schoolPartnerDecoder: Decoder<SchoolPartner> = succeed({})
  .assign(
    'kind',
    field(
      'kind',
      oneOf([
        stringLiteral<SchoolPartnerType>('first-party-school-partner'),
        stringLiteral<SchoolPartnerType>('third-party-school-partner'),
      ]),
    ),
  )
  .assign('id', field('id', number))
  .assign('name', field('name', alreadyTranslatedText));

export const schoolPartnerResourceDecoder: Decoder<Resource<SchoolPartner>> =
  resourceDecoder(schoolPartnerDecoder);

const conflictingProgramKindDecoder: Decoder<ConflictingProgramKind> = oneOf([
  stringLiteral('enrolled-on-demand').map<ConflictingProgramKind>(identity),
  stringLiteral('enrolled-scheduled').map<ConflictingProgramKind>(identity),
  stringLiteral('overlaps-with-existing').map<ConflictingProgramKind>(identity),
  stringLiteral('availability-in-progress').map<ConflictingProgramKind>(identity),
  stringLiteral('experience-in-progress').map<ConflictingProgramKind>(identity),
]);

const conflictingProgramDecoder: Decoder<ConflictingProgram> = succeed({})
  .assign('programId', field('program_id', number))
  .assign('kind', field('kind', conflictingProgramKindDecoder))
  .assign('startOn', field('start_on', explicitMaybe(dateISO)))
  .assign('endOn', field('end_on', explicitMaybe(dateISO)));

const scheduledAvailabilityDecoder: Decoder<ScheduledAvailability> = succeed({})
  .assign('kind', field('kind', stringLiteral('scheduled')))
  .assign('programId', field('program_id', number))
  .assign('date', field('date', dateISO))
  .assign(
    'conflictingProgram',
    field('conflicting_program', explicitMaybe(conflictingProgramDecoder)),
  );

const onDemandAvailabilityDecoder: Decoder<OnDemandAvailability> = succeed({})
  .assign('kind', field('kind', stringLiteral('on-demand')))
  .assign('programId', field('program_id', number))
  .assign(
    'conflictingProgram',
    field('conflicting_program', explicitMaybe(conflictingProgramDecoder)),
  );

const availabilityDecoder: Decoder<Availability> = oneOf<Availability>([
  scheduledAvailabilityDecoder.map<Availability>(identity),
  onDemandAvailabilityDecoder.map<Availability>(identity),
]);

const availabilityResourceDecoder: Decoder<AvailabilityResource> =
  resourceDecoder(availabilityDecoder);

const availabilityResourcesDecoder: Decoder<AvailabilityResources> = array(
  availabilityResourceDecoder,
).map<AvailabilityResources>(identity);

const graduatedExperiencePartsDecoder: Decoder<GraduatedExperienceParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('graduated')))
  .assign('programId', field('program_id', number))
  .assign('startOn', field('start_on', dateISO))
  .assign('courseCompletedAt', field('course_completed_at', dateISO))
  .assign('courseCompletedDate', field('course_completed_date', explicitMaybe(dateISO)));

const notEnrollablePartsDecoder: Decoder<NotEnrollableExperienceParts> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('not-enrollable')),
);

const enrollablePartsDecoder: Decoder<EnrollableExperienceParts> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('enrollable')),
);

const notLicensedPartsDecoder: Decoder<NotLicensedExperienceParts> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('not-licensed')),
);

const commerceEnrollablePartsDecoder: Decoder<CommerceEnrollableExperienceParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('commerce-enrollable')))
  .assign('programId', field('program_id', number));

const resumablePartsDecoder: Decoder<ResumableExperienceParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('resumable')))
  .assign('programId', field('program_id', number))
  .assign('moduleId', field('module_id', number))
  .assign('segmentId', field('segment_id', number));

const beginnablePartsDecoder: Decoder<BeginnableExperienceParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('beginnable')))
  .assign('programId', field('program_id', number))
  .assign('moduleId', field('module_id', number))
  .assign('segmentId', field('segment_id', number));

const returnablePartsDecoder: Decoder<ReturnableExperienceParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('returnable')))
  .assign('programId', field('program_id', number))
  .assign('startOn', field('start_on', dateISO))
  .assign('courseCompletedAt', field('course_completed_at', dateISO))
  .assign('courseCompletedDate', field('course_completed_date', explicitMaybe(dateISO)));

const upcomingPartsDecoder: Decoder<UpcomingExperienceParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('upcoming')))
  .assign('startOn', field('start_on', dateISO));

export const partsDecoder: Decoder<ExperienceParts> = oneOf<ExperienceParts>([
  commerceEnrollablePartsDecoder.map<ExperienceParts>(identity),
  notEnrollablePartsDecoder.map<ExperienceParts>(identity),
  enrollablePartsDecoder.map<ExperienceParts>(identity),
  graduatedExperiencePartsDecoder.map<ExperienceParts>(identity),
  resumablePartsDecoder.map<ExperienceParts>(identity),
  beginnablePartsDecoder.map<ExperienceParts>(identity),
  returnablePartsDecoder.map<ExperienceParts>(identity),
  upcomingPartsDecoder.map<ExperienceParts>(identity),
  notLicensedPartsDecoder.map<ExperienceParts>(identity),
]);

const deliveryChannelDecoder: Decoder<DeliveryChannel> = oneOf([
  stringLiteral<DeliveryChannel>('Email Campaign'),
  stringLiteral<DeliveryChannel>('L&D Newsletter'),
  stringLiteral<DeliveryChannel>('LMS (Degreed)'),
  stringLiteral<DeliveryChannel>('LMS (Workday Learning)'),
  stringLiteral<DeliveryChannel>('LMS (Docebo)'),
  stringLiteral<DeliveryChannel>('LMS (Edcast)'),
  stringLiteral<DeliveryChannel>('LMS (Gloat)'),
  stringLiteral<DeliveryChannel>('Testing'),
  stringLiteral<DeliveryChannel>('Social Sharing'),
  stringLiteral<DeliveryChannel>('Other'),
]);

const registrationInvitationKindDecoder: Decoder<RegistrationInvitationKind> = oneOf([
  stringLiteral<RegistrationInvitationKind>('new-program-family-shared-open-enrollment'),
  stringLiteral<RegistrationInvitationKind>('program-family-shared-open-enrollment'),
  stringLiteral<RegistrationInvitationKind>('shared-open-enrollment'),
  stringLiteral<RegistrationInvitationKind>('open-enrollment'),
  stringLiteral<RegistrationInvitationKind>('direct-enrollment'),
  stringLiteral<RegistrationInvitationKind>('preview-only'),
]);

export const offeringTypeDecoder: Decoder<OfferingType> = oneOf<OfferingType>([
  stringLiteral<OfferingType>('epc'),
  stringLiteral<OfferingType>('aep'),
  stringLiteral<OfferingType>('msuite'),
  stringLiteral<OfferingType>('coaching'),
  stringLiteral<OfferingType>('group-coaching'),
  stringLiteral<OfferingType>('program-sequence'),
]);

const coachingProductDetailsDecoder: Decoder<CoachingProductDetails> = succeed({})
  .assign('kind', field('kind', stringLiteral('leadership-coaching')))
  .assign('keyBenefitsHtml', field('key_benefits_html', explicitMaybe(alreadyTranslatedText)))
  .assign(
    'pullQuoteReviewsHtml',
    field('pull_quote_reviews_html', explicitMaybe(alreadyTranslatedText)),
  )
  .assign('howBestToUseHtml', field('how_best_to_use_html', explicitMaybe(alreadyTranslatedText)));

const programSequenceProductDetailsSectionsDecoder: Decoder<ProgramSequenceProductDetailsSections> =
  succeed({})
    .assign('title', field('title', alreadyTranslatedText))
    .assign('description', field('description', alreadyTranslatedText))
    .assign('programs', field('programs', array(alreadyTranslatedText)))
    .assign('label', field('label', alreadyTranslatedText));

const programSequenceProductDetailsDecoder: Decoder<ProgramSequenceProductDetails> = succeed({})
  .assign('kind', field('kind', stringLiteral('program-sequence')))
  .assign(
    'schoolPartnerResources',
    field('school_partner_resources', array(schoolPartnerResourceDecoder)),
  )
  .assign('sections', field('sections', array(programSequenceProductDetailsSectionsDecoder)))
  .assign('sectionsCount', field('sections_count', number));

const groupCoachDecoder: Decoder<CoachingProfile> = succeed({})
  .assign('id', field('id', number))
  .assign('userId', field('user_id', number))
  .assign('name', field('name', alreadyTranslatedText));

export const groupCoachResourceDecoder: Decoder<CoachingProfileResource> =
  resourceDecoder(groupCoachDecoder);

const groupSessionDecoder: Decoder<GroupCoachingSessionDetails> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', explicitMaybe(alreadyTranslatedText)))
  .assign('description', field('description', explicitMaybe(alreadyTranslatedText)))
  .assign('startTime', field('start_time', explicitMaybe(date)))
  .assign(
    'groupCoachProfile',
    field('group_coach_resource', explicitMaybe(groupCoachResourceDecoder)),
  )
  .assign('duration', field('duration', explicitMaybe(number)));

export const groupSessionResourceDecoder: Decoder<GroupCoachingSessionResource> =
  resourceDecoder(groupSessionDecoder);

const groupSessionResourcesDecoder: Decoder<GroupCoachingSessionResources> = array(
  groupSessionResourceDecoder,
).map<GroupCoachingSessionResources>(identity);

export const groupSessionOutherResourceDecoder: Decoder<Resource<GroupCoachingSessionResources>> =
  resourceDecoder(groupSessionResourcesDecoder);

const groupCoachingProductDetailsDecoder: Decoder<GroupCoachingProductDetails> = succeed({})
  .assign('id', field('id', number))
  .assign('kind', field('kind', stringLiteral('group-coaching')))
  .assign('title', field('title', explicitMaybe(alreadyTranslatedText)))
  .assign('description', field('description', explicitMaybe(alreadyTranslatedText)))
  .assign(
    'groupCoachingSessions',
    field('group_coaching_sessions', explicitMaybe(groupSessionOutherResourceDecoder)),
  )
  .assign('remainingSeats', field('remaining_seats', explicitMaybe(number)))
  .assign('sessionDurationInMinutes', field('session_duration_in_minutes', number));

const productDetailsDecoder: Decoder<ProductDetails> = oneOf([
  programSequenceProductDetailsDecoder.map<ProductDetails>(identity),
  coachingProductDetailsDecoder.map<ProductDetails>(identity),
  groupCoachingProductDetailsDecoder.map<ProductDetails>(identity),
]);

export const competencyDecoder: Decoder<Competency> = succeed({})
  .assign('id', field('id', number))
  .assign('name', field('name', alreadyTranslatedText));

export const competencyResourceDecoder: Decoder<CompetencyResource> =
  resourceDecoder(competencyDecoder);

const baseExperienceDecoder: Decoder<ExperienceBase> = succeed({})
  .assign('id', field('id', number))
  .assign('experienceId', field('experience_id', string))
  .assign('offeringType', field('offering_type', offeringTypeDecoder))
  .assign('primaryColor', field('primary_color', string))
  .assign('secondaryColor', field('secondary_color', string))
  .assign('title', field('title', alreadyTranslatedText))
  .assign('description', field('description', alreadyTranslatedText))
  .assign('availabilities', field('availabilities', availabilityResourcesDecoder))
  .assign('facultyCount', field('faculty_count', explicitMaybe(number)))
  .assign('schoolPartner', field('school_partner', schoolPartnerResourceDecoder))
  .assign('primaryCompetencies', field('primary_competencies', array(competencyResourceDecoder)))
  .assign(
    'secondaryCompetencies',
    field('secondary_competencies', array(competencyResourceDecoder)),
  )
  .assign('duration', field('duration', explicitMaybe(number)))
  .assign('totalHours', field('total_hours', explicitMaybe(number)))
  .assign('hoursPerWeek', field('hours_per_week', explicitMaybe(number)))
  .assign(
    'publicDetailedDescriptionHtml',
    field('public_detailed_description_html', explicitMaybe(alreadyTranslatedText)),
  )
  .assign(
    'whoShouldAttendHtml',
    field('who_should_attend_html', explicitMaybe(alreadyTranslatedText)),
  )
  .assign('keyTakeawaysHtml', field('key_takeaways_html', explicitMaybe(alreadyTranslatedText)))
  .assign('howItWorksHtml', field('how_it_works_html', explicitMaybe(alreadyTranslatedText)))
  .assign(
    'programStructureAndFeaturesHtml',
    field('program_structure_and_features_html', explicitMaybe(alreadyTranslatedText)),
  )
  .assign('deliveryChannel', field('delivery_channel', explicitMaybe(deliveryChannelDecoder)))
  .assign(
    'registrationInvitationKind',
    field('registration_invitation_kind', registrationInvitationKindDecoder),
  )
  .assign('availableLanguages', field('available_languages', languagesResourceDecoder))
  .assign('price', field('price', explicitMaybe(number)))
  .assign('productDetails', field('product_details', explicitMaybe(productDetailsDecoder)));

const experienceDecoder: Decoder<Experience> = mergeObjectDecoders(
  baseExperienceDecoder,
  partsDecoder,
);

export const experienceResourceDecoder: Decoder<ExperienceResource> =
  resourceDecoder(experienceDecoder);

export const experienceResourceFilterDecoder: Decoder<ExperienceResourceFilter> = resourceDecoder(
  array(experienceResourceDecoder),
);

export const durationDecoder: Decoder<Duration> = succeed({})
  .assign('value', field('value', number))
  .assign('label', field('label', string));

export const durationResourceDecoder: Decoder<DurationResource> = resourceDecoder(durationDecoder);

export const durationsDecoder: Decoder<Durations> =
  array(durationResourceDecoder).map<Durations>(identity);

export const experienceOptionDecoder: Decoder<ExperienceFilters> = succeed({})
  .assign('availableLanguages', field('available_languages', array(languageResourceDecoder)))
  .assign('durations', field('durations', array(durationResourceDecoder)))
  .assign('schoolPartners', field('school_partners', array(schoolPartnerResourceDecoder)))
  .assign('competencies', field('competencies', array(competencyResourceDecoder)));

export const experienceOptionsResourceDecoder: Decoder<ExperienceFiltersResource> =
  resourceDecoder(experienceOptionDecoder);

const notEnrollableAlertDecoder: Decoder<NotEnrollableAlert> = succeed({})
  .assign('kind', field('kind', string))
  .assign('programId', field('program_id', number))
  .assign('startOn', field('start_on', explicitMaybe(dateISO)))
  .assign('endOn', field('end_on', explicitMaybe(date)))
  .assign('title', field('title', explicitMaybe(string)))
  .assign('description', field('description', explicitMaybe(string)))
  .assign('experience', field('experience', explicitMaybe(experienceResourceDecoder)));

export const notEnrollableAlertResourceDecoder: Decoder<NotEnrollableAlertResource> =
  resourceDecoder(notEnrollableAlertDecoder);

const recommendationPerPageCountDecoder: Decoder<PerPageCount> = number.andThen((n) =>
  n === 2 || n === 3 || n === 4
    ? succeed(n)
    : fail('Recommendation per page counts can only be 2, 3 or 4'),
);

const productCollectionKindDecoder: Decoder<ProductCollectionKind> = oneOf([
  stringLiteral<ProductCollectionKind>('recommendation-engine'),
  stringLiteral<ProductCollectionKind>('learning-design'),
  stringLiteral<ProductCollectionKind>('dynamic-recommendation'),
  stringLiteral<ProductCollectionKind>('group-coaching-groups-recommendation'),
]);

const recommendationDisplayTypeDecoder: Decoder<ProductCollectionDisplayType> = oneOf([
  stringLiteral<ProductCollectionDisplayType>('carousel'),
  stringLiteral<ProductCollectionDisplayType>('list'),
  stringLiteral<ProductCollectionDisplayType>('grid'),
]);

export const recommendationDecoder: Decoder<ProductCollection> = succeed({})
  .assign('id', field('id', number))
  .assign('kind', field('kind', productCollectionKindDecoder))
  .assign('name', field('name', alreadyTranslatedText))
  .assign('shortName', field('short_name', string))
  .assign('description', field('description', explicitMaybe(alreadyTranslatedText)))
  .assign('displayPerPageCount', field('display_per_page_count', recommendationPerPageCountDecoder))
  .assign('experiences', field('experiences', array(experienceResourceDecoder)))
  .assign('displayType', field('display_type', recommendationDisplayTypeDecoder))
  .assign('displayButtonLabel', field('display_button_label', explicitMaybe(string)))
  .map<ProductCollection>(identity);

const useCaseDisplayTypeDecoder: Decoder<UseCaseDisplayType> = oneOf([
  stringLiteral<UseCaseDisplayType>('list'),
  stringLiteral<UseCaseDisplayType>('grid'),
]);

export const useCaseDecoder: Decoder<UseCase> = succeed({})
  .assign('id', field('id', number))
  .assign('name', field('name', alreadyTranslatedText))
  .assign('description', field('description', alreadyTranslatedText))
  .assign('commerceType', field('commerce_type', commerceTypeDecoder))
  .assign('displayType', field('display_type', useCaseDisplayTypeDecoder))
  .assign('productLicenseId', field('product_license_id', number))
  .assign('productLicenseType', field('product_license_type', string));

export const experiencesPayloadDecoder: Decoder<DiscoveryPortalPayload> = succeed({})
  .assign('experiences', field('experiences', array(experienceResourceDecoder)))
  .assign(
    'notEnrollableAlert',
    field('not_enrollable_alert', explicitMaybe(notEnrollableAlertResourceDecoder)),
  )
  .assign('useCase', field('use_case', useCaseDecoder))
  .assign('productCollections', field('product_collections', array(recommendationDecoder)))
  .assign('experienceFilters', field('experience_filters', experienceOptionsResourceDecoder))
  .map<DiscoveryPortalPayload>(identity);

export const discoveryPortalResourceDecoder: Decoder<DiscoveryPortalResource> =
  resourceDecoder(experiencesPayloadDecoder);
