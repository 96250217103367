import React, { useRef } from 'react';
import { DropdownMenuListProps } from './Types';
import { Maybe } from 'maybeasy';
import { fromNullable } from 'maybeasy';
import { when } from '@execonline-inc/maybe-adapter';
import { noop } from '@kofno/piper';

const focusElement = (focusedElement: HTMLElement): void => {
  when(focusedElement.hasAttribute('tabindex'), focusedElement).do((e) => e.focus());
};

function moveToItem(focusedElement: Element | null): void {
  fromNullable(focusedElement).map((element) => {
    element instanceof HTMLElement ? focusElement(element) : noop;
  });
}

const nextItem = (menuListElement: HTMLUListElement, currentElement: HTMLElement): Maybe<Element> =>
  fromNullable(currentElement.nextElementSibling).orElse(() =>
    fromNullable(menuListElement.firstElementChild),
  );

const previousItem = (
  menuListElement: HTMLUListElement,
  currentElement: HTMLElement,
): Maybe<Element> =>
  fromNullable(currentElement.previousElementSibling).orElse(() =>
    fromNullable(menuListElement.lastElementChild),
  );

const DropdownMenuList: React.FC<DropdownMenuListProps> = ({
  autoFocus = false,
  children,
  onKeyDown,
}) => {
  const rootElementRef = useRef<HTMLUListElement>();
  const handleKeyDown = (e: React.KeyboardEvent<HTMLUListElement>) => {
    e.preventDefault();
    const key = e.key;
    const menuListElement = rootElementRef.current;
    const currentElement = document.activeElement;

    fromNullable(menuListElement).do((list) => {
      if (currentElement instanceof HTMLElement) {
        switch (key) {
          case 'ArrowDown':
            nextItem(list, currentElement).do(moveToItem);
            break;
          case 'ArrowUp':
            previousItem(list, currentElement).do(moveToItem);
            break;
          case 'Home':
            fromNullable(list.lastElementChild).do(moveToItem);
            break;
          case 'End':
            fromNullable(list.firstElementChild).do(moveToItem);
            break;
        }
      }
    });
    fromNullable(onKeyDown).do((onKeyDown) => onKeyDown(e));
  };

  const handleElementRef = (element: HTMLUListElement) => {
    rootElementRef.current = element;
  };

  return (
    <ul
      ref={handleElementRef}
      className="p-1"
      tabIndex={autoFocus ? 0 : -1}
      onKeyDown={handleKeyDown}
    >
      {children}
    </ul>
  );
};

export default DropdownMenuList;
