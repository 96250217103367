import { observer } from 'mobx-react';
import * as React from 'react';
import { equals } from '@execonline-inc/collections';
import { T } from '../../../../../../Translations';

interface Props {
  selectedOptions: string[];
  allOptions: string[];
}

const ClassicFilterPlaceholder: React.FC<Props> = ({ selectedOptions, allOptions }) => {
  return equals(selectedOptions.length, allOptions.length) ? (
    <T kind="All" />
  ) : (
    <T kind="Selected: ({{count}})" count={selectedOptions.length} />
  );
};

export default observer(ClassicFilterPlaceholder);
