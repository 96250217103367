import { observer } from 'mobx-react';
import { just } from 'maybeasy';
import { equals } from '@execonline-inc/collections';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { findLink } from '../../../../../../LinkyLinky';
import { Link } from '../../../../../../Resource/Types';
import { ExperienceFiltersResource } from '../../../../Common/Experience/Types';
import ExperiencesStore from '../../Experiences/ExperiencesStore';
import { DropdownFilter } from '../CommonFilter';
import { allCompetencyOptions, CompetencyFilterStore } from '../CompetencyFilter';
import { allDurationOptions, DropdownDurationFilter, DurationFilterStore } from '../DurationFilter';
import { allLanguageOptions, LanguageFilterStore } from '../LanguageFilter';
import { allSchoolPartnerOptions, SchoolPartnerFilterStore } from '../SchoolPartnerFilter';
import SearchBox from '../SearchBox';
import { useMobileFilterSwitcher } from '../hooks';

export interface Props {
  experiencesStore: ExperiencesStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  options: ExperienceFiltersResource;
}

function InlineFilters({
  experiencesStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
  options,
}: Props) {
  const mode = useMobileFilterSwitcher();

  const handleSubmit = (link: Link) => (value: string) => {
    experiencesStore.searching(just(value), link);
  };

  return (
    <div className="flex w-full flex-col gap-2 lg:flex-row">
      {findLink('search-discovery-portal', experiencesStore.registerResource.links)
        .map((link) => <SearchBox state={experiencesStore.state} onSubmit={handleSubmit(link)} />)
        .getOrElse(emptyFragment)}

      {when(equals(mode, 'desktop'), true)
        .map(() => (
          <div className="flex basis-3/5 gap-2">
            <DropdownFilter
              label="Competency"
              store={competencyFilterStore}
              filterOptions={allCompetencyOptions(options)}
            />
            <DropdownDurationFilter
              store={durationFilterStore}
              filterOptions={allDurationOptions(options)}
            />
            <DropdownFilter
              label="Partner"
              store={schoolPartnerFilterStore}
              filterOptions={allSchoolPartnerOptions(options)}
            />
            <DropdownFilter
              label="Language"
              store={languageFilterStore}
              filterOptions={allLanguageOptions(options)}
            />
          </div>
        ))
        .getOrElse(emptyFragment)}
    </div>
  );
}

export default observer(InlineFilters);
