import * as React from 'react';
import { T } from '../../../Translations';
import Image from '../../Image';
import { translation, TranslationsContext } from '../../../Translations';
import * as style from '../style.module.css';

const SelectTime: React.FC = () => {
  return (
    <>
      <button
        className={style.button}
        id="freebusy-test-pickatime-button"
        type="button"
        data-test-button={true}
      >
        <T kind="Select a time" />
      </button>
      <div className={style.image}>
        <TranslationsContext.Consumer>
          {(ts) => (
            <>
              <Image
                src="https://media.execonline.com/resources/test-page-pickatime.png"
                alt={translation('Pick-a-Time grid')(ts)}
              />{' '}
            </>
          )}
        </TranslationsContext.Consumer>
      </div>
      <p>
        <T
          kind="If you experience any errors during these tests ..."
          emailAddress={
            <a className={style.mailToLink} href="mailto:support@execonline.com">
              support@execonline.com
            </a>
          }
        />
      </p>
    </>
  );
};

export default SelectTime;
