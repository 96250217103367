import { warn } from '@execonline-inc/logging';
import { observer } from 'mobx-react';
import React from 'react';

interface Props {
  warn?: string;
}

@observer
class EmptyFragment extends React.Component<Props> {
  componentDidMount(): void {
    if (this.props.warn) {
      warn(`An empty fragment was rendered: ${this.props.warn}`);
    }
  }

  render() {
    return <></>;
  }
}

export default EmptyFragment;
