import { observer } from 'mobx-react';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';
import { Typography } from '@execonline-inc/execonline-ui';
import { getDescriptionVariant } from './Types';
import { useMediaQuery } from '../../../../../../components/UI';

interface Props {
  description: AlreadyTranslatedText;
  className?: string;
}

function ExperienceDescription({ className, description }: Props) {
  const breakPoint = useMediaQuery();

  return (
    <Typography
      className={className}
      color="white"
      as="p"
      variant={getDescriptionVariant(breakPoint)}
    >
      <AlreadyTranslated content={description} />
    </Typography>
  );
}

export default observer(ExperienceDescription);
