import { observer } from 'mobx-react';
import * as React from 'react';
import ChannelAvailabilityStore from '../../../../../../../Socket/ChannelAvailabilityStore';
import PaymentConfirmationStore from '../../../Store';
import PresenceBinding from './PresenceBinding';

interface Props {
  channelAvailabilityStore: ChannelAvailabilityStore;
  store: PaymentConfirmationStore;
}

const PresenceHandler: React.FC<Props> = ({ channelAvailabilityStore, store }) => {
  switch (channelAvailabilityStore.channelAvailability.kind) {
    case 'available': {
      const channel = channelAvailabilityStore.channelAvailability.channel;
      return <PresenceBinding channel={channel} store={store} />;
    }
    case 'not-available': {
      return <></>;
    }
  }
};

export default observer(PresenceHandler);
