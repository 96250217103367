import { HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import SchoolPartnerLogo from '../SchoolPartnerLogo';
import { SchoolPartnerLogoProps } from '../Types';

interface Props extends HTMLAttributes<HTMLDivElement>, SchoolPartnerLogoProps {}

const SchoolPartnerLogoHeader = ({ className, height, ...others }: Props) => {
  return (
    <div className={className} style={{ height }}>
      <SchoolPartnerLogo height={height} {...others} />
    </div>
  );
};

export default observer(SchoolPartnerLogoHeader);
