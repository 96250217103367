import { observer } from 'mobx-react';
import * as React from 'react';
import EmptyFragment from '../../../../EmptyFragment/EmptyFragment';
import ProgramStore from '../../../../ProgramStore';
import { ActiveProgram, CoachingProductDetails } from '../../../../ProgramStore/Types';
import ModalStore from '../../../Modal/Store';
import CompletedOrientation from './CompletedOrientation';
import StartedOrientation from './StartedOrientation';
import UnstartedOrientation from './UnstartedOrientation';

interface Props {
  activeProgram: ActiveProgram;
  programStore: ProgramStore;
  modalStore: ModalStore;
  coachingProductDetails: CoachingProductDetails;
}

const Orientation: React.FC<Props> = ({
  activeProgram,
  programStore,
  modalStore,
  coachingProductDetails,
}) => {
  const { orientationStatus } = coachingProductDetails;
  switch (orientationStatus) {
    case 'Unstarted':
      return (
        <UnstartedOrientation
          programStore={programStore}
          modalStore={modalStore}
          activeProgram={activeProgram}
        />
      );
    case 'Started':
      return <StartedOrientation activeProgram={activeProgram} modalStore={modalStore} />;
    case 'Completed':
      return (
        <CompletedOrientation
          programStore={programStore}
          coachingProductDetails={coachingProductDetails}
          modalStore={modalStore}
        />
      );
    case 'NotApplicable':
      return <EmptyFragment warn="Orientation status is not applicable" />;
  }
};

export default observer(Orientation);
