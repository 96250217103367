import { pipe } from '@kofno/piper';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ContextType } from '..';
import { emptyFragment } from '../../../EmptyFragment';
import { throwMisuseError } from './Functions';
import { Props as WithCurrentUserProps } from './Types';

interface Props extends WithCurrentUserProps {
  ctx: ContextType;
}

/**
 * This component is needed becase `WithCurrentUser` uses a callback to render children.
 * Unless this children render function is executed *during a component render call*, Mobx
 * will not re-render based on references to observables that occur in the function body.
 *
 * This is tricky because if the execution of the function is further embedded in any other
 * component, then it becomes a child of that component and won't necessarily be executed
 * during the render of the component where it syntactially appears. Making all components
 * Mobx observers makes this problem less likely to manifest, but using third-party components
 * (such as a React Context consumer) may result in the observed value never being tracked by
 * Mobx.
 *
 * See https://iiunknown.gitbooks.io/mobxdocen/content/best/react.html#mobx-only-tracks-data-accessed-for-observer-components-if-they-are-directly-accessed-by-render
 */
const Children: React.FC<Props> = ({ ctx, children }) =>
  fromNullable(ctx)
    .map(pipe(children, inFragment))
    .elseDo(throwMisuseError)
    .getOrElse(emptyFragment);

export default observer(Children);

const inFragment = (content: React.ReactNode): React.ReactElement => <>{content}</>;
