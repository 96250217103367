import { Maybe } from 'maybeasy';
import { Error } from '../../../ErrorHandling';
import { Resource } from '../../../Resource/Types';
import { TPlainTextKey } from '../../../Translations';

export interface TermsAndConditions {}

export type TermsAndConditionsResource = Resource<TermsAndConditions>;

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  termsResource: TermsAndConditionsResource;
  termsAccepted: boolean;
  message: Maybe<TPlainTextKey>;
}

interface Updating {
  kind: 'updating';
  termsResource: TermsAndConditionsResource;
}

interface Updated {
  kind: 'updated';
}

export type State = Loading | Error | Ready | Waiting | Updating | Updated;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (
  termsResource: TermsAndConditionsResource,
  message: Maybe<TPlainTextKey>
): Ready => ({
  kind: 'ready',
  termsResource,
  message,
  termsAccepted: false,
});

export const updating = (termsResource: TermsAndConditionsResource): Updating => ({
  kind: 'updating',
  termsResource,
});

export const updated = (): Updated => ({ kind: 'updated' });
