import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../../SegmentStore';
import { AnyAdvancer } from '../../../../SegmentStore/Types';
import { TPlainTextKey } from '../../../../Translations';
import ReadyButton from '../AdvanceButton/ReadyButton';
import TransitionButton from '../AdvanceButton/TransitionButton';

interface Props {
  store: SegmentStore;
  text?: TPlainTextKey;
}

const TokenAdvanceButton: React.FC<Props> = ({ store, text = 'Next Segment' }) => {
  const advancer: AnyAdvancer = store.tokenSegmentAdvancer;
  switch (store.state.kind) {
    case 'ready':
    case 'processing-request':
    case 'auto-saving-form-fields':
    case 'schedule-session':
      return (
        <ReadyButton
          text={text}
          advancer={advancer}
          confirmationModal={store.confirmationModal}
          store={store}
        />
      );
    case 'advancing':
    case 'advancing-to':
    case 'completing':
    case 'completing-and-advancing':
    case 'reporting-results':
    case 'submitting-and-advancing':
      return <TransitionButton text={text} advancer={advancer} />;
    case 'loaded':
    case 'loading':
    case 'error':
    case 'waiting':
      return <div />;
  }
};

export default observer(TokenAdvanceButton);
