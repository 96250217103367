import { observer } from 'mobx-react';
import * as React from 'react';
import { AnyAdvancer } from '../../../../../SegmentStore/Types';
import { TPlainTextKey } from '../../../../../Translations';
import DisabledAdvanceButton from '../DisabledAdvanceButton';

interface Props {
  advancer: AnyAdvancer;
  text: TPlainTextKey;
}

const TransitionButton: React.FC<Props> = ({ advancer, text = 'Next Segment' }) => {
  switch (advancer.kind) {
    case 'advancer':
    case 'complete-and-advancer':
    case 'disabled-advancer':
    case 'submit-and-advancer':
      return <DisabledAdvanceButton text={text} />;
    case 'no-advancer':
      return <div />;
  }
};

export default observer(TransitionButton);
