import { findPayload } from '@execonline-inc/collections';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import { emptyFragment } from '../../../EmptyFragment';
import { ProgramResource } from '../../../ProgramChatStore/Types';
import ProgramStore from '../../../ProgramStore';
import { programsStore } from '../../../ProgramsStore';
import { T, TPlainTextKey } from '../../../Translations';
import BeginOrResumeButton from '../../BeginOrResumeButton';
import ProgramReactions from '../../ProgramReactions';
import * as style from './style.module.css';

interface Props {
  programResource: ProgramResource;
}

class ResumeProgram extends React.Component<Props> {
  programStore: ProgramStore = new ProgramStore();

  componentDidMount() {
    programsStore.resource
      .map(({ payload }) => payload.programs)
      .map((programs) => {
        findPayload(this.props.programResource.payload.id, programs).map(this.programStore.loading);
      });
  }

  beginOrResumeText = (percentComplete: number): TPlainTextKey =>
    percentComplete === 0
      ? 'Get started on your program'
      : 'Continue your program where you left off';

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <div className={style.resumeProgram}>
            {just({})
              .assign('currentSegment', this.programStore.currentSegment)
              .assign('percentComplete', this.programStore.percentComplete)
              .map(({ percentComplete }) => (
                <>
                  <p className={style.resumeText}>
                    <T kind={this.beginOrResumeText(percentComplete)} />
                  </p>
                  <BeginOrResumeButton programStore={this.programStore} />
                </>
              ))
              .getOrElse(emptyFragment)}

            <ProgramReactions store={this.programStore} currentUserResource={currentUserResource} />
          </div>
        )}
      />
    );
  }
}

export default observer(ResumeProgram);
