import { observer } from 'mobx-react';
import * as React from 'react';
import { emptyFragment } from '../../../EmptyFragment';
import Title from '../../../Title';
import Footer from '../../../components/Footer';
import DiscoveryContext, { FromState, learnMore } from '../DiscoveryContext';
import EnrollmentStore from '../DiscoveryPortal/Enrollment/Store';
import ExperiencesStore from '../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { PreviewModeContext } from '../DiscoveryPortal/PreviewMode/PreviewModeContext';
import { RegisterResource } from '../DiscoveryPortal/RegisterResourceStore/Types';
import DeliveryChannelSection from './DeliveryChannelSection';
import ExperienceDetailHeader from './ExperienceDetailHeader';
import ExperienceDetailViewStore from './ExperienceDetailViewStore';
import ExperienceDetailViewReactions from './ExperienceDetailViewStore/ExperienceDetailViewReactions';
import ExperienceDetailsBody from './ExperienceDetailsBody';
import HeroSection from './HeroSection';

interface Props {
  experienceId: string;
  invitationUuid: string;
  registerResource: RegisterResource;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
  discoveryReferenceState: FromState;
}

class ExperienceDetailView extends React.Component<Props> {
  experienceDetailViewStore = new ExperienceDetailViewStore();

  componentDidMount() {
    this.experienceDetailViewStore.loading(this.props.experienceId, this.props.registerResource);
  }

  render() {
    const {
      enrollmentStore,
      registerResource,
      experiencesStore,
      invitationUuid,
      discoveryReferenceState,
    } = this.props;
    return (
      <DiscoveryContext.Provider value={learnMore(discoveryReferenceState)}>
        <div className="flex flex-grow flex-col">
          {this.experienceDetailViewStore.experienceResource
            .map((experienceResource) => (
              <>
                <Title title={experienceResource.payload.title.text} />
                <ExperienceDetailHeader />
                <div className="flex-grow">
                  <DeliveryChannelSection experienceResource={experienceResource} />
                  <HeroSection
                    invitationUuid={invitationUuid}
                    experienceDetailViewStore={this.experienceDetailViewStore}
                    experienceResource={experienceResource}
                    experiencesStore={experiencesStore}
                    enrollmentStore={enrollmentStore}
                    registerResource={registerResource}
                  />
                  <ExperienceDetailsBody
                    experienceResource={experienceResource}
                    experienceDetailViewStore={this.experienceDetailViewStore}
                    enrollmentStore={enrollmentStore}
                    invitationUuid={invitationUuid}
                  />
                </div>
                <PreviewModeContext.Consumer>
                  {(previewMode) => {
                    switch (previewMode) {
                      case 'default':
                        return <Footer />;
                      case 'preview':
                        return <></>;
                    }
                  }}
                </PreviewModeContext.Consumer>
              </>
            ))
            .getOrElse(emptyFragment)}
          <ExperienceDetailViewReactions
            store={this.experienceDetailViewStore}
            discoveryPortal={this.props.experiencesStore.discoveryPortal}
          />
        </div>
      </DiscoveryContext.Provider>
    );
  }
}

export default observer(ExperienceDetailView);
