import React, { useContext, useLayoutEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { equals, find } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';
import { DropdownMenuItemProps } from './Types';
import { fromNullable } from 'maybeasy';
import DropdownContext from '../DropdownContext';

const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({ children, tabIndex }) => {
  const rootElementRef = useRef<HTMLLIElement>();
  const { autoFocus, onClick, ...contextValue } = useContext(DropdownContext);
  useLayoutEffect(() => {
    when(autoFocus, true)
      .andThen(() => fromNullable(rootElementRef.current))
      .do((element) => element.focus());
  }, [autoFocus]);

  const handleElementRef = (element: HTMLLIElement) => {
    rootElementRef.current = element;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
    const validKeys = [' ', 'Enter'];

    find(equals(e.key))(validKeys).do(() => {
      e.preventDefault();
      fromNullable(onClick).do((onClick) => onClick(e));
    });
  };

  return (
    <li
      ref={handleElementRef}
      className={clsx('flex items-center p-2 cursor-pointer select-none')}
      tabIndex={fromNullable(tabIndex).getOrElseValue(-1)}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      {...contextValue}
    >
      {children}
    </li>
  );
};

export default observer(DropdownMenuItem);
