import { Error } from '../../ErrorHandling';
import { Resource } from '../../Resource/Types';
import { TPlainTextKey } from '../../Translations';

interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  resource: CourseModuleProgressResource;
}

interface ProgressHidden {
  kind: 'ready-progress-hidden';
}

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (resource: CourseModuleProgressResource): Ready => ({
  kind: 'ready',
  resource,
});

export const readyProgressHidden = (): ProgressHidden => ({
  kind: 'ready-progress-hidden',
});

export const error = (message: TPlainTextKey): Error => ({
  kind: 'error',
  message,
});

export type State = Loading | Ready | Error | ProgressHidden;

export type CourseModuleProgress = {
  id: number;
  totalCount: number;
  completedCount: number;
};
export type CourseModuleProgressResource = Resource<CourseModuleProgress>;
