import { equals } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';
import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ProgramSegment } from '../../../ProgramStore/Types';
import SegmentStore from '../../../SegmentStore';
import { SegmentResource } from '../../../SegmentStore/Types';
import ContentStates from '../../ActiveProgramOverview/ActiveCoachingProductOverview/Orientation/OrientationModal/ModalSegment/ContentStates';
import * as style from '../../ActiveProgramOverview/ActiveCoachingProductOverview/Orientation/OrientationModal/style.module.css';
import { AutoLaunchableStore } from '../../AutoLaunchable/AutoLaunchableStore';
import SubmitAndAdvanceButton from '../../Segment/SegmentNavigation/AdvanceButton/SubmitAndAdvanceButton';
import * as btnStyle from '../../Segment/SegmentNavigation/AdvanceButton/SubmitAndAdvanceButton/style.module.css';
import SegmentReactions from '../../SegmentReactions';

interface Props {
  currentSegment: ProgramSegment;
  autoLaunchableStore: AutoLaunchableStore;
}

class ModalSegment extends React.Component<Props, {}> {
  segmentStore = new SegmentStore('Streamlined');

  render() {
    const { currentSegment, autoLaunchableStore } = this.props;
    return (
      <>
        <ContentStates
          longTitle={currentSegment.longTitle}
          store={this.segmentStore}
          autoLaunchableStore={just(autoLaunchableStore)}
        />
        <div className={style.submitButtonContainer}>
          <SubmitAndAdvanceButton
            segmentStore={this.segmentStore}
            btnStyle={btnStyle.submitButton}
            text="Next"
          >
            {{ btnIcon: nothing() }}
          </SubmitAndAdvanceButton>
        </div>
        <SegmentReactions
          params={{
            programId: String(currentSegment.programId),
            moduleId: String(currentSegment.moduleId),
            segmentId: String(currentSegment.id),
          }}
          store={this.segmentStore}
          advanceHook={(nextSegment: Maybe<SegmentResource>) => {
            nextSegment
              .andThen((s) => when(equals('Streamlined'), s.payload.presentationStyle))
              .elseDo(autoLaunchableStore.load);
          }}
          fireImmediately={true}
        />
      </>
    );
  }
}

export default observer(ModalSegment);
