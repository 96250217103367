import clsx from 'clsx';
import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Button from '../../components/Form/Button';
import InputWithLabel from '../../components/Form/InputWithLabel';
import { LoginStore } from '../Store';
import LoginFormWrapper from './LoginFormWrapper';
import * as style from './style.module.css';

interface Props {
  store: LoginStore;
}

const EmailForm: React.FC<Props> = ({ store }) => {
  return (
    <LoginFormWrapper store={store}>
      <form name="email_login_form">
        <div className={style.field}>
          <InputWithLabel
            labelClassName={clsx(style.labelDark, 'text-neutral-900')}
            labelText="Email or Username"
            autoFocus={true}
            type="email"
            onChange={setUsername(store)}
            hint="email@domain.com; username@id"
            name="email"
            id="login_email"
            className={clsx(style.input, 'w-full text-neutral-900')}
            autoComplete="username"
            disabled={store.formShouldBeDisabled}
          />
        </div>
        <div className={clsx(style.actions, 'mb-5')}>
          <Button
            type="submit"
            value="Next"
            onClick={submit(store)}
            className={clsx(
              style.button,
              'mx-0 my-3 bg-green-primary text-white hover:bg-[#187723]',
            )}
            disabled={store.formShouldBeDisabled}
          />
        </div>
      </form>
    </LoginFormWrapper>
  );
};

export default observer(EmailForm);

const setUsername =
  (store: LoginStore) =>
  (event: React.FormEvent<HTMLInputElement>): void => {
    store.awaitingUsername({ username: just(event.currentTarget.value), error: nothing() });
  };

const submit =
  (store: LoginStore) =>
  (event: React.FormEvent<HTMLInputElement>): void => {
    event.preventDefault();
    store.identifyingAssertionBackend();
  };
