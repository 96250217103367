import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import { Chip, emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { ExperienceResource } from '../../../Common/Experience/Types';
import { ExperienceDetailsSection, ExperienceDetailsSectionHeader } from '../ExperienceDetails';

interface Props {
  experienceResource: ExperienceResource;
}

function CompetenciesYouWillGain({ experienceResource }: Props) {
  return fromEmpty(experienceResource.payload.primaryCompetencies)
    .map((competencies) => (
      <ExperienceDetailsSection>
        <ExperienceDetailsSectionHeader title="Competencies you will gain" />
        <div className="flex flex-wrap gap-2 lg:gap-4">
          {competencies.map((competency) => (
            <Chip
              key={competency.payload.id}
              className="px-3 py-1.5"
              size="sm"
              variant="outlined"
              radius="md"
              color="gray"
            >
              <AlreadyTranslated content={competency.payload.name} />
            </Chip>
          ))}
        </div>
      </ExperienceDetailsSection>
    ))
    .getOrElse(emptyFragment);
}

export default observer(CompetenciesYouWillGain);
