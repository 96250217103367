import { useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  Popover,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
  Typography,
} from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { flip } from '@floating-ui/react';
import { useExperienceContext } from '../Experience/ExperienceContext';
import ExperienceAvailableLanguages from './ExperienceAvailableLanguages';
import ExperienceCompetencies from './ExperienceCompetencies';
import ExperienceTimeCommitment from './ExperienceTimeCommitment';
import ExperienceUpcomingDates from './ExperienceUpcomingDates';
import ExperienceActions from './ExperienceActions';
import ExperienceAlert from './ExperienceAlert';
import ExperienceDescription from './ExperienceDescription';
import ExperienceActionHint from './ExperienceActionHint';
import { ExperiencePopoverContext } from './ExperiencePopoverContext';
import { calculatePlacementOffset, PopoverProgramExperienceProps } from './Types';
import { useProgramPopoverTrigger } from './hooks';

function ProgramExperiencePopover({
  experiencesStore,
  placement = 'right',
  children,
  onClose,
}: PopoverProgramExperienceProps) {
  const { invitationUuid, experienceResource } = useExperienceContext();
  const trigger = useProgramPopoverTrigger(experienceResource);
  const middleware = useMemo(() => [calculatePlacementOffset(placement), flip()], [placement]);

  return (
    <Popover
      className="max-w-[20.625rem] text-black"
      trigger={trigger}
      delay={{ open: 1000, close: 0 }}
      returnFocus={false}
      placement={placement}
      renderTrigger={children}
      middleware={middleware}
    >
      <ExperiencePopoverContext.Provider
        value={{
          invitationUuid,
          experienceResource,
          experiencesStore,
          onClose,
        }}
      >
        <PopoverHeader className="flex flex-col gap-y-3">
          <ExperienceAlert />
          <Typography
            className="text-gray-800"
            variant="subtitleMedium"
            as="h5"
            fontWeight="medium"
          >
            <AlreadyTranslated content={experienceResource.payload.title} />
          </Typography>
        </PopoverHeader>
        <PopoverBody className="max-h-[420px] gap-y-4">
          <ExperienceTimeCommitment />
          <ExperienceAvailableLanguages />
          <ExperienceDescription />
          <ExperienceCompetencies />
          <ExperienceUpcomingDates />
        </PopoverBody>
        <PopoverFooter className="gap-y-2 empty:hidden">
          <ExperienceActions buttonProps={{ tabIndex: -1 }} />
          <ExperienceActionHint />
        </PopoverFooter>
      </ExperiencePopoverContext.Provider>
    </Popover>
  );
}

export default observer(ProgramExperiencePopover);
