import { warn } from '@execonline-inc/logging';
import { observer } from 'mobx-react';
import { useFetch, useFindLinkByR } from '../../Fetch';
import { Link } from '../../Resource/Types';
import Loader from '../Loader';
import { progressDecoder } from './Decoders';
import ProgressBar from './ProgressBar';

interface Props {
  links: ReadonlyArray<Link>;
}

function Progress({ links }: Props) {
  const progressLink = useFindLinkByR({ rel: 'progress', method: 'get' }, links);
  const state = useFetch(progressLink, progressDecoder);

  switch (state.kind) {
    case 'loading':
      return <Loader type="balls" />;
    case 'errored':
      warn(state.error);
      return <Loader type="balls" />;
    case 'ready':
      return (
        <ProgressBar
          total={state.data.payload.totalCount}
          currentStepIndex={state.data.payload.completedCount}
        />
      );
  }
}

export default observer(Progress);
