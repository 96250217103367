import { observer } from 'mobx-react';
import * as React from 'react';
import SectionContent from './SectionContent';
import { ExternalProgramResource } from '../../../../../SegmentStore/Types';
import { emptyFragment } from '../../../../../EmptyFragment';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const KeyTakeawaysSection: React.FC<Props> = ({ externalProgramResource }) => (
  <div className="flex">
    {externalProgramResource.payload.keyTakeawaysHtml
      .map((keyTakeawaysHtml) => (
        <div className="pr-16">
          <SectionContent description={keyTakeawaysHtml} header={'Key Takeaways'} />
        </div>
      ))
      .getOrElse(emptyFragment)}
    {externalProgramResource.payload.programStructureAndFeaturesHtml
      .map((programStructureAndFeaturesHtml) => (
        <div className="pr-16">
          <SectionContent
            description={programStructureAndFeaturesHtml}
            header={'Program Structure'}
          />
        </div>
      ))
      .getOrElse(emptyFragment)}
    {externalProgramResource.payload.howItWorksHtml
      .map((howItWorksHtml) => (
        <div className="pr-16">
          <SectionContent description={howItWorksHtml} header={'How It Works'} />
        </div>
      ))
      .getOrElse(emptyFragment)}
  </div>
);

export default observer(KeyTakeawaysSection);
