import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '../../../../../components/UI';
import { emptyFragment } from '../../../../../EmptyFragment';
import { T } from '../../../../../Translations';
import PaymentConfirmationStore from '../Store';
import PaymentPresence from './PaymentSuccess/PaymentPresence';
import PaymentProcesing from './PaymentProcesing';

interface Props {
  store: PaymentConfirmationStore;
}

const StillProcessing: React.FC<Props> = ({ store }) =>
  store.paymentIntentId
    .map((id) => (
      <>
        <PaymentPresence channelName={`presence-stripe-payment-intent-${id}`} store={store}>
          <div className="flex flex-col self-center content-center items-center h-full w-full">
            <PaymentProcesing />
            <Typography
              className="tracking-wide text-center leading-none mt-8 md:mt-10 xl:mt-12"
              variant="h5"
              component="p"
              fontWeight="medium"
            >
              <T kind="Processing Your Payment" />
            </Typography>
            <div className="mt-14">
              <Typography className="leading-tight text-center" variant="subtitle2" component="p">
                <T kind="Payment is taking a bit longer than usual. Please do not close this window." />
              </Typography>
            </div>
          </div>
        </PaymentPresence>
      </>
    ))
    .getOrElse(emptyFragment);

export default observer(StillProcessing);
