import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import CheckboxSelectFieldStore from '../Store';
import { SelectFieldValue, StringFormField } from '../../../../../Types';
import * as style from './../style.module.css';
import { find } from '@execonline-inc/collections';
import { inputValue, stringInputOptionLabel } from '../../Helpers';

interface Props {
  selectFieldStore: CheckboxSelectFieldStore;
  stringFormField: StringFormField;
}

function OptionWithStringInput({ selectFieldStore, stringFormField }: Props) {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    find(
      (v) => v.label === stringInputOptionLabel(stringFormField),
      selectFieldStore.fieldValue,
    ).do(() => setToggle(true));
  });

  const formatInputValue = (stringFieldValue: string): SelectFieldValue => ({
    label: stringInputOptionLabel(stringFormField),
    value: stringFieldValue,
  });

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      selectFieldStore.removeValue(formatInputValue(e.currentTarget.value));
    }
    setToggle(!toggle);
    selectFieldStore.autoSave();
  };

  return (
    <>
      <label key={stringFormField.name} className={style.checkboxLabel}>
        <input
          name={stringFormField.name}
          type="checkbox"
          data-test-checkbox={stringFormField.name}
          onChange={onValueChange}
          checked={toggle}
          className={style.checkboxField}
        />
        {stringInputOptionLabel(stringFormField)}
      </label>
      {toggle && (
        <textarea
          name={stringFormField.name}
          value={inputValue(stringFormField, selectFieldStore)}
          required={true}
          minLength={stringFormField.minlength.getOrElseValue(0)}
          maxLength={stringFormField.maxlength}
          onChange={(e) => selectFieldStore.addValue(formatInputValue(e.currentTarget.value))}
          onKeyUp={selectFieldStore.autoSave}
          className={style.textArea}
        />
      )}
    </>
  );
}

export default observer(OptionWithStringInput);
