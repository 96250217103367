import { readVarM } from '@execonline-inc/environment';
import { toUrl } from '@execonline-inc/url';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '../../../../components/UI';
import { emptyFragment } from '../../../../EmptyFragment';
import { resetPasswordRoute } from '../../../../ClientRoutes';
import { NotTranslated, T } from '../../../../Translations';
import ExternalLink from '../../ExternalLink';
import MailToLink from '../../MailToLink';

interface Props {}

const Notice: React.FC<Props> = () => (
  <div className="flex flex-col w-[600px] max-w-full gap-4 md:gap-6">
    <Typography>
      <T
        kind="If this email address is in our database you ..."
        emailAddress={(() => {
          const email = 'support@execonline.com';
          return (
            <MailToLink email={email}>
              <Typography className="underline" component="span">
                <NotTranslated text={email} />
              </Typography>
            </MailToLink>
          );
        })()}
      />
    </Typography>
    <div>
      {just({})
        .assign('base', readVarM('REACT_APP_BASE_NAME'))
        .assign('origin', readVarM('REACT_APP_API_ROOT'))
        .andThen(({ base, origin }) => toUrl(base + resetPasswordRoute, origin))
        .map((url) => (
          <ExternalLink href={url.href}>
            <Typography className="underline" variant="body2" component="span">
              <T kind="I forgot my password" />
            </Typography>
          </ExternalLink>
        ))
        .getOrElse(emptyFragment)}
    </div>
  </div>
);

export default observer(Notice);
