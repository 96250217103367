import { find } from '@execonline-inc/collections';
import { SelectFieldValue, StringFormField } from '../../../Types';
import { SelectFieldStore } from './Types';

export const stringInputOptionLabel = (stringFormField: StringFormField) =>
  stringFormField.label.getOrElseValue(stringFormField.name);

export const convertToSelectFieldValue = (label: string, value: string): SelectFieldValue => ({
  label,
  value,
});

export const inputValue = (stringFormField: StringFormField, selectFieldStore: SelectFieldStore) =>
  find((v) => v.label === stringInputOptionLabel(stringFormField), selectFieldStore.fieldValue)
    .map((v) => v.value)
    .getOrElseValue('');
