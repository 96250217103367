import { JsonValue } from '@execonline-inc/decoders';
import { Maybe, nothing } from 'maybeasy';
import AttachmentUploadStore from '../AttachmentUploadStore';
import { UploadedAttachmentResources } from '../AttachmentUploadStore/Types';
import { FormFieldAssetResource, FormFieldStore } from '../components/EmbeddedFormFieldAsset/Types';
import { ReqHlsVideoAssetResource } from '../components/ReqHlsVideo/Types';
import { VideoAssetResource } from '../components/Video/Types';
import { Error } from '../ErrorHandling';
import { LectureParts, LectureSegment } from '../LectureStore/Types';
import { PresentationParts, PresentationSegment } from '../PresentationStore/Types';
import { Link, Resource } from '../Resource/Types';
import { SurveyParts, SurveySegment } from '../SurveyStore/Types';
import { TeamDiscussionParts, TeamDiscussionSegment } from '../TeamDiscussionStore/Types';
import { Uploads } from '../Uploads';
import { SchoolPartner } from '../Native/AEP/Common/Experience/SchoolPartner';
import { CompetencyResource, OfferingType } from '../Native/AEP/Common/Experience/Types';
import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { NonEmptyList } from 'nonempty-list';
import { RegistrationStatus, RegistrationType } from '../ProgramsStore/Types';

export interface Waiting {
  kind: 'waiting';
}

export interface Loading {
  kind: 'loading';
  link: Link;
}

export interface Ready {
  kind: 'ready';
  segmentResource: SegmentResource;
}

export interface ReportingResults {
  kind: 'reporting-results';
  segmentResource: SegmentResource;
}

export interface Completing {
  kind: 'completing';
  segmentResource: SegmentResource;
}

export interface Advancing {
  kind: 'advancing';
  segmentResource: SegmentResource;
}

export interface Loaded {
  kind: 'loaded';
  segmentResource: SegmentResource;
}

export interface AdvancingTo {
  kind: 'advancing-to';
  link: Link;
  segmentResource: SegmentResource;
}

export interface CompletingAndAdvancing {
  kind: 'completing-and-advancing';
  segmentResource: SegmentResource;
}

export interface SubmittingAndAdvancing {
  kind: 'submitting-and-advancing';
  segmentResource: SegmentResource;
  submissionParams: SegmentSubmissionParams;
}

interface AutoSavingFormFields {
  kind: 'auto-saving-form-fields';
  segmentResource: SegmentResource;
}

interface ScheduleSession {
  kind: 'schedule-session';
  segmentResource: SegmentResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (link: Link): Loading => ({
  kind: 'loading',
  link,
});

export const ready = (segmentResource: SegmentResource): Ready => ({
  kind: 'ready',
  segmentResource,
});

export const reportingResults = (segmentResource: SegmentResource): ReportingResults => ({
  kind: 'reporting-results',
  segmentResource,
});

export const completing = (segmentResource: SegmentResource): Completing => ({
  kind: 'completing',
  segmentResource,
});

export const advancing = (segmentResource: SegmentResource): Advancing => ({
  kind: 'advancing',
  segmentResource,
});

export const loaded = (segmentResource: SegmentResource): Loaded => ({
  kind: 'loaded',
  segmentResource,
});

export const completingAndAdvancing = (
  segmentResource: SegmentResource,
): CompletingAndAdvancing => ({
  kind: 'completing-and-advancing',
  segmentResource,
});

export const submittingAndAdvancing = (
  segmentResource: SegmentResource,
  submissionParams: SegmentSubmissionParams,
): SubmittingAndAdvancing => ({
  kind: 'submitting-and-advancing',
  segmentResource,
  submissionParams,
});

export const advancingTo = (segmentResource: SegmentResource, link: Link): AdvancingTo => ({
  kind: 'advancing-to',
  link,
  segmentResource,
});

export const advancerForResource = (segmentResource: SegmentResource): Maybe<AnyAdvancer> => {
  switch (segmentResource.payload.type) {
    case 'overview':
      return nothing<SubmitAndAdvancer>();
    case 'lecture':
      return nothing<CompleteAndAdvancer>();
    case 'team-discussion':
    case 'external-program':
    case 'presentation':
    case 'survey':
    case 'assignment-due':
      return nothing<AnyAdvancer>();
  }
};

export const autoSavingFormFields = (segmentResource: SegmentResource): AutoSavingFormFields => ({
  kind: 'auto-saving-form-fields',
  segmentResource,
});

export type SegmentState =
  | Waiting
  | Loading
  | Error
  | Ready
  | Completing
  | Advancing
  | CompletingAndAdvancing
  | SubmittingAndAdvancing
  | ReportingResults
  | AdvancingTo
  | Loaded
  | AutoSavingFormFields
  | ScheduleSession
  | ProcessingRequest;

export interface Advancer {
  kind: 'advancer';
  advance: () => void;
}

export interface ProcessingRequest {
  kind: 'processing-request';
  segmentResource: SegmentResource;
}

export interface CompleteAndAdvancer {
  kind: 'complete-and-advancer';
  completeAndAdvance: () => void;
}

export interface SubmitAndAdvancer {
  kind: 'submit-and-advancer';
  submitAndAdvance: (submissionParams: SegmentSubmissionParams) => void;
}

export interface DisabledAdvancer {
  kind: 'disabled-advancer';
}

export interface NoAdvancer {
  kind: 'no-advancer';
}

export type AnyAdvancer =
  | SubmitAndAdvancer
  | CompleteAndAdvancer
  | Advancer
  | DisabledAdvancer
  | NoAdvancer;

export const disabledAdvancer = (): DisabledAdvancer => ({
  kind: 'disabled-advancer',
});

export const noAdvancer = (): NoAdvancer => ({
  kind: 'no-advancer',
});

export const processingRequest = (segmentResource: SegmentResource): ProcessingRequest => ({
  kind: 'processing-request',
  segmentResource,
});

export const advancer = (store: { advance: () => void }): Advancer => ({
  kind: 'advancer',
  advance: store.advance,
});

export const completeAndAdvancer = (store: {
  completeAndAdvance: () => void;
}): CompleteAndAdvancer => ({
  kind: 'complete-and-advancer',
  completeAndAdvance: store.completeAndAdvance,
});

export const submitAndAdvancer = (store: {
  submitAndAdvance: (submissionParams: SegmentSubmissionParams) => void;
}): SubmitAndAdvancer => ({
  kind: 'submit-and-advancer',
  submitAndAdvance: store.submitAndAdvance,
});

export const scheduleSession = (state: Ready): ScheduleSession => {
  const payload = {
    ...state.segmentResource.payload,
    sessionScheduled: true,
  };
  const segmentResource: SegmentResource = {
    ...state.segmentResource,
    payload,
  };
  return {
    kind: 'schedule-session',
    segmentResource,
  };
};

export interface AssignmentDueSubmissionParams {
  type: 'assignment-due';
  uploads: Uploads;
  attachmentUploadStore: AttachmentUploadStore;
}

export interface TeamDiscussionSubmissionParams {
  type: 'team-discussion';
  title: Maybe<string>;
  content: Maybe<string>;
  uploads: Maybe<Uploads>;
  attachmentUploadStore: AttachmentUploadStore;
}

export interface OverviewParams {
  type: 'overview';
  embeddedFormFieldAssetStores: FormFieldStore[];
  attachmentUploadStore: AttachmentUploadStore;
}

export type SegmentSubmissionParams =
  | AssignmentDueSubmissionParams
  | TeamDiscussionSubmissionParams
  | OverviewParams;

export interface ConfirmationModal {
  title: string;
  content: string;
  button: string;
}

export type ModulePresentationStyle = 'Full' | 'Streamlined';

//Any updates to SegmentBase should also be made to OverviewSegmentPreviewResource#payload
export interface SegmentBase {
  id: number;
  programId: number;
  moduleId: number;
  title: string;
  showCompletionCta: boolean;
  label: string;
  position: number;
  status: Maybe<string>;
  attachments: AttachmentResource[];
  confirmationModal: Maybe<ConfirmationModal>;
  presentationStyle: ModulePresentationStyle;
  studentId: number;
  registrationStatus: RegistrationStatus;
  registrationType: RegistrationType;
  learningCollectionId: Maybe<number>;
  learningCollectionName: Maybe<AlreadyTranslatedText>;
  productLicenseId: Maybe<number>;
  productLicenseType: Maybe<AlreadyTranslatedText>;
  programFamilyId: Maybe<number>;
  programFamilyName: Maybe<AlreadyTranslatedText>;
  schoolPartnerId: Maybe<number>;
  schoolPartnerName: Maybe<AlreadyTranslatedText>;
  enrollmentOpenDate: Maybe<Date>;
  duration: Maybe<number>;
  percentComplete: number;
  startsOn: Maybe<Date>;
  endsOn: Maybe<Date>;
  programTitle: string;
  programCreatedOn: Maybe<Date>;
}
//Any updates to SegmentBase should also be made to OverviewSegmentPreviewResource#payload

export type SegmentParts =
  | WebPageParts
  | SurveyParts
  | PresentationParts
  | LectureParts
  | TeamDiscussionParts
  | AssignmentDueParts
  | ExternalProgramSegmentParts;

export type Segment =
  | WebPageSegment
  | SurveySegment
  | PresentationSegment
  | LectureSegment
  | AssignmentDueSegment
  | ExternalProgramSegment
  | TeamDiscussionSegment;

export type SegmentResource = Resource<Segment>;

/**
 * Typically, the pattern is for the following types to live with the store that
 * corresponds to the type. For a couple of the segment types, we do not have
 * stores:
 *  - GotoWebinarSegment: This segment type is not supported in P3.
 *  - MeetingPreferenceSegment: This segment type is not supported in P3.
 *
 * For these segment types, we have the types here.
 */

export interface AssignmentDueParts {
  type: 'assignment-due';
  content: string;
  uploadedAttachments: UploadedAttachmentResources;
  embeddedVideoAssets: LegacyAndNewVideoAssetResource[];
  embeddedDocumentAssets: DocumentAssetResource[];
  embeddedFormFieldAssets: FormFieldAssetResource[];
}

export type CompletabilityValue = 'not-completable' | 'completable';

export interface ExternalProgramSegmentParts {
  type: 'external-program';
  externalProgramResources: Maybe<NonEmptyList<ExternalProgramResource>>;
  completability: CompletabilityValue;
}

export interface ExternalProgramSegment extends SegmentBase, ExternalProgramSegmentParts {}

export type LegacyAndNewVideoAssetResource = VideoAssetResource | ReqHlsVideoAssetResource;

export interface AssignmentDueSegment extends SegmentBase, AssignmentDueParts {}

export interface Attachment {
  title: string;
  kind: AttachmentKind;
  id: number;
}

export type VideosShowOption = 'default' | 'full-width';

//Any updates to WebPageParts should also be made to OverviewSegmentPreviewResource#payload
export interface WebPageParts {
  type: 'overview';
  content: string;
  showVideosFullWidth: VideosShowOption;
  embeddedVideoAssets: LegacyAndNewVideoAssetResource[];
  embeddedDocumentAssets: DocumentAssetResource[];
  embeddedFormFieldAssets: FormFieldAssetResource[];
  results: JsonValue;
  sessionScheduled: boolean;
  isFreebusyCoaching: boolean;
}
//Any updates to WebPageParts should also be made to OverviewSegmentPreviewResource#payload

export interface HtmlNode {
  name: string;
  children: [{ data: string }];
  attribs: {
    href: string;
    src?: string;
  };
}

export interface HtmlParserNode {
  name: 'a' | 'img';
  children: [{ data: string }];
  attribs: {
    href?: string;
    src?: string;
  };
}

export interface WebPageSegment extends SegmentBase, WebPageParts {}

export type AttachmentKind = 'document' | 'video';

export type AttachmentResource = Resource<Attachment>;

export interface DocumentAsset {
  uuid: string;
  title: string;
}

export type DocumentAssetResource = Resource<DocumentAsset>;

export type EmbeddableSegment = WebPageSegment | AssignmentDueSegment;
export interface TimeCommitment {
  duration: Maybe<number>;
  totalHours: Maybe<number>;
  hoursPerWeek: Maybe<number>;
}

export interface ExternalProgramBase {
  id: number;
  offeringType: OfferingType;
  primaryColor: string;
  secondaryColor: string;
  title: AlreadyTranslatedText;
  description: AlreadyTranslatedText;
  schoolPartner: Resource<SchoolPartner>;
  timeCommitment: Maybe<TimeCommitment>;
  publicDetailedDescriptionHtml: Maybe<AlreadyTranslatedText>;
  whoShouldAttendHtml: Maybe<AlreadyTranslatedText>;
  keyTakeawaysHtml: Maybe<AlreadyTranslatedText>;
  programStructureAndFeaturesHtml: Maybe<AlreadyTranslatedText>;
  howItWorksHtml: Maybe<AlreadyTranslatedText>;
  primaryCompetencies: Array<CompetencyResource>;
  secondaryCompetencies: Array<CompetencyResource>;
}

export interface CompletedExternalProgram
  extends ExternalProgramBase,
    CompletedExternalProgramParts {}

export interface CompletedExternalProgramParts {
  kind: 'completed';
}

export interface StartedExternalProgramParts {
  kind: 'started';
  percentComplete: number;
}

export interface StartedExternalProgram extends ExternalProgramBase, StartedExternalProgramParts {}

export interface UnstartedExternalProgramParts {
  kind: 'unstarted';
}

export type ExternalProgramParts =
  | StartedExternalProgramParts
  | UnstartedExternalProgramParts
  | CompletedExternalProgramParts;

export interface UnstartedExternalProgram
  extends ExternalProgramBase,
    UnstartedExternalProgramParts {}

export type ExternalProgram =
  | StartedExternalProgram
  | UnstartedExternalProgram
  | CompletedExternalProgram;

export type ExternalProgramResource = Resource<ExternalProgram>;
export type UnstartedExternalProgramResource = Resource<UnstartedExternalProgram>;
export type StartedExternalProgramResource = Resource<StartedExternalProgram>;
export type CompletedExternalProgramResource = Resource<CompletedExternalProgram>;
export type SchoolPartnerResource = Resource<SchoolPartner>;
