import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../../../../SegmentStore';
import { SegmentResource } from '../../../../../../SegmentStore/Types';
import { AutoLaunchableStore } from '../../../../../AutoLaunchable/AutoLaunchableStore';
import DefaultSegmentContent from './DefaultSegmentContent';
import SurveySegmentContent from './SurveySegmentContent';

interface Props {
  longTitle: string;
  store: SegmentStore;
  segmentResource: SegmentResource;
  autoLaunchableStore: Maybe<AutoLaunchableStore>;
}

const SegmentContent: React.FC<Props> = ({
  store,
  longTitle,
  segmentResource,
  autoLaunchableStore,
}) => {
  switch (segmentResource.payload.type) {
    case 'survey':
      return <SurveySegmentContent store={store} segmentResource={segmentResource} />;
    case 'assignment-due':
    case 'lecture':
    case 'overview':
    case 'external-program':
    case 'presentation':
    case 'team-discussion':
      return (
        <DefaultSegmentContent
          store={store}
          segmentResource={segmentResource}
          longTitle={longTitle}
          autoLaunchableStore={autoLaunchableStore}
        />
      );
  }
};

export default observer(SegmentContent);
