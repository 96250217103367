import { observer } from 'mobx-react';
import * as React from 'react';
import HeroSchoolPartnerLogoImage from './HeroSchoolPartnerLogoImage';
import { just } from 'maybeasy';
import { findLink } from '../../../../../../../LinkyLinky';
import { SchoolPartnerResource } from '../../../../../../../SegmentStore/Types';
import { emptyFragment } from '../../../../../../../EmptyFragment';
import * as style from '../../../style.module.css';

interface Props {
  schoolPartnerResource: SchoolPartnerResource;
}

const HeroSchoolPartnerLogo: React.FC<Props> = ({ schoolPartnerResource }) =>
  just(schoolPartnerResource.links)
    .andThen(findLink('logo-on-dark'))
    .map(({ href }) => {
      return (
        <div className={style.imageContainer}>
          <HeroSchoolPartnerLogoImage alt={schoolPartnerResource.payload.name.text} href={href} />
        </div>
      );
    })
    .getOrElse(emptyFragment);

export default observer(HeroSchoolPartnerLogo);
