import { find } from '@execonline-inc/collections';
import { JsonValue } from '@execonline-inc/decoders';
import { pick } from '@kofno/piper';
import { array, field, succeed } from 'jsonous';
import { just, Maybe, nothing } from 'maybeasy';
import { formFieldOutputDecoder } from '../components/EmbeddedFormFieldAsset/Decoders';
import {
  DecodedFormFieldOutput,
  FormFieldOutputValue,
} from '../components/EmbeddedFormFieldAsset/Types';

export const decodeFormFieldOutput = (results: unknown): Maybe<DecodedFormFieldOutput> =>
  succeed({})
    .assign('value', field('value', array(formFieldOutputDecoder)))
    .decodeAny(results)
    .map(just)
    .getOrElse(nothing);

export const getFieldValue = (
  results: Maybe<JsonValue>,
  formFieldId: string
): Maybe<FormFieldOutputValue> =>
  results
    .andThen(decodeFormFieldOutput)
    .map(pick('value'))
    .andThen(find((formFieldOutput) => formFieldOutput.id === formFieldId))
    .map(pick('value'));
