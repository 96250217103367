import clsx from 'clsx';
import * as React from 'react';
import { Typography } from '../UI';
import { T, TranslationsContext, translation } from '../../Translations';
import AppStoreGraphic from '../Cutesies/AppStoreGraphic';
import * as style from './style.module.css';

const LinkedMobileApp: React.FC = () => {
  return (
    <TranslationsContext.Consumer>
      {(ts) => (
        <div className={clsx(style.wrapper)}>
          <Typography className="mb-5" variant="h6" component="p" fontWeight="medium">
            <T kind="Learn on the go with our mobile app" />
          </Typography>
          <a
            href="https://itunes.apple.com/us/app/apple-store/id1437096431?mt=8"
            aria-label={translation('Download on the App Store')(ts)}
          >
            <div className={style.iconWrapper}>
              <AppStoreGraphic />
            </div>
          </a>
        </div>
      )}
    </TranslationsContext.Consumer>
  );
};

export default LinkedMobileApp;
