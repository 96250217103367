import { observer } from 'mobx-react';
import * as React from 'react';
import EditPasswordStore from '../../EditPasswordStore';
import { T } from '../../Translations';
import Loader from '../Loader';
import * as style from '../ResetPassword/style.module.css';
import Form from './Form';

interface Props {
  store: EditPasswordStore;
}

const EditPasswordRenderer: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'creating':
    case 'ready':
    case 'ready-with-client-error':
    case 'ready-with-server-error':
      return (
        <>
          <h1 className={style.title} data-test-title={true}>
            <T kind="Reset Your Password" />
          </h1>
          <p>
            <T kind="Enter a new password for your account." />
            <br />
            <T kind="Be sure to choose a secure password that only you will know." />
          </p>
          <Form store={store} />
        </>
      );
    case 'loading':
      return <Loader />;
    case 'waiting':
    case 'error':
      return <></>;
  }
};

export default observer(EditPasswordRenderer);
