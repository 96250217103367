import { Maybe } from 'maybeasy';
import { Error } from '../../../../ErrorHandling';
import { TPlainTextKey } from '../../../../Translations';
import { EnrollmentResource } from '../Enrollment/Store/Types';

interface Initializing {
  kind: 'initializing';
  paymentIntentId: string;
}

interface PusherError {
  kind: 'pusher-error';
  message: TPlainTextKey;
  detail: TPlainTextKey;
  paymentIntentId: string;
}

interface EnrollmentError {
  kind: 'enrollment-error';
  message: TPlainTextKey;
  detail: TPlainTextKey;
}

interface PaymentError {
  kind: 'payment-error';
  message: TPlainTextKey;
  detail: TPlainTextKey;
}

interface WaitingForMessage {
  kind: 'waiting-for-message';
  paymentIntentId: string;
}

interface PaymentStillProcessing {
  kind: 'payment-still-processing';
  paymentIntentId: string;
}

interface RequestPaymentStatus {
  kind: 'request-payment-status';
  paymentIntentId: string;
}

interface PaymentStatusRequested {
  kind: 'payment-status-requested';
  paymentIntentId: string;
}

interface PaidAndEnrolled {
  kind: 'paid-and-enrolled';
  enrollmentResource: EnrollmentResource;
  paymentIntentId: string;
}

interface ReadyToRedirect {
  kind: 'ready-to-redirect';
  enrollmentResource: Maybe<EnrollmentResource>;
  paymentIntentId: string;
}

export type PaymentIntentErrorType =
  | 'internal-server-error'
  | 'enrollment-error'
  | 'payment-declined-error';

export interface PaymentIntentError {
  type: PaymentIntentErrorType;
}

export interface MessageReceived {
  kind: 'message-received';
  responseBody: unknown;
  paymentIntentId: string;
  eventName: PaymentConfirmationEventName;
}

export type PaymentConfirmationEventName =
  | 'succeeded'
  | 'processing'
  | 'processing-timed-out'
  | 'error';

export const initialize = (): Initializing => ({
  kind: 'initializing',
  paymentIntentId: '',
});

export const waitingForMessage = (id: string): WaitingForMessage => ({
  kind: 'waiting-for-message',
  paymentIntentId: id,
});

export const paymentStillProcessing = (id: string): PaymentStillProcessing => ({
  kind: 'payment-still-processing',
  paymentIntentId: id,
});

export const requestPaymentStatus = (id: string): RequestPaymentStatus => ({
  kind: 'request-payment-status',
  paymentIntentId: id,
});

export const paymentStatusRequested = (id: string): PaymentStatusRequested => ({
  kind: 'payment-status-requested',
  paymentIntentId: id,
});

export const pusherError = (
  message: TPlainTextKey,
  detail: TPlainTextKey,
  paymentIntentId: string
): PusherError => ({
  kind: 'pusher-error',
  message,
  detail,
  paymentIntentId,
});

export const enrollmentError = (
  message: TPlainTextKey,
  detail: TPlainTextKey
): EnrollmentError => ({
  kind: 'enrollment-error',
  message,
  detail,
});

export const paymentError = (message: TPlainTextKey, detail: TPlainTextKey): PaymentError => ({
  kind: 'payment-error',
  message,
  detail,
});

export const messageReceived = (
  eventName: PaymentConfirmationEventName,
  responseBody: unknown,
  paymentIntentId: string
): MessageReceived => ({
  kind: 'message-received',
  eventName,
  responseBody,
  paymentIntentId,
});

export const paidAndEnrolled = (
  enrollmentResource: EnrollmentResource,
  paymentIntentId: string
): PaidAndEnrolled => ({
  kind: 'paid-and-enrolled',
  enrollmentResource,
  paymentIntentId,
});

export const readyToRedirect = (
  enrollmentResource: Maybe<EnrollmentResource>,
  paymentIntentId: string
): ReadyToRedirect => ({
  kind: 'ready-to-redirect',
  enrollmentResource,
  paymentIntentId,
});

export type EventState =
  | Initializing
  | WaitingForMessage
  | Error
  | PusherError
  | EnrollmentError
  | PaidAndEnrolled
  | ReadyToRedirect
  | MessageReceived
  | RequestPaymentStatus
  | PaymentStillProcessing
  | PaymentError
  | PaymentStatusRequested;
