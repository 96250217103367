import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../../Notifications/Types';
import { TPlainTextKey } from '../../../../Translations';
import { EnrollmentResource } from '../Enrollment/Store/Types';
import {
  EventState,
  initialize,
  requestPaymentStatus,
  messageReceived,
  paidAndEnrolled,
  PaymentConfirmationEventName,
  paymentStillProcessing,
  readyToRedirect,
  waitingForMessage,
  paymentStatusRequested,
  pusherError,
  enrollmentError,
  paymentError,
} from './Types';

class PaymentConfirmationStore {
  @observable
  state: EventState;

  constructor() {
    this.state = initialize();
  }

  @action
  waitingForMessage = (id: string) => {
    switch (this.state.kind) {
      case 'initializing':
      case 'message-received':
      case 'payment-still-processing':
        this.state = waitingForMessage(id);
        break;
      case 'waiting-for-message':
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  paymentStillProcessing = (id: string) => {
    switch (this.state.kind) {
      case 'initializing':
        break;
      case 'message-received':
        this.state = paymentStillProcessing(id);
        break;
      case 'payment-still-processing':
      case 'waiting-for-message':
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  messageReceived = (
    eventName: PaymentConfirmationEventName,
    enrollmentResponse: unknown,
    paymentIntentId: string
  ) => {
    switch (this.state.kind) {
      case 'initializing':
        break;
      case 'waiting-for-message':
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'payment-still-processing':
        this.state = messageReceived(eventName, enrollmentResponse, paymentIntentId);
        break;
      case 'message-received':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  paidAndEnrolled = (paymentIntentId: string) => (enrollmentResource: EnrollmentResource): void => {
    switch (this.state.kind) {
      case 'initializing':
      case 'waiting-for-message':
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'payment-still-processing':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
        break;
      case 'message-received':
        this.state = paidAndEnrolled(enrollmentResource, paymentIntentId);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyToRedirect = (
    enrollmentResource: Maybe<EnrollmentResource>,
    paymentIntentId: string
  ): void => {
    switch (this.state.kind) {
      case 'initializing':
      case 'waiting-for-message':
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'message-received':
      case 'payment-still-processing':
      case 'ready-to-redirect':
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
        break;
      case 'paid-and-enrolled':
        this.state = readyToRedirect(enrollmentResource, paymentIntentId);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  requestPaymentStatus = (paymentIntentId: string) => {
    switch (this.state.kind) {
      case 'waiting-for-message':
        this.state = requestPaymentStatus(paymentIntentId);
        break;
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'message-received':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'error':
      case 'initializing':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
      case 'payment-still-processing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  paymentStatusRequested = (paymentIntentId: string) => {
    switch (this.state.kind) {
      case 'waiting-for-message':
        break;
      case 'request-payment-status':
        this.state = paymentStatusRequested(paymentIntentId);
        break;
      case 'payment-status-requested':
      case 'message-received':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'error':
      case 'initializing':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
      case 'payment-still-processing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  pusherError = (msg: TPlainTextKey, detail: TPlainTextKey, paymentIntentId: string) => {
    switch (this.state.kind) {
      case 'error':
      case 'pusher-error':
      case 'initializing':
      case 'enrollment-error':
      case 'payment-error':
      case 'waiting-for-message':
      case 'message-received':
      case 'payment-still-processing':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'request-payment-status':
        break;
      case 'payment-status-requested':
        this.state = pusherError(msg, detail, paymentIntentId);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  enrollmentError = (msg: TPlainTextKey, detail: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
      case 'initializing':
      case 'waiting-for-message':
        break;
      case 'message-received':
        this.state = enrollmentError(msg, detail);
        break;
      case 'payment-still-processing':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'request-payment-status':
        break;
      case 'payment-status-requested':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  paymentError = (msg: TPlainTextKey, detail: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
      case 'initializing':
      case 'waiting-for-message':
        break;
      case 'message-received':
        this.state = paymentError(msg, detail);
        break;
      case 'payment-still-processing':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'request-payment-status':
        break;
      case 'payment-status-requested':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'initializing':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
      case 'waiting-for-message':
      case 'message-received':
      case 'payment-still-processing':
      case 'paid-and-enrolled':
      case 'ready-to-redirect':
      case 'request-payment-status':
      case 'payment-status-requested':
        return nothing();
    }
  }

  @computed
  get enrollmentResource(): Maybe<EnrollmentResource> {
    switch (this.state.kind) {
      case 'waiting-for-message':
      case 'error':
      case 'initializing':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
      case 'message-received':
      case 'ready-to-redirect':
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'payment-still-processing':
        return nothing();
      case 'paid-and-enrolled':
        return just(this.state.enrollmentResource);
    }
  }

  @computed
  get paymentIntentId(): Maybe<string> {
    switch (this.state.kind) {
      case 'initializing':
      case 'error':
      case 'pusher-error':
      case 'enrollment-error':
      case 'payment-error':
        return nothing();
      case 'ready-to-redirect':
      case 'paid-and-enrolled':
      case 'waiting-for-message':
      case 'message-received':
      case 'request-payment-status':
      case 'payment-status-requested':
      case 'payment-still-processing':
        return just(this.state.paymentIntentId);
    }
  }
}

export default PaymentConfirmationStore;
