import { AlreadyTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import ExperiencesStore from '../../Experiences/ExperiencesStore';
import HtmlView from '../../../../HtmlView';

interface Props {
  experiencesStore: ExperiencesStore;
}

const WelcomeMessage: React.FC<Props> = ({ experiencesStore }) => {
  return experiencesStore.useCase
    .map((useCase) => (
      <div className="sm:px-0">
        <div className="mx-auto box-border px-6 pb-8 pt-11 xl:container md:px-16">
          <Typography className="text-black" variant="h4" as="h1">
            <AlreadyTranslated content={useCase.name} />
          </Typography>
          <HtmlView description={useCase.description} />
        </div>
      </div>
    ))
    .getOrElse(emptyFragment);
};

export default observer(WelcomeMessage);
