import { observer } from 'mobx-react';
import { cn } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../../../Common/Experience/Types';
import ExperienceTimeCommitment from './ExperienceTimeCommitment';

interface Props {
  experienceResource: ExperienceResource;
  className?: string;
}

function ExperienceOverview({ experienceResource, className }: Props) {
  return (
    <div className={cn('flex gap-4', className)}>
      <ExperienceTimeCommitment experience={experienceResource.payload} />
    </div>
  );
}

export default observer(ExperienceOverview);
