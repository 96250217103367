import { useEffect, useRef } from 'react';
import { find } from '@execonline-inc/collections';
import MediaQueryStore from './MediaQueryStore';
import { MediaQuery, MediaQueryBreakPoint, State, defaultMediaQueries } from './Types';

const breakPoint = (state: State): MediaQueryBreakPoint => {
  const predicate = (item: MediaQuery): boolean => item.matches;

  return find(predicate, state.queries.reverse())
    .map((item: MediaQuery) => item.breakPoint)
    .getOrElseValue('xs');
};

const useMediaQuery = (): MediaQueryBreakPoint => {
  const store = useRef(new MediaQueryStore());

  useEffect(() => {
    const mediaQueryList = defaultMediaQueries.map(({ media }) => window.matchMedia(media));

    const handleChange = (event: MediaQueryListEvent) => {
      store.current.update(event);
    };

    mediaQueryList.forEach((mediaQuery) => {
      mediaQuery.addEventListener('change', handleChange);
    });

    return () => {
      mediaQueryList.forEach((mediaQuery) => {
        mediaQuery.removeEventListener('change', handleChange);
      });
    };
  }, []);

  return breakPoint(store.current.state);
};

export default useMediaQuery;
