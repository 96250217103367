import { observer } from 'mobx-react';
import React from 'react';
import { findLink } from '../../../../../../LinkyLinky';
import { T } from '../../../../../../Translations';
import CertificateOfCompletion from './CertificateOfCompletion';
import { ExternalProgramResource } from '../../../../../../SegmentStore/Types';
import { emptyFragment } from '../../../../../../EmptyFragment';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const Certificate: React.FC<Props> = ({ externalProgramResource }) =>
  findLink('download-digital-cert-preview', externalProgramResource.links)
    .map((certLink) => (
      <div className="mb-8">
        <div className="font-semibold text-lg uppercase">
          <T kind="Certificate" />
        </div>
        <div className="leading-normal text-base font-light mt-4 mb-5">
          <CertificateOfCompletion
            externalProgramResource={externalProgramResource}
            certLink={certLink}
          />
        </div>
      </div>
    ))
    .getOrElse(emptyFragment);

export default observer(Certificate);
