import React from 'react';
import { observer } from 'mobx-react';
import Divider, { Props as DividerProps } from '../Divider';

interface Props extends DividerProps {}

const ListItemDivider: React.FC<Props> = (props) => {
  return <Divider component="li" {...props} />;
};

export default observer(ListItemDivider);
