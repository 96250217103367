import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { dashboardUrl } from '../../../ClientRoutes';
import ResetPasswordStore from '../../../ResetPasswordStore';
import { T } from '../../../Translations';
import Button from '../../Form/Button';
import Input from '../../Form/Input';
import Loader from '../../Loader';
import * as style from '../style.module.css';

interface Props {
  store: ResetPasswordStore;
}

const Form: React.FC<Props> = ({ store }) => {
  const setEmail = (event: React.FormEvent<HTMLInputElement>) => {
    store.setEmail(event.currentTarget.value);
  };

  const submit = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    store.creating();
  };

  switch (store.state.kind) {
    case 'creating':
    case 'requested':
    case 'error':
    case 'ready': {
      return (
        <>
          <form>
            <div className={style.field}>
              <label className={clsx(style.label, 'text-neutral-900')} htmlFor="login_email">
                <T kind="Email Address" />
              </label>
              <Input
                autoFocus={true}
                type="email"
                onChange={setEmail}
                hint="email@domain.com"
                name="email"
                id="login_email"
                data-test-input-email={true}
                className={style.input}
                value={store.email.getOrElseValue('')}
              />
            </div>
            <div className={style.actions}>
              <Button
                type="submit"
                value="Confirm Reset Password"
                onClick={submit}
                className={clsx(
                  style.button,
                  'mx-0 my-3 bg-green-primary text-white hover:bg-[#187723]',
                )}
                data-test-action-reset-password={true}
              />
            </div>
          </form>
          <br />
          <Link
            to={dashboardUrl}
            className={clsx(
              'mx-0 my-3 inline-block flex-auto cursor-pointer whitespace-nowrap rounded-sm px-4 py-2 text-center text-sm font-light md:mr-2',
              'border border-solid border-gray-600-old bg-transparent text-gray-600-old hover:border-gray-800-old hover:bg-gray-50-old hover:text-gray-800-old',
            )}
            data-test-login-link={true}
          >
            <T kind="Sign In" />
          </Link>
        </>
      );
    }
    case 'loading':
    case 'waiting':
      return <Loader />;
  }
};
export default observer(Form);
