import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '../../../../../components/UI';
import ErrorIcon from '../../../../../components/Cutesies/ErrorIcon';
import { T, TPlainTextKey } from '../../../../../Translations';

interface Props {
  message: TPlainTextKey;
  detail: TPlainTextKey;
}

const Error: React.FC<Props> = ({ message, detail }) => (
  <div className="flex flex-col self-center content-center items-center h-full w-full">
    <ErrorIcon />
    <Typography
      className="tracking-wide text-center leading-none mt-8 md:mt-10 xl:mt-12"
      variant="h5"
      component="p"
      fontWeight="medium"
    >
      <T kind={message} />
    </Typography>
    <Typography className="leading-tight text-center mt-14" variant="subtitle2" component="p">
      <T kind={detail} />
    </Typography>
  </div>
);

export default observer(Error);
