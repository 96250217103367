import { ButtonSize } from '@execonline-inc/execonline-ui';
import { MediaQueryBreakPoint } from '../../../../../../../components/UI';

export const getButtonSize = (breakPoint: MediaQueryBreakPoint): ButtonSize => {
  switch (breakPoint) {
    case 'xl':
    case 'lg':
    case 'md':
    case 'sm':
      return 'lg';
    case 'xs':
      return 'md';
  }
};
