import React, { HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface Props extends HTMLAttributes<HTMLLIElement> {}

const ListItem: React.FC<Props> = ({ className, children, ...others }) => {
  return (
    <li className={twMerge(clsx('flex', className))} {...others}>
      {children}
    </li>
  );
};

export default observer(ListItem);
