import { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { IconButton, SvgIcon } from '../../../../../../components/UI';

interface Props {
  disabled?: boolean;
  icon: typeof SvgIcon;
  onClick: (event?: MouseEvent<HTMLButtonElement>) => void;
}

function NavigationButton({ icon: Icon, disabled, onClick }: Props) {
  return (
    <div>
      <IconButton
        className="h-full w-[30px] items-center text-font-size-4xl lg:w-10"
        disabled={disabled}
        onClick={onClick}
      >
        {<Icon color="gray" size="inherit" disabled={disabled} />}
      </IconButton>
    </div>
  );
}

export default observer(NavigationButton);
