import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Typography } from '../../../../../components/UI';
import SuccessIcon from '../../../../../components/Cutesies/SuccessIcon';
import { emptyFragment } from '../../../../../EmptyFragment';
import { T } from '../../../../../Translations';
import Exit from '../../../../Exit';
import { exitToDashboard } from '../../../Common/Experience/Actions/Exitable/Types';
import PaymentConfirmationStore from '../Store';
import TransactionNumber from '../TransactionNumber';
import ExperienceDetailMessage from './ExperienceDetailMessage';

interface Props {
  store: PaymentConfirmationStore;
}

const Redirect: React.FC<Props> = ({ store }) => (
  <div className="flex h-full w-full flex-col content-center items-center self-center">
    <SuccessIcon />
    <Typography
      className="mt-8 text-center leading-none tracking-wide md:mt-10 xl:mt-12"
      variant="h5"
      component="p"
      fontWeight="medium"
    >
      <T kind="Successfully Purchased Access" />
    </Typography>
    <div className="mt-2.5 text-center">
      <TransactionNumber paymentIntentId={store.paymentIntentId} />
    </div>
    {store.enrollmentResource
      .map((resource) => (
        <div className="mb-4 mt-14">
          <ExperienceDetailMessage enrollment={resource.payload} />
        </div>
      ))
      .getOrElse(emptyFragment)}
    <div className="mt-8 text-center">
      <Button className="min-w-[330px]" size="lg" disabled>
        <T kind="Loading..." />
        <Exit exitContext={exitToDashboard()} />
      </Button>
    </div>
  </div>
);
export default observer(Redirect);
