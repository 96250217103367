import { observer } from 'mobx-react';
import { emptyFragment } from '../EmptyFragment';
import { sessionStore } from '../Session/Store';

interface WhenImpersonatingProps {
  children: JSX.Element | ((impersonator: string) => JSX.Element);
}

function WhenImpersonating({ children }: WhenImpersonatingProps) {
  return sessionStore.impersonatingBy
    .map((name) => (typeof children === 'function' ? children(name) : children))
    .getOrElse(emptyFragment);
}

export default observer(WhenImpersonating);
