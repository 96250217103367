import { dropUntil, find, flatMap } from '@execonline-inc/collections';
import { Seconds, Time } from '@execonline-inc/time';
import { Maybe, just, nothing } from 'maybeasy';
import { fromArrayMaybe } from 'nonempty-list';
import { AppyError, callApi } from '../Appy';
import { Error } from '../ErrorHandling';
import { MissingLinkError } from '../LinkyLinky';
import { LanguagesResource } from '../ProfileFormStore/Types';
import { ProfileResource } from '../ProfileStore/Types';
import { ProgramResource } from '../ProgramsStore/Types';
import { Link, Resource } from '../Resource/Types';
import { ModulePresentationStyle } from '../SegmentStore/Types';
import { AlreadyTranslatedText } from '../Translations';
import { CoachProfileResource } from '../components/EmbeddedFormFieldAsset/Types';
import { Position } from '../components/PositionContext';
import { programDetailsResourceDecoder } from './Decoders';
import { Linkables } from '../components/Linkable/Types';

export type ProgramDetailResource = Resource<Program>;

export type Program =
  | ActiveProgram
  | CompletedProgram
  | UpcomingProgram
  | InactiveProgram
  | ExpiredProgram;

export type Image = {
  src: string;
  alt: string;
};

export interface CoachingSession {
  id: number;
  date: Date;
  startAt: Maybe<Date>;
  endAt: Maybe<Date>;
  accessAt: Maybe<Date>;
  duration: Maybe<Seconds>;
}

export type CoachingSessionResource = Resource<CoachingSession>;

export interface CoachingProductDetails {
  kind: 'coaching';
  orientationStatus: 'Unstarted' | 'Started' | 'Completed' | 'NotApplicable';
  selectedCoach: Maybe<CoachProfileResource>;
  sessions: CoachingSessionResource[];
  pastSessionsCount: number;
}

export interface ProgramProductDetails {
  kind: 'program';
}

export interface ProgramSequenceProductDetails {
  kind: 'program-sequence';
}

export interface GroupCoachingProductDetails {
  kind: 'group-coaching';
  id: number;
  title: AlreadyTranslatedText;
  memberProfileResources: Array<ProfileResource>;
  upcomingSessionResource: Maybe<UpcomingSessionPayload>;
}

export interface UpcomingSession {
  kind: 'group-coaching-session';
  startAt: Date;
  durationInMinutes: number;
  coachingProfileResource: Maybe<CoachProfileResource>;
  themeDetails: AlreadyTranslatedText;
  name: AlreadyTranslatedText;
}

export type UpcomingSessionPayload = Resource<UpcomingSession>;

export type ProductDetails =
  | ProgramProductDetails
  | CoachingProductDetails
  | ProgramSequenceProductDetails
  | GroupCoachingProductDetails;

export type ProductDetailsResource = Resource<ProductDetails>;

export interface ProgramBase {
  id: number;
  title: AlreadyTranslatedText;
  programFormat: 'modules' | 'chapters';
  availableLanguages: LanguagesResource;
  productDetails: ProductDetailsResource;
  directRegistrationGuid: Maybe<string>;
  experienceOrigin: 'commerce-direct-unpaid' | 'commerce-direct-paid' | 'unavailable';
  presentationStyle: Maybe<ModulePresentationStyle>;
  linkables: Linkables;
}

export interface Progress {
  kind: 'registration-segment-progress' | 'program-progress' | 'module-progress';
  completedSegments: number;
  totalSegments: number;
}

export interface ParticipateableProgram {
  daysSinceProgramEnd: Maybe<number>;
  daysRemaining: Maybe<number>;
  daysBehind: Maybe<number>;
  endsOn: Maybe<Date>;
  userProductLicenseEndsOn: Maybe<Date>;
  accessKind:
    | 'current-unlocked'
    | 'current-warning'
    | 'current-danger'
    | 'ended-unlocked'
    | 'ended-warning'
    | 'ended-danger';
  lockedOutOn: Maybe<Date>;
  modules: ActiveProgramModule[];
  chapters: ActiveProgramChapter[];
  courseCompletedAt: Maybe<Date>;
  accessEndsOn: Maybe<Date>;
  sfProgramUid: Maybe<string>;
  currentChapterName: Maybe<AlreadyTranslatedText>;
  progress: Progress;
  moduleProgress: Maybe<Progress>;
  programInstanceKind: 'scheduled' | 'on-demand';
  daysUntilLockedOut: Maybe<number>;
}

export interface ActiveProgram extends ProgramBase, ParticipateableProgram {
  kind: 'active';
}

export interface CompletedProgram extends ProgramBase, ParticipateableProgram {
  kind: 'completed';
}

export interface ExpiredProgram extends ProgramBase {
  kind: 'expired';
}

export interface UpcomingProgram extends ProgramBase {
  kind: 'upcoming';
  modules: UpcomingProgramModule[];
  chapters: UpcomingProgramChapter[];
  message: string;
  startDate: StartDate;
  accessDate: AccessDate;
}

export interface InactiveProgram extends ProgramBase {
  kind: 'inactive';
}

export interface ActiveProgramModule {
  title: AlreadyTranslatedText;
  id: number;
  percentComplete: number;
  isCurrentModule: boolean;
  isMilestone: boolean;
  isGated: boolean;
  gatedMessage: Maybe<AlreadyTranslatedText>;
  daysRemaining: Maybe<number>;
  daysSinceModuleEnd: Maybe<number>;
  label: Maybe<string>;
  timeToComplete: Maybe<Time>;
  segments: ProgramSegment[];
  startsOn: Maybe<Date>;
  endsOn: Maybe<Date>;
  expectedPercentComplete: Maybe<number>;
  expectedModule: Maybe<boolean>;
  offeringType: Maybe<ProductDetails['kind']>;
}

export interface ActiveProgramChapter {
  name: string;
  modules: ActiveProgramModule[];
}

export interface UpcomingProgramModule {
  id: number;
  title: string;
  gatedUntil: Maybe<Date>;
}

export interface UpcomingProgramChapter {
  name: string;
  modules: UpcomingProgramModule[];
}

export type ProgramModule = ActiveProgramModule | UpcomingProgramModule;

export type AccessDate = AccessibleAnytime | AccessibleOn;

export interface AccessibleAnytime {
  kind: 'accessible-anytime';
}

export interface AccessibleOn {
  kind: 'accessible-on';
  date: Date;
}

export type StartDate = StartsAnytime | StartsOn;

export interface StartsAnytime {
  kind: 'starts-anytime';
}

export interface StartsOn {
  kind: 'starts-on';
  date: Date;
}

export type SegmentStatus = 'Complete' | 'Accessible' | 'Inaccessible' | 'Started';

export interface ProgramSegment {
  title: string;
  id: number;
  moduleId: number;
  longTitle: string;
  programId: number;
  isCurrentSegment: boolean;
  status: SegmentStatus;
  timeToComplete: Maybe<Time>;
  links: ReadonlyArray<Link>;
}

export const whenActive = (program: Program): Maybe<ActiveProgram | CompletedProgram> => {
  switch (program.kind) {
    case 'active':
    case 'completed':
      return just(program);
    case 'expired':
    case 'upcoming':
    case 'inactive':
      return nothing();
  }
};

export const whenUpcoming = (program: Program): Maybe<UpcomingProgram> => {
  switch (program.kind) {
    case 'upcoming':
      return just(program);
    case 'active':
    case 'completed':
    case 'expired':
    case 'inactive':
      return nothing();
  }
};

export const analyticsEventLabel = (position: Position): Maybe<string> => {
  switch (position.kind) {
    case 'dashboard':
    case 'none':
      return just('Dashboard');
    case 'overview':
      return just('ProgramOverview');
    case 'nav':
    case 'tooltip':
    case 'supPanel':
    case 'chat':
    case 'participant':
    case 'footer':
      return nothing();
  }
};

export type ProgramError = AppyError | MissingLinkError;

export const getProgram = callApi(programDetailsResourceDecoder, {});

export const reloading = (programDetailResource: ProgramDetailResource): Reloading => ({
  kind: 'reloading',
  programDetailResource,
});

export const loading = (programResource: ProgramResource): Loading => ({
  kind: 'loading',
  programResource,
});

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const courseNotReady = (): CourseNotReady => ({
  kind: 'course-not-ready',
});

export const ready = (programDetailResource: ProgramDetailResource): Ready => ({
  kind: 'ready',
  programDetailResource,
});

export const cancellingRegistration = (cancelLink: Link): CancellingRegistration => ({
  kind: 'cancelling-registration',
  cancelLink,
});

export const registrationCancelled = (): RegistrationCancelled => ({
  kind: 'registration-cancelled',
});

interface Waiting {
  kind: 'waiting';
}

interface CourseNotReady {
  kind: 'course-not-ready';
}

interface Loading {
  kind: 'loading';
  programResource: ProgramResource;
}

interface Ready {
  kind: 'ready';
  programDetailResource: ProgramDetailResource;
}

interface Reloading {
  kind: 'reloading';
  programDetailResource: ProgramDetailResource;
}

interface CancellingRegistration {
  kind: 'cancelling-registration';
  cancelLink: Link;
}

interface RegistrationCancelled {
  kind: 'registration-cancelled';
}

export type State =
  | Loading
  | Error
  | Ready
  | Reloading
  | Waiting
  | CourseNotReady
  | CancellingRegistration
  | RegistrationCancelled;

export const progress = (programDetail: Program): Maybe<Progress> => {
  switch (programDetail.kind) {
    case 'active':
    case 'completed':
      return just(programDetail.progress);
    case 'upcoming':
    case 'expired':
    case 'inactive':
      return nothing();
  }
};

export const moduleProgress = (programDetail: Program): Maybe<Progress> => {
  switch (programDetail.kind) {
    case 'active':
    case 'completed':
      return programDetail.moduleProgress;
    case 'upcoming':
    case 'expired':
    case 'inactive':
      return nothing();
  }
};

export const remainingDays = (programDetail: Program): Maybe<number> => {
  switch (programDetail.kind) {
    case 'active':
    case 'completed':
      return programDetail.daysRemaining;
    case 'upcoming':
    case 'expired':
    case 'inactive':
      return nothing();
  }
};

export const programEndOnDate = (programDetail: Program): Maybe<Date> => {
  switch (programDetail.kind) {
    case 'active':
    case 'completed':
      return programDetail.userProductLicenseEndsOn.orElse(() => programDetail.endsOn);
    case 'upcoming':
    case 'expired':
    case 'inactive':
      return nothing();
  }
};

export const modulesRemainingCount = (
  programDetail: Program,
  programDetailResource: ProgramDetailResource,
  currentModule: Maybe<ActiveProgramModule>,
): Maybe<number> => {
  switch (programDetail.kind) {
    case 'completed':
    case 'active':
      return modulesCompletedCount(programDetailResource, currentModule).map(
        (completedCount) => programDetail.modules.length - completedCount,
      );
    case 'upcoming':
    case 'expired':
    case 'inactive':
      return nothing();
  }
};

export const modulesCompletedCount = (
  programDetailResource: ProgramDetailResource,
  currentModule: Maybe<ActiveProgramModule>,
): Maybe<number> => {
  switch (programDetailResource.payload.kind) {
    case 'completed':
    case 'active': {
      const { modules } = programDetailResource.payload;
      return currentModule.map((cm) => modules.indexOf(cm));
    }
    case 'upcoming':
    case 'expired':
    case 'inactive':
      return nothing();
  }
};

export const programModules = (programDetail: Program): Maybe<ProgramModule[]> => {
  switch (programDetail.kind) {
    case 'active':
    case 'upcoming':
    case 'completed':
      return just(programDetail.modules);
    case 'expired':
    case 'inactive':
      return nothing();
  }
};

export const upcomingModules = (
  programDetail: Program,
): Maybe<ReadonlyArray<ActiveProgramModule>> => {
  switch (programDetail.kind) {
    case 'active':
      return currentModule(programDetail).map((currentModule) =>
        dropUntil((t) => t == currentModule, programDetail.modules),
      );
    case 'expired':
    case 'upcoming':
    case 'completed':
    case 'inactive':
      return nothing();
  }
};

export const currentSegment = (programDetail: Program): Maybe<ProgramSegment> => {
  switch (programDetail.kind) {
    case 'active':
    case 'completed':
      return fromArrayMaybe(
        flatMap((m) => m.segments.filter((s) => s.isCurrentSegment), programDetail.modules),
      ).map(({ first }) => first);
    case 'upcoming':
    case 'expired':
    case 'inactive':
      return nothing();
  }
};

export const currentModule = (programDetail: Program): Maybe<ActiveProgramModule> => {
  switch (programDetail.kind) {
    case 'active':
    case 'completed':
      return just(programDetail.modules).andThen(find((m) => m.isCurrentModule === true));
    case 'upcoming':
    case 'expired':
    case 'inactive':
      return nothing();
  }
};

export const sfProgramUid = (programDetailResource: ProgramDetailResource): Maybe<string> => {
  switch (programDetailResource.payload.kind) {
    case 'active':
    case 'completed':
      return programDetailResource.payload.sfProgramUid;
    case 'upcoming':
    case 'expired':
    case 'inactive':
      return nothing();
  }
};
