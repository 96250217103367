import { Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../../../../ProgramStore';
import SegmentStore from '../../../../../../SegmentStore';
import { SegmentResource } from '../../../../../../SegmentStore/Types';
import ModalStore from '../../../../../Modal/Store';
import SubmitAndAdvanceButton from '../../../../../Segment/SegmentNavigation/AdvanceButton/SubmitAndAdvanceButton';
import * as btnStyle from '../../../../../Segment/SegmentNavigation/AdvanceButton/SubmitAndAdvanceButton/style.module.css';
import SegmentReactions from '../../../../../SegmentReactions';
import * as style from '../style.module.css';
import ContentStates from './ContentStates';

interface Props {
  programStore: ProgramStore;
  modalStore: ModalStore;
}

class ModalSegment extends React.Component<Props, {}> {
  segmentStore = new SegmentStore('Streamlined');

  render() {
    const { programStore, modalStore } = this.props;

    return programStore.currentSegment
      .map((currentSegment) => {
        return (
          <>
            <ContentStates
              longTitle={currentSegment.longTitle}
              store={this.segmentStore}
              autoLaunchableStore={nothing()}
            />
            <div className={style.submitButtonContainer}>
              <SubmitAndAdvanceButton
                segmentStore={this.segmentStore}
                btnStyle={btnStyle.submitButton}
                text="Next"
              >
                {{ btnIcon: nothing() }}
              </SubmitAndAdvanceButton>
            </div>
            <SegmentReactions
              params={{
                programId: String(currentSegment.programId),
                moduleId: String(currentSegment.moduleId),
                segmentId: String(currentSegment.id),
              }}
              store={this.segmentStore}
              advanceHook={(nextSegment: Maybe<SegmentResource>) => {
                programStore.programDetailResource.map(programStore.reloading);
                nextSegment.elseDo(modalStore.close);
              }}
              fireImmediately={true}
            />
          </>
        );
      })
      .getOrElse(() => <></>);
  }
}

export default observer(ModalSegment);
