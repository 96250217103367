import React, { HTMLAttributes } from 'react';
import { observer } from 'mobx-react';

interface Props extends HTMLAttributes<HTMLDivElement> {}

const SectionBody: React.FC<Props> = ({ children, ...others }) => {
  return <div {...others}>{children}</div>;
};

export default observer(SectionBody);
