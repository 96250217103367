import Decoder, { field, number, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { CourseModuleProgress, CourseModuleProgressResource } from './Types';

export const courseModuleProgressDecoder: Decoder<CourseModuleProgress> = succeed({})
  .assign('id', field('id', number))
  .assign('totalCount', field('total_count', number))
  .assign('completedCount', field('completed_count', number));

export const progressDecoder: Decoder<CourseModuleProgressResource> = resourceDecoder(
  courseModuleProgressDecoder,
);
