import { observer } from 'mobx-react';
import { Button } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import { useExperienceEnrollmentContext } from '../../../../Common/ProgramExperienceEnrollment';
import { useMediaQuery } from '../../../../../../components/UI';
import { getButtonSize } from './Types';

function GetStartedButton() {
  const { onOpen } = useExperienceEnrollmentContext();
  const breakPoint = useMediaQuery();

  return (
    <Button
      radius="md"
      size={getButtonSize(breakPoint)}
      color="secondary"
      className="mt-20 min-w-full bg-white text-black sm:mt-14 sm:min-w-48"
      onClick={onOpen}
    >
      <T kind="Get Started" />
    </Button>
  );
}

export default observer(GetStartedButton);
