import { observer } from 'mobx-react';
import React, { ReactElement, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { toTask, when } from '@execonline-inc/maybe-adapter';
import { equals, find } from '@execonline-inc/collections';
import { emptyFragment } from '../../../../EmptyFragment';
import DropdownMenuList from '../DropdownMenuList';
import { DropdownMenuProps } from './Types';
import { Maybe, fromNullable, just, nothing } from 'maybeasy';
import { noop } from '@kofno/piper';

interface ValidEvent {
  target: HTMLElement;
}

function getViewport() {
  const win = window,
    d = document,
    e = d.documentElement,
    g = d.body,
    width = win.innerWidth || e.clientWidth || g.clientWidth,
    height = win.innerHeight || e.clientHeight || g.clientHeight,
    scroll = win.scrollY || e.scrollTop || g.scrollTop;

  return { width, height, scroll };
}

const calculateElementPosition = (popoverElement: HTMLElement, anchorElement: HTMLElement) => {
  const { offsetHeight: popoverElementHeight } = popoverElement;
  const { offsetWidth: anchorWidth, offsetHeight: anchorHeight } = anchorElement;
  const {
    top: anchorTop,
    bottom: anchorBottom,
    left: anchorLeft,
  } = anchorElement.getBoundingClientRect();

  const { height: viewportHeight, scroll: viewPortScroll } = getViewport();

  const left = anchorLeft;
  const top = when(
    anchorTop + anchorHeight + popoverElementHeight > viewportHeight - viewPortScroll,
    {},
  )
    .map(() => anchorTop - popoverElementHeight)
    .getOrElse(() => anchorBottom);
  popoverElement.style.top = top + viewPortScroll + 3 + 'px';
  popoverElement.style.left = left + 'px';
  popoverElement.style.bottom = 'auto';
  popoverElement.style.width = anchorWidth - 2 + 'px';
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  isOpen,
  anchorElement,
  children,
  onClose,
}) => {
  const rootElementRef = useRef<HTMLDivElement | null>(null);
  function handleFocus(
    open: boolean,
    rootElement: React.MutableRefObject<HTMLElement | null>,
    anchorElement: HTMLElement | null | undefined,
  ) {
    toTask('closed', when(open, true))
      .andThen(() =>
        toTask('rootElement-unavailable', fromNullable(rootElement.current)).do(() =>
          document.addEventListener('click', handleDocumentClick, true),
        ),
      )
      .orElse(() =>
        toTask('anchorElement-unavailable', fromNullable(anchorElement)).do(() =>
          document.removeEventListener('click', handleDocumentClick, true),
        ),
      )
      .fork(noop, (ref) => ref.focus());
  }
  useEffect(() => {
    handleFocus(isOpen, rootElementRef, anchorElement);
  }, [isOpen]);

  const calculatePosition = () =>
    when(isOpen, {}).andThen(() =>
      just({})
        .assign('ref', fromNullable(rootElementRef.current))
        .assign('anchor', fromNullable(anchorElement)),
    );

  useEffect(() => {
    calculatePosition().do(({ ref, anchor }) => {
      calculateElementPosition(ref, anchor);
      ref.style.opacity = '1';
    });
  }, [isOpen, rootElementRef.current, anchorElement]);

  const handleElementRef = (element: HTMLDivElement) => {
    rootElementRef.current = element;
  };

  const validateTarget = (e: MouseEvent) =>
    fromNullable(e.target).andThen<ValidEvent>((target) =>
      target instanceof HTMLElement ? just({ target }) : nothing(),
    );

  const isInDOMSubtree = (element: ValidEvent, subtreeRoot: HTMLElement): Maybe<{}> =>
    when(equals(element.target, subtreeRoot), {}).orElse(() =>
      when(subtreeRoot.contains(element.target), {}),
    );

  const handleDocumentClick = (e: MouseEvent) => {
    just({})
      .assign('currentRoot', fromNullable(rootElementRef.current))
      .assign('targetEvent', validateTarget(e))
      .assign('anchorElement', fromNullable(anchorElement))
      .map(({ currentRoot, targetEvent, anchorElement }) =>
        isInDOMSubtree(targetEvent, anchorElement).elseDo(() =>
          when(isOpen && isInDOMSubtree(targetEvent, currentRoot).isNothing(), {}).do(onClose),
        ),
      );
  };

  const handleListKeyDown = (e: React.KeyboardEvent<HTMLUListElement>) => {
    e.preventDefault();
    const validKeys = ['Tab', 'Escape'];
    just(validKeys)
      .andThen(find(equals(e.key)))
      .do(() => onClose());
  };

  return when(isOpen, true)
    .map<ReactElement>(() =>
      ReactDOM.createPortal(
        <div
          ref={handleElementRef}
          className={clsx(
            'absolute inset-0 overflow-x-hidden overflow-y-auto opacity-0 max-h-[280px] py-1 px-0',
            'transition-opacity duration-300',
            'shadow-[0_0_5px_rgba(0,0,0,0.2)] bg-white border border-solid border-[#ccc]',
          )}
        >
          <DropdownMenuList tabIndex={0} onKeyDown={handleListKeyDown}>
            {children}
          </DropdownMenuList>
        </div>,
        document.body,
      ),
    )
    .getOrElse(emptyFragment);
};

export default observer(DropdownMenu);
