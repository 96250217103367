import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { fromEmpty, Maybe } from 'maybeasy';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { ProductCollection } from '../../../../../Common/Experience/Types';
import DiscoveryContext from '../../../../../DiscoveryContext';
import EnrollmentStore from '../../../../Enrollment/Store';
import CompetencyFilterStore from '../../../Filters/CompetencyFilter/Store';
import DurationFilterStore from '../../../Filters/DurationFilter/Store';
import FilterStore from '../../../Filters/FilterStore';
import LanguageFilterStore from '../../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../../Filters/SchoolPartnerFilter/Store';
import ExperienceSection from '../Common/ExperienceSection';
import NoExperiences from '../Common/NoExperiences';
import TileCarouselExperiences from './TileCarouselExperiences';
import ProductCollectionDisplay from '../ProductCollections/ProductCollectionDisplay';

interface Props {
  productCollection: ProductCollection;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  searchValue: Maybe<string>;
}

function TileViewCarouselExperiences({
  productCollection,
  invitationUuid,
  enrollmentStore,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
  searchValue,
}: Props) {
  const filterExperiences = useCallback(
    (productCollection: ProductCollection): ProductCollection => {
      const experiences = productCollection.experiences.filter((experience) =>
        filterStore.showFilteredExperience(
          experience,
          languageFilterStore,
          durationFilterStore,
          schoolPartnerFilterStore,
          competencyFilterStore,
        ),
      );

      return { ...productCollection, experiences };
    },
    [
      competencyFilterStore.appliedValues,
      durationFilterStore.appliedValues,
      schoolPartnerFilterStore.appliedValues,
      languageFilterStore.appliedValues,
    ],
  );

  const filteredProductCollection = useMemo(() => {
    return filterExperiences(productCollection);
  }, [filterExperiences, productCollection]);

  return (
    <DiscoveryContext.Provider
      value={{
        kind: filteredProductCollection.kind,
        productCollectionId: filteredProductCollection.id,
      }}
    >
      <ExperienceSection
        title={<AlreadyTranslated content={filteredProductCollection.name} />}
        description={filteredProductCollection.description}
      >
        <ProductCollectionDisplay productCollection={filteredProductCollection}>
          {fromEmpty(filteredProductCollection.experiences)
            .map(() => (
              <TileCarouselExperiences
                invitationUuid={invitationUuid}
                enrollmentStore={enrollmentStore}
                productCollection={filteredProductCollection}
              />
            ))
            .getOrElse(() => (
              <NoExperiences searchValue={searchValue} />
            ))}
        </ProductCollectionDisplay>
      </ExperienceSection>
    </DiscoveryContext.Provider>
  );
}

export default observer(TileViewCarouselExperiences);
