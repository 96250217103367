import React, { HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import ThemeContext, { LightnessTheme } from '../../../Themes/ThemeContext';
import { BadgeBackgroundColor, BadgeShape } from './Types';

interface Props extends HTMLAttributes<HTMLDivElement> {
  color?: BadgeBackgroundColor;
  shape?: BadgeShape;
}

const getBadgeBackgroundColorValue = (
  theme: LightnessTheme,
  color: BadgeBackgroundColor
): string => {
  return theme.colors[`badge-${color}-bg-color`];
};

const Badge: React.FC<Props> = ({
  className,
  style,
  children,
  color = 'gray',
  shape = 'rounded-sm',
  ...others
}) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <div
          className={twMerge(clsx('flex items-center', shape, className))}
          style={{ backgroundColor: getBadgeBackgroundColorValue(theme, color), ...style }}
          {...others}
        >
          {children}
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default observer(Badge);
