import * as React from 'react';
import { observer } from 'mobx-react';
import { when } from '@execonline-inc/maybe-adapter';

import EnabledButton from './EnabledButton';
import DisabledButton from './DisabledButton';
import SegmentStore from '../../../../../SegmentStore';
import { Maybe } from 'maybeasy';
import { TPlainTextKey } from '../../../../../Translations';

interface Props {
  segmentStore: SegmentStore;
  submit: () => void;
  btnStyle: string;
  btnIcon: Maybe<JSX.Element>;
  text: TPlainTextKey;
}

class ScheduleSessionButton extends React.Component<Props> {
  render() {
    const { submit, btnStyle, btnIcon, text } = this.props;
    return when(this.props.segmentStore.sessionScheduled, {})
      .map(() => (
        <EnabledButton text={text} submit={submit} btnStyle={btnStyle} btnIcon={btnIcon} />
      ))
      .getOrElse(() => <DisabledButton text={text} btnStyle={btnStyle} btnIcon={btnIcon} />);
  }
}

export default observer(ScheduleSessionButton);
