import { observer } from 'mobx-react';
import EnrollmentStore from '../../DiscoveryPortal/Enrollment/Store';
import ExperiencesStore from '../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { ExperienceResource } from '../../Common/Experience/Types';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import ExperienceDetailViewStore from '../ExperienceDetailViewStore';
import WithCurrentUser from '../../../../CurrentUser/Context/WithCurrentUser';
import Loading from '../../../../components/Loading';
import Hero from './Hero';
import ErrorMessage from '../../../ErrorMessage';
import { getHeroSectionBackgroundColor } from './Types';

interface Props {
  invitationUuid: string;
  experienceDetailViewStore: ExperienceDetailViewStore;
  experienceResource: ExperienceResource;
  experiencesStore: ExperiencesStore;
  enrollmentStore: EnrollmentStore;
  registerResource: RegisterResource;
}

function HeroSection({
  invitationUuid,
  experienceDetailViewStore,
  experienceResource,
  experiencesStore,
  enrollmentStore,
  registerResource,
}: Props) {
  return (
    <WithCurrentUser
      children={(currentUserResource) => {
        switch (experiencesStore.state.kind) {
          case 'waiting':
          case 'loading':
            return (
              <div className="bg-white">
                <div className="mx-auto xl:container">
                  <Loading />
                </div>
              </div>
            );
          case 'experiences':
          case 'product-collections':
          case 'product-collections-and-experiences':
          case 'searching':
            return (
              <div className={getHeroSectionBackgroundColor(experienceResource)}>
                <Hero
                  invitationUuid={invitationUuid}
                  experienceResource={experienceResource}
                  enrollmentStore={enrollmentStore}
                  registerResource={registerResource}
                  experiencesStore={experiencesStore}
                  currentUserResource={currentUserResource}
                />
              </div>
            );
          case 'error':
            return (
              <div className="bg-white">
                <div className="mx-auto xl:container">
                  <ErrorMessage message="Unable to load your experience" />
                </div>
              </div>
            );
        }
      }}
    />
  );
}

export default observer(HeroSection);
