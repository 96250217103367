import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import Typography from '../Typography';
import { TypographyProps } from '../Typography/Types';

interface Props extends TypographyProps {}

const SectionTitle: React.FC<Props> = ({ className, children, ...others }) => {
  return (
    <Typography
      className={twMerge(clsx('normal-case !tracking-normal', className))}
      fontWeight="bold"
      variant="h4"
      component="h5"
      {...others}
    >
      {children}
    </Typography>
  );
};

export default observer(SectionTitle);
