import { observer } from 'mobx-react';
import * as React from 'react';
import { handleEnterKeyPress } from '../../../../../Accessibility/KeyboardEvents';
import FormField from '../../../../Form/FormField';
import TextField from '../../../../Form/TextField';
import SetPasswordStore from '../../SetPasswordStore';

interface Props {
  store: SetPasswordStore;
}

const ConfirmPasswordField: React.FC<Props> = ({ store }) => (
  <FormField label="Confirm Password">
    <TextField
      type="password"
      value={store.passwordConfirmation.getOrElseValue('')}
      onKeyDown={handleEnterKeyPress(store.creating)}
      onChange={(e) => store.setPasswordConfirmation(e.target.value)}
    />
  </FormField>
);

export default observer(ConfirmPasswordField);
