import { observer } from 'mobx-react';
import { cn } from '@execonline-inc/execonline-ui';
import { TileViewProgramExperience } from '../../../../../../Common/ProgramExperience';
import { useExperiencesContext } from '../../../ExperiencesContext';
import { ShowExperienceItemProps } from './Types';

function TileViewProgramExperienceSwitcher({
  invitationUuid,
  experience,
  enrollmentStore,
  productCollections,
  className,
}: ShowExperienceItemProps) {
  const experiencesStore = useExperiencesContext();
  return (
    <div className={cn('box-border flex w-full xl:min-w-[17rem] xl:max-w-[26.625rem]', className)}>
      <TileViewProgramExperience
        invitationUuid={invitationUuid}
        experienceResource={experience}
        experiencesStore={experiencesStore}
        enrollmentStore={enrollmentStore}
      />
    </div>
  );
}

export default observer(TileViewProgramExperienceSwitcher);
