import { action, observable } from 'mobx';
import { Link } from '../../../../Resource/Types';
import { error, Error } from '../../../../ErrorHandling';
import { TPlainTextKey } from '../../../../Translations';
import { Loading, NoPromotables, PromotablesResource, Ready } from './Types';

const loading = (): Loading => ({
  kind: 'loading',
});

const ready = (promotablesResource: PromotablesResource): Ready => ({
  kind: 'ready',
  promotablesResource,
});

const noPromotables = (): NoPromotables => ({
  kind: 'no-promotables',
});

export type State = Loading | Ready | NoPromotables | Error;

class PromotablesStore {
  @observable
  state: State;
  link: Link;

  constructor(link: Link) {
    this.link = link;
    this.state = loading();
  }

  @action
  ready = (promotablesResource: PromotablesResource) => {
    this.state = ready(promotablesResource);
  };

  @action
  noPromotables = () => {
    switch (this.state.kind) {
      case 'loading': {
        this.state = noPromotables();
        break;
      }
      case 'error':
      case 'no-promotables':
      case 'ready': {
        break;
      }
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };
}

export default PromotablesStore;
