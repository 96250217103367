import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../../Translations';
import TimeCommitments from './TimeCommitments';
import Certificate from './Certificate';
import WhoShouldAttend from './WhoShouldAttend';
import { ExternalProgramResource } from '../../../../../SegmentStore/Types';
import { emptyFragment } from '../../../../../EmptyFragment';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const AtAGlanceSection: React.FC<Props> = ({ externalProgramResource }) => (
  <div>
    <div className="font-light text-3xl mb-8">
      <T kind="At a Glance" />
    </div>
    <div className="flex justify-between">
      {externalProgramResource.payload.whoShouldAttendHtml
        .map((whoShouldAttendHtml) => <WhoShouldAttend whoShouldAttendHtml={whoShouldAttendHtml} />)
        .getOrElse(emptyFragment)}
      {externalProgramResource.payload.timeCommitment
        .map((timeCommitment) => <TimeCommitments timeCommitment={timeCommitment} />)
        .getOrElse(emptyFragment)}
      <Certificate externalProgramResource={externalProgramResource} />
    </div>
  </div>
);

export default observer(AtAGlanceSection);
