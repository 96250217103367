import { observer } from 'mobx-react';
import clsx from 'clsx';
import { BrowserShieldIcon, CircleProgressIcon } from '../../../../../../components/UI';

const PaymentProcesing = () => {
  return (
    <div className="relative flex items-center">
      <CircleProgressIcon
        className={clsx('h-[160px] w-[160px]', 'animate-[spin_2s_linear_infinite] origin-center')}
        color="secondary"
      />
      <BrowserShieldIcon
        className={clsx(
          'absolute h-[60px] w-[60px] left-1/2  top-1/2',
          '-translate-x-1/2 -translate-y-1/3 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100'
        )}
        color="secondary"
      />
    </div>
  );
};

export default observer(PaymentProcesing);
