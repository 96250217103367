import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../../../Common/Experience/Types';
import AlreadyEnrolledButton from './AlreadyEnrolledButton';
import GetStartedButton from './GetStartedButton';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceActions({ experienceResource }: Props) {
  switch (experienceResource.payload.kind) {
    case 'enrollable':
    case 'commerce-enrollable':
      return <GetStartedButton />;
    case 'beginnable':
      return <AlreadyEnrolledButton buttonText="Begin Your Experience" />;
    case 'resumable':
      return <AlreadyEnrolledButton buttonText="Resume Your Experience" />;
    case 'returnable':
      return <AlreadyEnrolledButton buttonText="Return to Experience" />;
    case 'not-enrollable':
    case 'not-licensed':
    case 'graduated':
    case 'upcoming':
      return emptyFragment();
  }
}
export default observer(ExperienceActions);
