import { Typography } from '@execonline-inc/execonline-ui';
import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import Loading from 'react-loading';
import { emptyFragment } from '../../EmptyFragment';
import { T, TPlainTextKey } from '../../Translations';
import { Props } from './Types';

function LoaderMessage({ text }: { text: Maybe<TPlainTextKey> }) {
  return text
    .map((t) => (
      <Typography color="black">
        <T kind={t} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

function Loader({
  type = 'bubbles',
  color = 'black',
  text = just('loading content'),
  height = 100,
  width = 100,
  delay = 1000,
}: Props) {
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
      <Loading type={type} color={color} height={height} width={width} delay={delay} />
      <LoaderMessage text={text} />
    </div>
  );
}

export default observer(Loader);
