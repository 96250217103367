import * as React from 'react';
import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import * as style from './style.module.css';
import { NotTranslated } from '@execonline-inc/translations';

interface Props {
  label: Maybe<string>;
}

const Label: React.FC<Props> = ({ label }) => {
  return label
    .map((l) => (
      <label className={style.label}>
        <NotTranslated text={l} />
      </label>
    ))
    .getOrElseValue(<></>);
};

export default observer(Label);
