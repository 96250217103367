import { observer } from 'mobx-react';
import { just } from 'maybeasy';
import { cn } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../../Common/Experience/Types';
import ExperiencesStore from '../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import {
  EnrollmentCard,
  ExperienceActions,
  ExperienceCompetencies,
  ExperienceDescription,
  ExperienceTitle,
  ExperiencesLogo,
  LanguageAvailability,
} from '../../Common';
import ExperienceVideo from '../../Common/ExperienceVideo';
import ExperienceOverview from './ExperienceOverview';

interface Props {
  experienceResource: ExperienceResource;
  experiencesStore: ExperiencesStore;
}

function LearnMoreProgramHero({ experienceResource, experiencesStore }: Props) {
  return (
    <>
      <div className="flex w-full flex-col gap-x-28 lg:flex-row">
        <div className="w-full lg:w-3/5">
          <ExperiencesLogo experienceResource={experienceResource} />
          <ExperienceTitle className="text-black" title={just(experienceResource.payload.title)} />
          <ExperienceCompetencies
            className="mb-4 sm:mb-7"
            competencies={experienceResource.payload.primaryCompetencies}
          />
          <ExperienceDescription
            className="mb-10 sm:mb-12"
            description={experienceResource.payload.publicDetailedDescriptionHtml}
          />
          <LanguageAvailability
            className="mb-4 text-gray-600 sm:mb-8"
            experienceResource={experienceResource}
          />
          <ExperienceOverview experienceResource={experienceResource} />
        </div>
        <div
          className={cn(
            'flex flex-col items-center',
            'mx-auto md:gap-x-4 lg:flex-col lg:justify-normal lg:gap-x-0 lg:gap-y-6',
            'empty:hidden',
          )}
        >
          <ExperienceVideo experiencesStore={experiencesStore} />
          <EnrollmentCard className="mt-10 lg:mt-0" experienceResource={experienceResource} />
        </div>
      </div>
      <ExperienceActions experienceResource={experienceResource} />
    </>
  );
}

export default observer(LearnMoreProgramHero);
