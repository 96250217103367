import React, { HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface Props extends HTMLAttributes<HTMLDivElement> {}

const CardHeader: React.FC<Props> = ({ className, children, ...others }) => {
  return (
    <div className={twMerge(clsx('mb-3', className))} {...others}>
      {children}
    </div>
  );
};

export default observer(CardHeader);
