import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { emptyFragment } from '../../EmptyFragment';
import Anchor from '../../LinkyLinky/Anchor';
import { Link } from '../../Resource/Types';
import { T } from '../../Translations';
import DownloadIcon from '../Cutesies/DownloadIcon';
import * as style from './style.module.css';

interface Props {
  link: Maybe<Link>;
}

const DownloadButton: React.FC<Props> = ({ link }) =>
  link
    .map((l) => (
      <Anchor className={style.downloadButton} href={l.href} data-test-download-button>
        <T kind="Download File" />
        <div className={style.buttonIcon}>
          <DownloadIcon />
        </div>
      </Anchor>
    ))
    .getOrElse(emptyFragment);

export default observer(DownloadButton);
