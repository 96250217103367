import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import NotificationSource from '../../NotificationSource';
import Notifications from '../../Notifications';
import ResetPasswordStore from '../../ResetPasswordStore';
import ResetPasswordReactions from '../../ResetPasswordStore/ResetPasswordReactions';
import ThemeContext, { currentGlobalTheme } from '../../Themes/ThemeContext';
import { T } from '../../Translations';
import CenteredLogo from '../CenteredLogo';
import WithBackgroundImage from '../WithBackgroundImage';
import Form from './Form';
import * as style from './style.module.css';

@observer
class ResetPassword extends React.Component<{}, {}> {
  private resetPasswordStore: ResetPasswordStore;

  constructor(props: {}) {
    super(props);
    this.resetPasswordStore = new ResetPasswordStore();
  }

  componentDidMount() {
    this.resetPasswordStore.loading();
  }

  render() {
    return (
      <ThemeContext.Provider value={currentGlobalTheme()}>
        <ThemeContext.Consumer>
          {(theme) => (
            <>
              <WithBackgroundImage>
                <Main>
                  <CenteredLogo />
                  <Notifications />
                  <h1
                    className={clsx(style.title, 'mb-8 text-xl text-neutral-900')}
                    data-test-title={true}
                  >
                    <T kind="Reset Your Password" />
                  </h1>
                  <Form store={this.resetPasswordStore} />
                </Main>
              </WithBackgroundImage>
              <ResetPasswordReactions store={this.resetPasswordStore} />
              <NotificationSource store={this.resetPasswordStore} />
            </>
          )}
        </ThemeContext.Consumer>
      </ThemeContext.Provider>
    );
  }
}

export default ResetPassword;
