import { observer } from 'mobx-react';
import { ExperienceResource, GroupCoachingProductDetails } from '../../../Common/Experience/Types';
import { ExperienceTitle, ExperiencesLogo } from '../../Common';
import ExperienceActions from './ExperienceActions';
import ExperienceDescription from './ExperienceDescription';

interface Props {
  experienceResource: ExperienceResource;
  productDetails: GroupCoachingProductDetails;
}

function LearnMoreGroupCoachingHero({ experienceResource, productDetails }: Props) {
  const { title, description } = productDetails;

  return (
    <>
      <ExperiencesLogo experienceResource={experienceResource} />
      <ExperienceTitle className="text-white" title={title} />
      <ExperienceDescription description={description} />
      <ExperienceActions experienceResource={experienceResource} productDetails={productDetails} />
    </>
  );
}

export default observer(LearnMoreGroupCoachingHero);
