import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import BeginnableCardBody from './BeginnableCardBody';
import ResumableCardBody from './ResumableCardBody';
import GraduatedCardBody from './GraduatedCardBody';
import ReturnableCardBody from './ReturnableCardBody';
import { ExperienceResource } from '../../../../Common/Experience/Types';

interface Props {
  experienceResource: ExperienceResource;
}

function EnrollmentCardBody({ experienceResource }: Props) {
  switch (experienceResource.payload.kind) {
    case 'beginnable':
      return <BeginnableCardBody />;
    case 'resumable':
      return <ResumableCardBody />;
    case 'returnable':
      return <ReturnableCardBody experience={experienceResource.payload} />;
    case 'graduated':
      return <GraduatedCardBody experience={experienceResource.payload} />;
    case 'commerce-enrollable':
    case 'upcoming':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      return emptyFragment();
  }
}

export default observer(EnrollmentCardBody);
