import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { ReturnableExperience } from '../../../../Common/Experience/Types';
import { T } from '../../../../../../Translations';
import { dateToLocalDateTime } from '../../../HeroSection/CurrentlyEnrolledSection/ConvertDate';

interface Props {
  experience: ReturnableExperience;
}

function ReturnableCardBody({ experience }: Props) {
  return (
    <>
      <Typography className="tracking-widest text-[#5E5E5E]" variant="bodyMedium">
        <T kind="Graduation Date" />
      </Typography>
      <Typography className="pt-2.5 text-gray-700" variant="bodyLarge" fontWeight="medium">
        {dateToLocalDateTime(experience.courseCompletedAt)}
      </Typography>
    </>
  );
}

export default observer(ReturnableCardBody);
