import { observer } from 'mobx-react';
import * as React from 'react';
import SVGIcon from '../SVGIcon';

const ErrorIcon: React.FC = () => (
  <SVGIcon style={{ width: 160, height: 160 }} width="160" height="160" viewBox="0 0 164 164">
    <path
      fill="#D4453B"
      d="M82 15.375c-36.736 0-66.625 29.89-66.625 66.625 0 36.736 29.89 66.625 66.625 66.625 36.736 0 66.625-29.889 66.625-66.625 0-36.736-29.889-66.625-66.625-66.625Zm0 10.25c31.19 0 56.375 25.185 56.375 56.375S113.19 138.375 82 138.375 25.625 113.19 25.625 82 50.81 25.625 82 25.625ZM76.875 51.25V61.5h10.25V51.25h-10.25Zm0 20.5v41h10.25v-41h-10.25Z"
    />
  </SVGIcon>
);

export default observer(ErrorIcon);
