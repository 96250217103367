import React from 'react';
import {
  PopoverPlacement,
  PopoverReferenceReturnType,
  PopoverReferenceType,
} from '@execonline-inc/execonline-ui';
import { offset } from '@floating-ui/react';
import ExperiencesStore from '../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';

export interface PopoverProgramExperienceProps {
  experiencesStore: ExperiencesStore;
  children: (ref: PopoverReferenceType, props: PopoverReferenceReturnType) => React.ReactNode;
  placement?: PopoverPlacement;
  onClose: () => void;
}

/**
 * Centers the floating element vertically relative to the reference element
 * by setting the offset to half the combined heights of both elements.
 */
export const calculatePlacementOffset = (placement: string) => {
  switch (placement) {
    case 'top':
      return offset(({ rects }) => -rects.reference.height / 2 - rects.floating.height / 2);
    default:
      return offset(5);
  }
};
