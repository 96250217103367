import { emptyFragment } from '@execonline-inc/execonline-ui';
import { noop } from '@kofno/piper';
import { fromEmpty, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Link } from '../../../../../../Resource/Types';
import SegmentStore from '../../../../../../SegmentStore';
import { SegmentResource } from '../../../../../../SegmentStore/Types';
import { AutoLaunchableStore } from '../../../../../AutoLaunchable/AutoLaunchableStore';
import Progress from '../../../../../Progress';
import SegmentDetail from '../../../../../Segment/SegmentContent/SegmentDetail';
import * as style from '../style.module.css';

interface Props {
  longTitle: string;
  store: SegmentStore;
  segmentResource: SegmentResource;
  autoLaunchableStore: Maybe<AutoLaunchableStore>;
}

function DefaultSegmentContent({ store, longTitle, segmentResource, autoLaunchableStore }: Props) {
  const [autoLaunchableLinks, setAutoLaunchableLinks] = useState<ReadonlyArray<Link>>([]);

  useEffect(() => {
    autoLaunchableStore
      .map((store) => {
        switch (store.state.kind) {
          case 'loading':
          case 'nothing-to-launch':
          case 'error':
            break;
          case 'ready':
            setAutoLaunchableLinks(store.state.autoLaunchableResource.links);
            break;
        }
      })
      .getOrElse(() => noop);
  }, [autoLaunchableStore]);

  return (
    <>
      {fromEmpty(autoLaunchableLinks)
        .map((links) => <Progress links={links} />)
        .getOrElse(emptyFragment)}
      <h2 className={style.subheading}>{longTitle}</h2>
      <div className={style.content}>
        <SegmentDetail segmentResource={segmentResource} store={store} />
      </div>
    </>
  );
}

export default observer(DefaultSegmentContent);
