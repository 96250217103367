import { AlreadyTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import * as React from 'react';
import { UseCaseExplorePayload } from '../../../../../DiscoveryStore/Types';
import { T } from '../../../../../Translations';
import EnrollmentStatus from './EnrollmentStatus';
import RegistrationType from './RegistrationType';

interface Props {
  data: UseCaseExplorePayload;
}

const UseCaseInfo: React.FC<Props> = ({ data }) => (
  <div className="truncate" style={{ maxWidth: 450 }}>
    <div className={'flex text-left text-sm font-medium leading-6'}>
      <AlreadyTranslated content={data.name} />
    </div>
    <div className={'flex min-w-max'}>
      <div className="text-left text-2xs font-normal leading-5 text-gray-500">
        <T kind="{{count}} Program" count={data.programsCount} />
      </div>
      <div className="text-left text-2xs font-normal leading-5 text-gray-500">
        <EnrollmentStatus status={data.enrollmentStatus} />
      </div>
      <RegistrationType registrationType={data.registrationType} />
    </div>
  </div>
);

export default observer(UseCaseInfo);
