export type MediaQueryBreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type MediaQuery = {
  breakPoint: MediaQueryBreakPoint;
  media: string;
  matches: boolean;
};

export const defaultMediaQueries: ReadonlyArray<MediaQuery> = [
  { breakPoint: 'xs', media: '(min-width: 0px)', matches: false },
  { breakPoint: 'sm', media: '(min-width: 640px)', matches: false },
  { breakPoint: 'md', media: '(min-width: 768px)', matches: false },
  { breakPoint: 'lg', media: '(min-width: 1024px)', matches: false },
  { breakPoint: 'xl', media: '(min-width: 1280px)', matches: false },
];

interface BaseState {
  queries: MediaQuery[];
}

interface Initializing extends BaseState {
  kind: 'initializing';
}

interface Updating extends BaseState {
  kind: 'updating';
}

export type State = Initializing | Updating;

export const initializing = (queries: MediaQuery[]): Initializing => ({
  kind: 'initializing',
  queries,
});

export const updating = (queries: MediaQuery[]): Updating => ({
  kind: 'updating',
  queries,
});
