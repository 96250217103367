import { useRef } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { useOpenable } from '../../Openable';
import ArrowDownIcon from '../../Cutesies/ArrowDownIcon';
import Typography from '../Typography';
import DropdownMenu from './DropdownMenu';
import { DropdownProps } from './Types';
import { handleEnterOrSpaceKeyPress } from '../../../Accessibility/KeyboardEvents';
import { fromNullable } from 'maybeasy';
import { assertNever } from '@kofno/piper';
import DropdownChildrenItem from './DropdownChildrenItem';

const getDisplayValue = (props: DropdownProps) => {
  const { multiple, value, renderValue } = props;

  switch (multiple) {
    case true:
      return fromNullable(renderValue)
        .map((renderValue) => renderValue(value))
        .getOrElseValue(value);
    case false:
      return fromNullable(renderValue)
        .map((renderValue) => renderValue(value))
        .getOrElseValue(value);
    default:
      return value;
  }
};

const Dropdown = ({
  children,
  placeholder,
  disabled,
  label,
  className,
  ...others
}: DropdownProps) => {
  const rootElementRef = useRef<HTMLDivElement>();
  const { openableState, open, close } = useOpenable();

  const handleElementRef = (element: HTMLDivElement): HTMLDivElement =>
    (rootElementRef.current = element);

  const handleClick = () => {
    switch (openableState.kind) {
      case 'closed':
        return open();
      case 'open':
        return close();
      default:
        return assertNever(openableState);
    }
  };

  const displayValue = getDisplayValue(others);

  const isOpenMethod = () => {
    switch (openableState.kind) {
      case 'closed':
        return false;
      case 'open':
        return true;
      default:
        return assertNever(openableState);
    }
  };

  return (
    <div className={clsx('relative', { 'pointer-events-none opacity-50': disabled }, className)}>
      <Typography
        className="!text-font-size-2xs tracking-wider uppercase mb-1.5"
        variant="caption"
        component="label"
        fontWeight="medium"
      >
        {label}
      </Typography>
      <div
        ref={handleElementRef}
        tabIndex={0}
        className={clsx(
          'relative inline-flex items-center w-full cursor-pointer box-border',
          'border border-solid border-[#d3d3d3] p-2 pr-7 bg-white',
        )}
        onClick={handleClick}
        onKeyDown={handleEnterOrSpaceKeyPress(handleClick)}
      >
        <span className="text-black whitespace-nowrap text-ellipsis overflow-hidden w-full select-none">
          {fromNullable(displayValue)
            .map(() => displayValue)
            .getOrElse(() => placeholder)}
        </span>
        <div
          className={clsx(
            'absolute flex flex-shrink-0 items-center w-4',
            'right-2 select-none pointer-events-none',
            'transition-transform',
            {
              'translate-x-0 translate-y-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 rotate-180':
                openableState.kind === 'open',
            },
          )}
        >
          <ArrowDownIcon color="icon-secondary-color" />
        </div>
      </div>
      <DropdownMenu anchorElement={rootElementRef.current} isOpen={isOpenMethod()} onClose={close}>
        <DropdownChildrenItem children={children} {...others} />
      </DropdownMenu>
    </div>
  );
};

export default observer(Dropdown);
