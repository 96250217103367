import { action, observable } from 'mobx';
import { when } from '@execonline-inc/maybe-adapter';
import { equals } from '@execonline-inc/collections';
import { State, defaultMediaQueries, initializing, updating } from './Types';

class MediaQueryStore {
  @observable
  state: State;

  constructor() {
    const queries = defaultMediaQueries.map(({ breakPoint, media }) => ({
      breakPoint,
      matches: window.matchMedia(media).matches,
      media: media,
    }));

    this.state = initializing(queries);
  }

  queries = (state: State, event: MediaQueryListEvent) =>
    state.queries.map((item) => {
      return when(equals(item.media, event.media), {})
        .map(() => ({ ...item, matches: event.matches }))
        .getOrElseValue(item);
    });

  @action
  update = (event: MediaQueryListEvent): void => {
    this.state = updating(this.queries(this.state, event));
  };
}

export default MediaQueryStore;
