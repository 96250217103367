import { assertNever } from '@kofno/piper';
import cssVars from 'css-vars-ponyfill';
import { nothing } from 'maybeasy';
import { Task } from 'taskarian';
import ResetPasswordStore, { missingEmailErrorMessage } from '.';
import { AppyError, postToApi } from '../Appy';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import { rootResourceStore } from '../RootResourceStore';
import { RootResourceError } from '../RootResourceStore/Types';
import { defaultTheme } from '../Themes';
import { TPlainTextKey } from '../Translations';
import { ResetPasswordState } from './Types';

export interface Props extends EAProps<ResetPasswordStore> {
  store: ResetPasswordStore;
}

export const handleAppyError = (error: AppyError): TPlainTextKey => {
  switch (error.kind) {
    case 'missing-application-id':
      return 'You are missing the application id';
    case 'missing-api-compatibility':
      return 'You are missing the API compatibility version';
    default:
      return 'Unable to reset password';
  }
};

const handleError = (store: ResetPasswordStore, error: AppyError) => {
  store.error(handleAppyError(error));
};

const handleResetPasswordRequestError =
  (store: ResetPasswordStore) => (error: RootResourceError) => {
    switch (error.kind) {
      case 'missing-link-error':
        store.error('Error resetting password');
        break;
      case 'bad-status':
        error.response.status == 422 ? store.requested() : handleError(store, error);
        break;
      case 'bad-payload':
      case 'bad-url':
      case 'missing-application-id':
      case 'missing-api-compatibility':
      case 'network-error':
      case 'timeout':
        handleError(store, error);
        break;
      default:
        assertNever(error);
    }
  };

const sendPasswordReset = (email: string): Task<RootResourceError, string> =>
  rootResourceStore
    .findLinkT('reset-password')
    .andThen(postToApi({ password_request_origin: 'p3', user: { email } }));

class ResetPasswordReactions extends ErrorActionableReaction<
  ResetPasswordStore,
  ResetPasswordState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: ResetPasswordState) => {
    cssVars({ variables: defaultTheme.variables });
    switch (state.kind) {
      case 'loading':
        this.props.store.ready(nothing());
        break;
      case 'creating':
        state.email.map(sendPasswordReset).cata({
          Just: (task) => {
            task.fork(
              handleResetPasswordRequestError(this.props.store),
              this.props.store.requested,
            );
          },
          Nothing: () => {
            this.props.store.error(missingEmailErrorMessage);
          },
        });

        break;
      case 'requested':
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ResetPasswordReactions;
