import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { Typography, cn, emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';

interface Props {
  title: React.ReactElement;
  description: Maybe<AlreadyTranslatedText>;
  className?: string;
}

function ExperienceSection({ title, description, children, className }: PropsWithChildren<Props>) {
  return (
    <div
      data-testid="learning-collection-experience-section"
      className={cn('mx-auto flex flex-col px-0 py-10 xl:container', className)}
    >
      <div data-testid="learning-collection-experience-header" className="px-6 md:px-16">
        <Typography className="!p-0 tracking-normal text-black" variant="h5" as="h2">
          {title}
        </Typography>
        {description
          .map((description) => (
            <Typography
              className="mt-4 !text-sm leading-normal text-black sm:!text-lg"
              variant="bodyLarge"
              as="p"
            >
              <AlreadyTranslated content={description} />
            </Typography>
          ))
          .getOrElse(emptyFragment)}
      </div>
      <div data-testid="learning-collection-experience-body" className="px-6 md:px-16">
        {children}
      </div>
    </div>
  );
}

export default observer(ExperienceSection);
