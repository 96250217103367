import { observer } from 'mobx-react';
import { Button } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';
import { useMediaQuery } from '../../../../../../../components/UI';
import { getButtonSize } from './Types';

function NoAvailabilityButton() {
  const breakPoint = useMediaQuery();

  return (
    <Button
      className="min-w-full sm:min-w-48"
      color="gray"
      radius="md"
      size={getButtonSize(breakPoint)}
      disabled
    >
      <T kind="No availability" />
    </Button>
  );
}

export default observer(NoAvailabilityButton);
