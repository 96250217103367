import * as React from 'react';
import { T } from '../../../Translations';
import LogoGraphic from '../../Cutesies/LogoGraphic';
import * as style from '../style.module.css';

const TechCheckOverview: React.FC = () => {
  return (
    <>
      <div className={style.logo}>
        <LogoGraphic />
      </div>
      <h1>
        <T kind="Let's do a Quick Tech Check" />
      </h1>
      <p>
        <T kind="Let's make sure you can schedule a test session and that ..." />
      </p>
      <ol className={style.orderedList}>
        <li>
          <T kind="Test whether your system can schedule a test session" />
        </li>
        <li>
          <T kind="Confirm your date and time settings" />
        </li>
        <li>
          <T kind="Check your browser's ability to run availability and scheduling features" />
        </li>
      </ol>
    </>
  );
};

export default TechCheckOverview;
