import * as React from 'react';
import { observer } from 'mobx-react';
import { TranslationsContext } from '@execonline-inc/translations';
import { TPlainTextKey, T, translation } from '../../../../../Translations';
import { Maybe } from 'maybeasy';
import { emptyFragment } from '@execonline-inc/execonline-ui';

interface Props {
  text: TPlainTextKey;
  submit: () => void;
  btnStyle: string;
  btnIcon: Maybe<JSX.Element>;
}

const EnabledButton: React.FC<Props> = ({ text, submit, btnStyle, btnIcon }) => (
  <TranslationsContext.Consumer>
    {(ts) => (
      <button
        className={btnStyle}
        title={translation('Advance')(ts)}
        role="button"
        type="submit"
        onClick={() => submit()}
        data-test-submit-button={true}
      >
        <T kind={text} />
        {btnIcon.getOrElse(emptyFragment)}
      </button>
    )}
  </TranslationsContext.Consumer>
);

export default observer(EnabledButton);
