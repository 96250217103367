import { mergeObjectDecoders, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { date, field, number, oneOf, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { videoAssetResourceDecoder } from '../../VideoStore/Legacy/Decoders';
import {
  Attachment,
  AttachmentResource,
  DocumentParts,
  ResourceBase,
  ResourceParts,
  ResourceResource,
  VideoParts,
} from '../Types';

const attachmentDecoder: Decoder<Attachment> = succeed({}).assign('title', field('title', string));

export const attachmentResourceDecoder: Decoder<AttachmentResource> =
  resourceDecoder(attachmentDecoder);

export const baseResourceDecoder: Decoder<ResourceBase> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', string))
  .assign('createdAt', field('created_at', date));

export const documentPartsDecoder: Decoder<DocumentParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('document')))
  .assign('attachment', field('attachment', attachmentResourceDecoder));

const videoPartsDecoder: Decoder<VideoParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('video')))
  .assign('videoAssetResource', field('video_asset_resource', videoAssetResourceDecoder));

export const resourcePartsDecoder: Decoder<ResourceParts> = oneOf<ResourceParts>([
  documentPartsDecoder.map<ResourceParts>(identity),
  videoPartsDecoder.map<ResourceParts>(identity),
]);

export const resourceItemDecoder = mergeObjectDecoders(baseResourceDecoder, resourcePartsDecoder);

export const resourceResourceDecoder: Decoder<ResourceResource> =
  resourceDecoder(resourceItemDecoder);
