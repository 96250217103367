import { observer } from 'mobx-react';
import { cn } from '@execonline-inc/execonline-ui';
import { SchoolPartnerLogo, SchoolPartnerResource } from '../Experience/SchoolPartner';

interface Props {
  className?: string;
  data: SchoolPartnerResource;
  height: number;
}

function ExperienceViewLogo({ data, height, className }: Props) {
  return (
    <div
      className={cn(
        'box-border flex h-full w-full items-center justify-center bg-[#e1e1e1] p-2',
        className,
      )}
    >
      <SchoolPartnerLogo logoLinkType="logo-on-white" data={data} height={height} />
    </div>
  );
}

export default observer(ExperienceViewLogo);
