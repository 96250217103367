import { observer } from 'mobx-react';
import * as React from 'react';
import ThemeContext from '../../../../Themes/ThemeContext';
import Confirm from './Confirm';
import PaymentConfirmationStore from './Store';

interface Props {
  store: PaymentConfirmationStore;
}

const ConfirmWrapper: React.FC<Props> = ({ store }) => (
  <ThemeContext.Consumer>
    {({ colors }) => (
      <div
        style={{ backgroundColor: colors['bg-primary-color'] }}
        className="flex justify-center min-w-[100vw] min-h-[100vh]"
      >
        <Confirm store={store} />
      </div>
    )}
  </ThemeContext.Consumer>
);

export default observer(ConfirmWrapper);
