import { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { ScrollBox } from '@execonline-inc/execonline-ui';
import { ExperienceCarouselProps } from '../Types';

interface Props<T> {
  items: ReadonlyArray<T>;
  children: (props: ExperienceCarouselProps<T>) => ReactNode;
}

function MobileExperienceList<T>({ items, children }: Props<T>) {
  return (
    <ScrollBox className="-mx-2.5 -mb-2.5 flex pb-2.5 pt-3" orientation="horizontal">
      <div className="flex flex-shrink-0 gap-x-4 px-2.5">
        {children({ className: 'w-[19.75rem] flex-shrink-0', items })}
      </div>
    </ScrollBox>
  );
}

export default observer(MobileExperienceList);
