import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../Translations';
import { Typography } from '../../../../components/UI';
import { emptyFragment } from '../../../../EmptyFragment';

interface Props {
  paymentIntentId: Maybe<string>;
}

const TransactionNumber: React.FC<Props> = ({ paymentIntentId }) =>
  paymentIntentId
    .map((id) => (
      <Typography className="!text-gray-60" variant="body2">
        <T kind="Transaction number: {{id}}" id={id} />
      </Typography>
    ))
    .getOrElse(emptyFragment);

export default observer(TransactionNumber);
