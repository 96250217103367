import { observer } from 'mobx-react';
import { ExperienceResource } from '../../../Common/Experience/Types';
import { OverView, WhatYouWillLearn, WhoShouldAttend, CompetenciesYouWillGain } from '../../Common';

interface Props {
  experienceResource: ExperienceResource;
}

function LearnMoreProgramDetails({ experienceResource }: Props) {
  return (
    <div className="box-border flex flex-col gap-y-12 bg-white px-4 py-12 sm:p-12 md:gap-y-14">
      <OverView experienceResource={experienceResource} />
      <div className="flex flex-col gap-6 xl:flex-row">
        <div className="flex basis-8/12">
          <WhatYouWillLearn experienceResource={experienceResource} />
        </div>
        <div className="flex basis-4/12 flex-col gap-6 lg:flex-row xl:flex-col">
          <WhoShouldAttend experienceResource={experienceResource} />
          <CompetenciesYouWillGain experienceResource={experienceResource} />
        </div>
      </div>
    </div>
  );
}

export default observer(LearnMoreProgramDetails);
