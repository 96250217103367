import { Maybe, just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AnyAdvancer, ConfirmationModal } from '../../../../../SegmentStore/Types';
import { TPlainTextKey } from '../../../../../Translations';
import AdvanceOnlyButton from '../AdvanceOnlyButton';
import CompleteAndAdvanceButton from '../CompleteAndAdvanceButton';
import ConfirmCompleteAndAdvanceButton from '../ConfirmCompleteAndAdvanceButton';
import DisabledAdvanceButton from '../DisabledAdvanceButton';
import SubmitAndAdvanceButton from '../SubmitAndAdvanceButton';
import SegmentStore from '../../../../../SegmentStore';
import * as style from '../../../style.module.css';
import ArrowRightIcon from '../../../../Cutesies/ArrowRightIcon';

interface Props {
  advancer: AnyAdvancer;
  text: TPlainTextKey;
  confirmationModal: Maybe<ConfirmationModal>;
  store: SegmentStore;
}

const ReadyButton: React.FC<Props> = ({
  advancer,
  text = 'Next Segment',
  confirmationModal,
  store,
}) => {
  switch (advancer.kind) {
    case 'advancer':
      return <AdvanceOnlyButton advancer={advancer} text={text} />;
    case 'complete-and-advancer':
      return confirmationModal
        .map((m) => (
          <ConfirmCompleteAndAdvanceButton
            completeAndAdvancer={advancer}
            text={text}
            confirmationModal={m}
          />
        ))
        .getOrElse(() => <CompleteAndAdvanceButton completeAndAdvancer={advancer} text={text} />);
    case 'submit-and-advancer':
      return (
        <SubmitAndAdvanceButton segmentStore={store} btnStyle={style.nextButton} text={text}>
          {{
            btnIcon: just(
              <div className={style.buttonIcon}>
                <ArrowRightIcon />
              </div>,
            ),
          }}
        </SubmitAndAdvanceButton>
      );
    case 'disabled-advancer':
      return <DisabledAdvanceButton text={text} />;
    case 'no-advancer':
      return <div />;
  }
};

export default observer(ReadyButton);
