import { ReactNode } from 'react';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { cn } from '@execonline-inc/execonline-ui';
import { CarouselPage, pageContentsArray } from '../../../../../../Common/Experience/Carousel';
import { ExperienceCarouselProps } from '../Types';

interface Props<T> {
  page: Maybe<CarouselPage<T>>;
  perPageCount: number;
  children: (props: ExperienceCarouselProps<T>) => ReactNode;
}

function getContainerClassName(count: number) {
  return cn('grid w-full grid-cols-3 gap-x-4', {
    'grid-cols-4': count === 4,
  });
}

function ExperienceList<T>({ page, perPageCount, children }: Props<T>) {
  return (
    <div className={getContainerClassName(perPageCount)}>
      {page
        .map(pageContentsArray)
        .map((items) => children({ className: 'sm:max-w-full', items }))
        .getOrElseValue([])}
    </div>
  );
}

export default observer(ExperienceList);
