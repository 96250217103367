import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { nothing } from 'maybeasy';
import { CurrentUserResource } from '../../../../../CurrentUser/Types';
import NotSupportedInLanguageStore from '../../../../../NotSupportedInLanguageStore';
import { ExperienceContextProvider } from '../../../Common/Experience/ExperienceContext';
import { ExperienceResource } from '../../../Common/Experience/Types';
import {
  EnrollmentModalContextOptions,
  ExperienceEnrollmentContextProvider,
} from '../../../Common/ProgramExperienceEnrollment';
import EnrollmentStore from '../../../DiscoveryPortal/Enrollment/Store';
import ExperiencesStore from '../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { RegisterResource } from '../../../DiscoveryPortal/RegisterResourceStore/Types';
import ExperienceNotSupportedInLanguageMessage from '../ExperienceNotSupportedInLanguageMessage';
import HeroBody from '../HeroBody';
import HeroBackground from './HeroBackground';
import HeroBreadcrumbs from './HeroBreadcrumbs';

interface Props {
  experienceResource: ExperienceResource;
  invitationUuid: string;
  registerResource: RegisterResource;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
  currentUserResource: CurrentUserResource;
}

const options: EnrollmentModalContextOptions = {
  programFooterButtons: nothing(),
  scheduledExperienceOptions: nothing(),
};

function Hero({
  experienceResource,
  invitationUuid,
  enrollmentStore,
  experiencesStore,
  currentUserResource,
  registerResource,
}: Props) {
  useEffect(() => {
    enrollmentStore.settingUp(experiencesStore.useCase);
  }, [experiencesStore.useCase]);

  const experienceNotSupportedInLanguageStore = useMemo(
    () =>
      new NotSupportedInLanguageStore(
        experienceResource.payload.availableLanguages,
        currentUserResource,
      ),
    [experienceResource.payload.availableLanguages, currentUserResource],
  );

  return (
    <ExperienceEnrollmentContextProvider
      value={{
        invitationUuid,
        experienceResource,
        enrollmentStore,
        experiencesStore,
        options,
      }}
    >
      <ExperienceContextProvider value={{ invitationUuid, experienceResource }}>
        <div className="mx-auto xl:container">
          <HeroBackground experienceResource={experienceResource}>
            <div aria-live="polite">
              <ExperienceNotSupportedInLanguageMessage
                experienceNotSupportedInLanguageStore={experienceNotSupportedInLanguageStore}
              />
            </div>
            <div className="box-border w-full px-4 py-12 sm:px-12 sm:pb-16 sm:pt-8">
              <HeroBreadcrumbs
                invitationUuid={invitationUuid}
                experienceResource={experienceResource}
                registerResource={registerResource}
              />
              <HeroBody
                experiencesStore={experiencesStore}
                experienceResource={experienceResource}
              />
            </div>
          </HeroBackground>
        </div>
      </ExperienceContextProvider>
    </ExperienceEnrollmentContextProvider>
  );
}

export default observer(Hero);
