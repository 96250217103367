import { assertNever } from '@kofno/piper';
import { action, computed, observable } from 'mobx';
import SegmentStore from '../../../../../SegmentStore';
import { StringFormField } from '../../../Types';
import { autoSaving, initializing, ready, State, waiting } from './Types';

class StringFieldStore {
  @observable
  state: State = waiting();

  constructor(public formFieldId: string) {}

  @action
  initialize = (segmentStore: SegmentStore, formField: StringFormField) => {
    this.state = initializing(segmentStore, formField);
  };

  @action
  ready = (formField: StringFormField, value: string) => {
    this.state = ready(formField, value);
  };

  @action
  setValue = (value: string) => {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        this.state = ready(this.state.formField, value);
        break;
      case 'waiting':
      case 'initializing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  autoSave = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = autoSaving(this.state.formField, this.state.value);
        break;
      case 'auto-saving':
      case 'waiting':
      case 'initializing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  autoSaveComplete = () => {
    switch (this.state.kind) {
      case 'auto-saving':
        this.state = ready(this.state.formField, this.state.value);
        break;
      case 'ready':
      case 'waiting':
      case 'initializing':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get fieldValue(): string {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        return this.state.value;
      case 'waiting':
      case 'initializing':
        return '';
      default:
        assertNever(this.state);
    }
  }

  @computed
  get isRequired(): boolean {
    switch (this.state.kind) {
      case 'ready':
      case 'auto-saving':
        return this.state.formField.mode === 'required';
      case 'waiting':
      case 'initializing':
        return false;
      default:
        assertNever(this.state);
    }
  }
}

export default StringFieldStore;
