import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../../Common/Experience/Types';
import { useExperienceEnrollmentContext } from '../../../Common/ProgramExperienceEnrollment';
import EnrollableAction from './EnrollableAction';
import NotEnrollableAction from './NotEnrollableAction';
import UpcomingAction from './UpcomingAction';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceActions({ experienceResource }: Props) {
  const { kind, availabilities } = experienceResource.payload;
  const { onOpen } = useExperienceEnrollmentContext();

  switch (kind) {
    case 'commerce-enrollable':
    case 'enrollable':
      return (
        <EnrollableAction
          availabilities={availabilities}
          experience={experienceResource.payload}
          onClick={onOpen}
        />
      );
    case 'not-enrollable':
      return <NotEnrollableAction className="mt-14 lg:mt-6" availabilities={availabilities} />;
    case 'not-licensed':
    case 'beginnable':
    case 'returnable':
    case 'resumable':
    case 'graduated':
      return emptyFragment();
    case 'upcoming':
      return <UpcomingAction experience={experienceResource.payload} />;
  }
}

export default observer(ExperienceActions);
