import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Typography } from '../../../../../../components/UI';
import SuccessIcon from '../../../../../../components/Cutesies/SuccessIcon';
import { emptyFragment } from '../../../../../../EmptyFragment';
import { T } from '../../../../../../Translations';
import PaymentConfirmationStore from '../../Store';
import TransactionNumber from '../../TransactionNumber';
import ExperienceDetailMessage from '../ExperienceDetailMessage';
import ModalButtonLabel from './ModalButtonLabel';

interface Props {
  store: PaymentConfirmationStore;
  paymentIntentId: string;
}

const PaidAndEnrolled: React.FC<Props> = ({ store, paymentIntentId }) =>
  store.enrollmentResource
    .map((resource) => (
      <div className="flex flex-col self-center content-center items-center h-full w-full">
        <SuccessIcon />
        <Typography
          className="tracking-wide text-center leading-none mt-8 md:mt-10 xl:mt-12"
          variant="h5"
          component="p"
          fontWeight="medium"
        >
          <T kind="Successfully Purchased Access" />
        </Typography>
        <div className="text-center mt-2.5">
          <TransactionNumber paymentIntentId={store.paymentIntentId} />
        </div>
        <div className="mt-14 mb-4">
          <ExperienceDetailMessage enrollment={resource.payload} />
        </div>
        <div className="text-center mt-8">
          <Button
            className="min-w-[330px]"
            size="lg"
            onClick={() => {
              store.readyToRedirect(store.enrollmentResource, paymentIntentId);
            }}
          >
            <ModalButtonLabel enrollment={resource.payload} />
          </Button>
        </div>
      </div>
    ))
    .getOrElse(emptyFragment);

export default observer(PaidAndEnrolled);
