import { observer } from 'mobx-react';
import { Channel } from 'pusher-js';
import * as React from 'react';
import PaymentConfirmationStore from '../../../Store';

interface Props {
  channel: Channel;
  store: PaymentConfirmationStore;
}

class PresenceBinding extends React.Component<Props, {}> {
  componentDidMount() {
    const { channel, store } = this.props;
    channel.bind('succeeded', (responseBody: unknown) => {
      switch (store.state.kind) {
        case 'initializing':
          break;
        case 'message-received':
        case 'waiting-for-message':
        case 'request-payment-status':
        case 'payment-status-requested':
          store.messageReceived('succeeded', responseBody, store.state.paymentIntentId);
          break;
        case 'paid-and-enrolled':
        case 'ready-to-redirect':
        case 'error':
        case 'pusher-error':
        case 'enrollment-error':
        case 'payment-error':
          break;
      }
    });

    channel.bind('processing', (responseBody: unknown) => {
      switch (store.state.kind) {
        case 'initializing':
          break;
        case 'waiting-for-message':
        case 'request-payment-status':
        case 'payment-status-requested':
          store.messageReceived('processing', responseBody, store.state.paymentIntentId);
          break;
        case 'message-received':
        case 'paid-and-enrolled':
        case 'ready-to-redirect':
        case 'error':
        case 'pusher-error':
        case 'enrollment-error':
        case 'payment-error':
          break;
      }
    });

    channel.bind('processing-timed-out', (responseBody: unknown) => {
      switch (store.state.kind) {
        case 'initializing':
          break;
        case 'waiting-for-message':
        case 'request-payment-status':
        case 'payment-status-requested':
          store.messageReceived('processing-timed-out', responseBody, store.state.paymentIntentId);
          break;
        case 'message-received':
        case 'paid-and-enrolled':
        case 'ready-to-redirect':
        case 'error':
        case 'pusher-error':
        case 'enrollment-error':
        case 'payment-error':
          break;
      }
    });

    channel.bind('server-error', (responseBody: unknown) => {
      switch (store.state.kind) {
        case 'initializing':
          break;
        case 'waiting-for-message':
        case 'request-payment-status':
        case 'payment-status-requested':
        case 'message-received':
          store.messageReceived('error', responseBody, store.state.paymentIntentId);
          break;
        case 'paid-and-enrolled':
        case 'ready-to-redirect':
        case 'error':
        case 'pusher-error':
        case 'enrollment-error':
        case 'payment-error':
          break;
      }
    });
  }

  componentWillUnmount() {
    const { channel } = this.props;
    channel.unbind_all();
  }

  render() {
    return null;
  }
}

export default observer(PresenceBinding);
