import React from 'react';
import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import { ExperienceResources, ProductCollection } from '../../../../../../Common/Experience/Types';
import EnrollmentStore from '../../../../../Enrollment/Store';
import FilterStore from '../../../../Filters/FilterStore';
import LanguageFilterStore from '../../../../Filters/LanguageFilter/Store';
import DurationFilterStore from '../../../../Filters/DurationFilter/Store';
import SchoolPartnerFilterStore from '../../../../Filters/SchoolPartnerFilter/Store';
import CompetencyFilterStore from '../../../../Filters/CompetencyFilter/Store';
import TileViewProgramExperience from '../TileViewProgramExperience';

interface Props {
  invitationUuid: string;
  experiences: ExperienceResources;
  productCollections: Maybe<NonEmptyList<ProductCollection>>;
  enrollmentStore: EnrollmentStore;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
}

const ShowExperiences: React.FC<Props> = ({
  invitationUuid,
  experiences,
  productCollections,
  enrollmentStore,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
}) => {
  return (
    <div className="mt-14 grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {experiences
        .filter((experience) =>
          filterStore.showFilteredExperience(
            experience,
            languageFilterStore,
            durationFilterStore,
            schoolPartnerFilterStore,
            competencyFilterStore,
          ),
        )
        .map((experience) => {
          return (
            <TileViewProgramExperience
              className="sm:max-w-full"
              key={experience.payload.experienceId}
              invitationUuid={invitationUuid}
              experience={experience}
              productCollections={productCollections}
              enrollmentStore={enrollmentStore}
            />
          );
        })}
    </div>
  );
};

export default observer(ShowExperiences);
