import { observer } from 'mobx-react';
import { CardBody, CardFooter, CardHeader, cn } from '@execonline-inc/execonline-ui';
import {
  ExperienceFooter,
  ExperienceHeader,
  getExperienceHeaderBackgroundByType,
} from '../../Experience';
import { ExperienceContextProvider } from '../../Experience/ExperienceContext';
import ExperienceHeaderEndIcon from '../../Experience/ExperienceHeader/ExperienceHeaderEndIcon';
import { ExperienceResource } from '../../Experience/Types';
import { ExperienceHeaderText, ProgramExperienceCard } from '../Common';
import ExperienceBody from './ExperienceBody';

interface Props {
  invitationUuid: string;
  experienceResource: ExperienceResource;
}

function TileViewProgramExperiencePreview({ invitationUuid, experienceResource }: Props) {
  return (
    <ExperienceContextProvider value={{ invitationUuid, experienceResource }}>
      <ProgramExperienceCard className="pointer-events-none h-full min-h-[14rem] w-[20rem] bg-white text-gray-800 shadow-tile-preview">
        <CardHeader
          className={cn(
            'min-h-6 rounded-t',
            getExperienceHeaderBackgroundByType(experienceResource.payload),
          )}
          startContent={<ExperienceHeaderText />}
          endContent={<ExperienceHeaderEndIcon />}
        >
          <ExperienceHeader />
        </CardHeader>
        <CardBody>
          <ExperienceBody />
        </CardBody>
        <CardFooter>
          <ExperienceFooter />
        </CardFooter>
      </ProgramExperienceCard>
    </ExperienceContextProvider>
  );
}

export default observer(TileViewProgramExperiencePreview);
