import {
  explicitMaybe,
  jsonValueDecoder,
  mergeObjectDecoders,
  stringLiteral,
} from '@execonline-inc/decoders';
import { toResult } from '@execonline-inc/maybe-adapter';
import { identity } from '@kofno/piper';
import Decoder, {
  array,
  boolean,
  dateISO,
  field,
  maybe,
  number,
  oneOf,
  string,
  succeed,
} from 'jsonous';
import { UploadedAttachment, UploadedAttachmentResource } from '../../AttachmentUploadStore/Types';
import { formFieldAssetResourceDecoder } from '../../components/EmbeddedFormFieldAsset/Decoders';
import {
  AnySlide,
  IncompleteLectureMissingPpt,
  LectureCommentary,
  LectureCommentaryResource,
  LectureParts,
  LecturePptAsset,
  LecturePptAssetResource,
  LectureSlideResource,
  LectureStep,
  LectureStepResource,
} from '../../LectureStore/Types';
import { PresentationParts } from '../../PresentationStore/Types';
import { resourceDecoder } from '../../Resource/Decoders';
import { Resource } from '../../Resource/Types';
import { SurveyParts } from '../../SurveyStore/Types';
import { TeamDiscussion, TeamDiscussionParts } from '../../TeamDiscussionStore/Types';
import { videoAssetResourceDecoder } from '../../VideoStore/Legacy/Decoders';
import { reqHlsVideoAssetResourceDecoder } from '../../VideoStore/ReqHls/Decoders';
import {
  AssignmentDueParts,
  Attachment,
  AttachmentKind,
  AttachmentResource,
  CompletabilityValue,
  CompletedExternalProgramParts,
  ConfirmationModal,
  DocumentAsset,
  DocumentAssetResource,
  ExternalProgram,
  ExternalProgramBase,
  ExternalProgramParts,
  ExternalProgramResource,
  ExternalProgramSegmentParts,
  LegacyAndNewVideoAssetResource,
  ModulePresentationStyle,
  Segment,
  SegmentBase,
  SegmentParts,
  SegmentResource,
  StartedExternalProgramParts,
  TimeCommitment,
  UnstartedExternalProgramParts,
  VideosShowOption,
  WebPageParts,
} from '../Types';
import { identifyVisibility } from './LectureSlides';
import { alreadyTranslatedText } from '@execonline-inc/translations';
import {
  competencyResourceDecoder,
  offeringTypeDecoder,
  schoolPartnerResourceDecoder,
} from '../../Native/AEP/DiscoveryPortal/ExperienceSelection/Experiences/Decoders';
import { fromArrayMaybe } from 'nonempty-list';
import { registrationStatusDecoder, registrationTypeDecoder } from '../../ProgramsStore/Decoders';

const attachmentKindDecoder: Decoder<AttachmentKind> = oneOf([
  stringLiteral<AttachmentKind>('video'),
  stringLiteral<AttachmentKind>('document'),
]);

const attachmentDecoder: Decoder<Attachment> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', string))
  .assign('kind', field('kind', attachmentKindDecoder));

export const attachmentResourceDecoder: Decoder<AttachmentResource> =
  resourceDecoder(attachmentDecoder);

const presentationPartsDecoder: Decoder<PresentationParts> = succeed({})
  .assign('type', field('type', stringLiteral('presentation')))
  .assign('results', field('results', jsonValueDecoder))
  .assign('height', field('height', number));

export const documentAssetDecoder: Decoder<DocumentAsset> = succeed({})
  .assign('uuid', field('uuid', string))
  .assign('title', field('title', string));

export const documentAssetResourceDecoder: Decoder<DocumentAssetResource> =
  resourceDecoder(documentAssetDecoder);

const lecturePptAssetDecoder: Decoder<LecturePptAsset> = succeed({})
  .assign('uuid', field('uuid', string))
  .assign('title', field('title', string));

const lecturePptAssetResourceDecoder: Decoder<LecturePptAssetResource> =
  resourceDecoder(lecturePptAssetDecoder);

const lectureSlideDecoder: Decoder<AnySlide> = succeed({})
  .assign('id', field('id', number))
  .assign('url', field('url', string))
  .assign('absolutePosition', field('position', number))
  .assign('time', field('time', number))
  .assign('title', field('title', string))
  .assign('visible', field('visible', boolean))
  .assign('videoonly', field('videoonly', boolean));

const lectureSlideResourceDecoder: Decoder<Resource<AnySlide>> =
  resourceDecoder(lectureSlideDecoder);

const lectureStepDecoder: Decoder<LectureStep> = succeed({})
  .assign('step', field('step', number))
  .assign('slide', field('slide', number))
  .assign('slideStep', field('slide_step', number))
  .assign('seconds', field('seconds', number))
  .assign('time', field('time', string))
  .assign('phrases', field('phrases', string))
  .assign('onscreen', field('onscreen', string))
  .assign('comments', field('comments', string));

const lectureStepResourceDecoder: Decoder<LectureStepResource> =
  resourceDecoder(lectureStepDecoder);

const lectureCommentaryDecoder: Decoder<LectureCommentary> = succeed({})
  .assign('id', field('id', number))
  .assign('startAt', field('start_at', number))
  .assign('endAt', field('end_at', number))
  .assign('content', field('content', string));

const lectureCommentaryResourceDecoder: Decoder<LectureCommentaryResource> =
  resourceDecoder(lectureCommentaryDecoder);

const lectureSlidesResourceDecoder: Decoder<LectureSlideResource[]> = array(
  lectureSlideResourceDecoder,
).map(identifyVisibility);

const lecturePartsDecoder: Decoder<LectureParts> = succeed({})
  .assign('type', field('type', stringLiteral('lecture')))
  .assign(
    'video',
    field(
      'video',
      oneOf<LegacyAndNewVideoAssetResource>([
        videoAssetResourceDecoder.map<LegacyAndNewVideoAssetResource>(identity),
        reqHlsVideoAssetResourceDecoder.map<LegacyAndNewVideoAssetResource>(identity),
      ]),
    ),
  )
  .assign(
    'ppt',
    field(
      'ppt',
      maybe(lecturePptAssetResourceDecoder).map(
        toResult<IncompleteLectureMissingPpt, LecturePptAssetResource>({
          kind: 'incomplete-lecture-missing-ppt',
        }),
      ),
    ),
  )
  .assign('slides', field('slides', lectureSlidesResourceDecoder))
  .assign('steps', field('steps', array(lectureStepResourceDecoder)))
  .assign('commentaries', field('commentaries', array(lectureCommentaryResourceDecoder)));

const uploadedAttachmentDecoder: Decoder<UploadedAttachment> = succeed({})
  .assign('id', field('id', number))
  .assign('filename', field('filename', string))
  .assign('complete', field('complete', boolean));

const uploadedAttachmentResourceDecoder: Decoder<UploadedAttachmentResource> =
  resourceDecoder(uploadedAttachmentDecoder);

const uploadedAttachmentsDecoder: Decoder<UploadedAttachmentResource[]> = oneOf([
  field('uploadedAttachments', array(uploadedAttachmentResourceDecoder)),
  field('uploaded_attachments', array(uploadedAttachmentResourceDecoder)),
]);

const assignmentDuePartsDecoder: Decoder<AssignmentDueParts> = succeed({})
  .assign('type', field('type', stringLiteral('assignment-due')))
  .assign('content', field('content', string))
  .assign('uploadedAttachments', uploadedAttachmentsDecoder)
  .assign(
    'embeddedVideoAssets',
    field(
      'embedded_video_assets',
      array(
        oneOf<LegacyAndNewVideoAssetResource>([
          videoAssetResourceDecoder.map<LegacyAndNewVideoAssetResource>(identity),
          reqHlsVideoAssetResourceDecoder.map<LegacyAndNewVideoAssetResource>(identity),
        ]),
      ),
    ),
  )
  .assign(
    'embeddedDocumentAssets',
    field('embedded_document_assets', array(documentAssetResourceDecoder)),
  )
  .assign(
    'embeddedFormFieldAssets',
    field('embedded_form_field_assets', array(formFieldAssetResourceDecoder)),
  );

const teamDiscussionDecoder: Decoder<TeamDiscussion> = succeed({})
  .assign('title', field('title', maybe(string)))
  .assign('content', field('content', maybe(string)));

const teamDiscussionPartsDecoder: Decoder<TeamDiscussionParts> = succeed({})
  .assign('type', field('type', stringLiteral('team-discussion')))
  .assign('content', field('content', string))
  .assign('teamDiscussion', field('team_discussion', maybe(teamDiscussionDecoder)))
  .assign('uploadedAttachments', uploadedAttachmentsDecoder)
  .assign('teamId', field('team_id', maybe(number)));

const videosShowOptionDecoder: Decoder<VideosShowOption> = oneOf([
  stringLiteral<VideosShowOption>('default'),
  stringLiteral<VideosShowOption>('full-width'),
]);

export const webPagePartsDecoder: Decoder<WebPageParts> = succeed({})
  .assign('type', field('type', stringLiteral('overview')))
  .assign('content', field('content', string))
  .assign('showVideosFullWidth', field('videos_show_option', videosShowOptionDecoder))
  .assign(
    'embeddedVideoAssets',
    field(
      'embedded_video_assets',
      array(
        oneOf<LegacyAndNewVideoAssetResource>([
          videoAssetResourceDecoder.map<LegacyAndNewVideoAssetResource>(identity),
          reqHlsVideoAssetResourceDecoder.map<LegacyAndNewVideoAssetResource>(identity),
        ]),
      ),
    ),
  )
  .assign(
    'embeddedDocumentAssets',
    field('embedded_document_assets', array(documentAssetResourceDecoder)),
  )
  .assign(
    'embeddedFormFieldAssets',
    field('embedded_form_field_assets', array(formFieldAssetResourceDecoder)),
  )
  .assign('results', field('results', jsonValueDecoder))
  .assign('isFreebusyCoaching', field('is_freebusy_coaching', boolean))
  .assign('sessionScheduled', field('session_scheduled', boolean));

export const surveyPartsDecoder: Decoder<SurveyParts> = succeed({}).assign(
  'type',
  field('type', stringLiteral('survey')),
);

const timeCommitmentDecoder: Decoder<TimeCommitment> = succeed({})
  .assign('duration', field('duration', explicitMaybe(number)))
  .assign('totalHours', field('total_hours', explicitMaybe(number)))
  .assign('hoursPerWeek', field('hours_per_week', explicitMaybe(number)));

export const externalProgramBaseDecoder: Decoder<ExternalProgramBase> = succeed({})
  .assign('id', field('id', number))
  .assign('offeringType', field('offering_type', offeringTypeDecoder))
  .assign('primaryColor', field('primary_color', string))
  .assign('secondaryColor', field('secondary_color', string))
  .assign('title', field('title', alreadyTranslatedText))
  .assign('description', field('description', alreadyTranslatedText))
  .assign('schoolPartner', field('school_partner', schoolPartnerResourceDecoder))
  .assign('timeCommitment', field('time_commitment', explicitMaybe(timeCommitmentDecoder)))
  .assign(
    'publicDetailedDescriptionHtml',
    field('public_detailed_description_html', explicitMaybe(alreadyTranslatedText)),
  )
  .assign(
    'whoShouldAttendHtml',
    field('who_should_attend_html', explicitMaybe(alreadyTranslatedText)),
  )
  .assign('keyTakeawaysHtml', field('key_takeaways_html', explicitMaybe(alreadyTranslatedText)))
  .assign(
    'programStructureAndFeaturesHtml',
    field('program_structure_and_features_html', explicitMaybe(alreadyTranslatedText)),
  )
  .assign('howItWorksHtml', field('how_it_works_html', explicitMaybe(alreadyTranslatedText)))
  .assign('primaryCompetencies', field('primary_competencies', array(competencyResourceDecoder)))
  .assign(
    'secondaryCompetencies',
    field('secondary_competencies', array(competencyResourceDecoder)),
  );

const startedExternalProgramPartsDecoder: Decoder<StartedExternalProgramParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('started')))
  .assign('percentComplete', field('percent_complete', number));

const completedExternalProgramPartsDecoder: Decoder<CompletedExternalProgramParts> = succeed(
  {},
).assign('kind', field('kind', stringLiteral('completed')));

const unstartedExternalProgramPartsDecoder: Decoder<UnstartedExternalProgramParts> = succeed(
  {},
).assign('kind', field('kind', stringLiteral('unstarted')));

export const externalProgramPartsDecoder: Decoder<ExternalProgramParts> =
  oneOf<ExternalProgramParts>([
    startedExternalProgramPartsDecoder.map<ExternalProgramParts>(identity),
    unstartedExternalProgramPartsDecoder.map<ExternalProgramParts>(identity),
    completedExternalProgramPartsDecoder.map<ExternalProgramParts>(identity),
  ]);

const externalProgramDecoder: Decoder<ExternalProgram> = mergeObjectDecoders(
  externalProgramBaseDecoder,
  externalProgramPartsDecoder,
);
const externalProgramResourceDecoder: Decoder<ExternalProgramResource> =
  resourceDecoder(externalProgramDecoder);

export const completeabilityDecoder: Decoder<CompletabilityValue> = oneOf([
  stringLiteral<CompletabilityValue>('completable'),
  stringLiteral<CompletabilityValue>('not-completable'),
]);

export const externalProgramSegmentPartsDecoder: Decoder<ExternalProgramSegmentParts> = succeed({})
  .assign('type', field('type', stringLiteral('external-program')))
  .assign('completability', field('completability', completeabilityDecoder))
  .assign(
    'externalProgramResources',
    field('external_program_resources', array(externalProgramResourceDecoder).map(fromArrayMaybe)),
  );

export const partsDecoder: Decoder<SegmentParts> = oneOf<SegmentParts>([
  webPagePartsDecoder.map<SegmentParts>(identity),
  surveyPartsDecoder.map<SegmentParts>(identity),
  presentationPartsDecoder.map<SegmentParts>(identity),
  teamDiscussionPartsDecoder.map<SegmentParts>(identity),
  assignmentDuePartsDecoder.map<SegmentParts>(identity),
  externalProgramSegmentPartsDecoder.map<SegmentParts>(identity),
  lecturePartsDecoder.map<SegmentParts>(identity),
]);

export const confirmationModalDecoder: Decoder<ConfirmationModal> = succeed({})
  .assign('title', field('title', string))
  .assign('content', field('content', string))
  .assign('button', field('button', string));

export const presentationStyleDecoder: Decoder<ModulePresentationStyle> = oneOf([
  stringLiteral<ModulePresentationStyle>('Full'),
  stringLiteral<ModulePresentationStyle>('Streamlined'),
]);

const baseSegmentPayloadDecoder: Decoder<SegmentBase> = succeed({})
  .assign('id', field('id', number))
  .assign('programId', field('course_id', number))
  .assign('moduleId', field('module_id', number))
  .assign('title', field('title', string))
  .assign('showCompletionCta', field('show_completion_cta', boolean))
  .assign('label', field('label', string))
  .assign('position', field('position', number))
  .assign('attachments', field('attachments', array(attachmentResourceDecoder)))
  .assign('status', field('status', maybe(string)))
  .assign('confirmationModal', field('confirmation_modal', explicitMaybe(confirmationModalDecoder)))
  .assign('presentationStyle', field('presentation_style', presentationStyleDecoder))
  .assign('studentId', field('student_id', number))
  .assign('registrationStatus', field('registration_status', registrationStatusDecoder))
  .assign('registrationType', field('registration_type', registrationTypeDecoder))
  .assign('learningCollectionId', field('learning_collection_id', explicitMaybe(number)))
  .assign(
    'learningCollectionName',
    field('learning_collection_name', explicitMaybe(alreadyTranslatedText)),
  )
  .assign('productLicenseId', field('product_license_id', explicitMaybe(number)))
  .assign('productLicenseType', field('product_license_type', explicitMaybe(alreadyTranslatedText)))
  .assign('programFamilyId', field('program_family_id', explicitMaybe(number)))
  .assign('programFamilyName', field('program_family_name', explicitMaybe(alreadyTranslatedText)))
  .assign('schoolPartnerId', field('school_partner_id', explicitMaybe(number)))
  .assign('schoolPartnerName', field('school_partner_name', explicitMaybe(alreadyTranslatedText)))
  .assign('enrollmentOpenDate', field('enrollment_open_date', explicitMaybe(dateISO)))
  .assign('duration', field('duration', explicitMaybe(number)))
  .assign('percentComplete', field('percent_complete', number))
  .assign('startsOn', field('starts_on', explicitMaybe(dateISO)))
  .assign('endsOn', field('ends_on', explicitMaybe(dateISO)))
  .assign('programTitle', field('program_title', string))
  .assign('programCreatedOn', field('program_created_on', explicitMaybe(dateISO)));

const segmentDecoder: Decoder<Segment> = mergeObjectDecoders(
  baseSegmentPayloadDecoder,
  partsDecoder,
);

export const segmentResourceDecoder: Decoder<SegmentResource> = resourceDecoder(segmentDecoder);
