import { findPayload } from '@execonline-inc/collections';
import { warn } from '@execonline-inc/logging';
import { parseIntM } from '@execonline-inc/numbers';
import { noop } from '@kofno/piper';
import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { emptyFragment } from '../../EmptyFragment';
import EventsStore from '../../EventsStore';
import { calendarEventStore } from '../../EventsStore/CalendarEventStore';
import EventsReactions from '../../EventsStore/EventsReactions';
import { EventResource, EventsResource } from '../../EventsStore/Types';
import { NotTranslated } from '../../Translations';
import Loader from '../Loader';
import * as platformStyle from '../Platform/style.module.css';
import Lobby from './Lobby';
import { clearWindowName, setWindowName } from './Types';

interface Params {
  eventId: string;
}

interface Props {
  match: { params: Params };
  currentUserResource: CurrentUserResource;
}

const eventResource = (eventsResource: EventsResource, eventId: number): Maybe<EventResource> =>
  just(eventsResource.payload).andThen(findPayload(eventId));

class EventConference extends React.Component<Props> {
  params = this.props.match.params;
  eventsStore: EventsStore = new EventsStore(this.props.currentUserResource);

  componentDidMount() {
    setWindowName.fork((err) => warn(err), noop);
  }

  componentWillUnmount() {
    clearWindowName.fork((err) => warn(err), noop);
  }

  render() {
    const state = this.eventsStore.state;

    return (
      <>
        <EventsReactions store={this.eventsStore} fireImmediately={true} />
        {parseIntM(this.params.eventId)
          .map((eventId: number) => {
            switch (state.kind) {
              case 'waiting':
              case 'loading':
                return (
                  <div className={platformStyle.contentLoader}>
                    <Loader />
                  </div>
                );
              case 'ready':
              case 'refresh':
                return eventResource(state.eventsResource, eventId)
                  .map(calendarEventStore)
                  .map((store) => <Lobby eventId={eventId} store={store} />)
                  .getOrElse(() => <Redirect to="/404" />);
              case 'error':
                return (
                  <div>
                    <NotTranslated text={state.message} />
                  </div>
                );
            }
          })
          .getOrElse(emptyFragment)}
      </>
    );
  }
}

export default observer(EventConference);
