import clsx from 'clsx';
import { fromEmpty, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { resetPasswordRoute } from '../../ClientRoutes';
import { T } from '../../Translations';
import Button from '../../components/Form/Button';
import InputWithLabel from '../../components/Form/InputWithLabel';
import { LoginStore } from '../Store';
import LoginFormWrapper from './LoginFormWrapper';
import * as style from './style.module.css';

interface Props {
  store: LoginStore;
  username: string;
}

const PasswordForm: React.FC<Props> = ({ store, username }) => (
  <LoginFormWrapper store={store}>
    <form name="password_login_form">
      <div className={clsx('my-8 ml-0 mr-0 w-full text-left text-neutral-900')}>{username}</div>
      <div className="my-auto ml-0 mr-0">
        <input hidden={true} value={username} readOnly={true} autoComplete="username" />
        <InputWithLabel
          labelClassName={clsx(style.labelDark, 'text-neutral-900')}
          labelText="Password"
          autoFocus={true}
          type="password"
          onChange={setPassword(store)}
          name="password"
          id="login_password"
          className={clsx(style.input, 'box-border w-full text-neutral-900')}
          autoComplete="current-password"
          disabled={store.formShouldBeDisabled}
        />
      </div>
      <Link
        className={clsx(
          'mb-5 mt-1 block text-left font-medium',
          'text-neutral-900 opacity-80 hover:text-neutral-900 hover:opacity-100',
        )}
        to={resetPasswordRoute}
        data-test-forgot-password-link={true}
      >
        <T kind="I forgot my password" />
      </Link>
      <div className={style.actions}>
        <Button
          type="reset"
          value="Go Back"
          onClick={cancel(store)}
          className="mx-0 my-3 flex-auto border border-solid border-gray-600-old bg-transparent text-gray-600-old hover:border-gray-800-old hover:bg-gray-50-old hover:text-gray-800-old md:mr-2"
          disabled={store.formShouldBeDisabled}
        />
        <Button
          type="submit"
          name="Login"
          value="Login to Your Account"
          onClick={submit(store)}
          className={clsx(style.button, 'mx-0 my-3 bg-green-primary text-white hover:bg-[#187723]')}
          disabled={store.formShouldBeDisabled}
        />
      </div>
      <br />
    </form>
  </LoginFormWrapper>
);

export default observer(PasswordForm);

const setPassword =
  (store: LoginStore) =>
  (event: React.FormEvent<HTMLInputElement>): void =>
    store.awaitingPassword({ password: fromEmpty(event.currentTarget.value), error: nothing() });

const cancel =
  (store: LoginStore) =>
  (event: React.FormEvent<HTMLInputElement>): void => {
    event.preventDefault();
    store.awaitingUsername({ username: nothing(), error: nothing() });
  };

const submit =
  (store: LoginStore) =>
  (event: React.FormEvent<HTMLInputElement>): void => {
    event.preventDefault();
    store.submittingPassword();
  };
