import { observer } from 'mobx-react';
import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { BeginnableAction } from '../../../../Common/Experience/ExperienceActions';
import {
  ExperienceResource,
  GroupCoachingProductDetails,
} from '../../../../Common/Experience/Types';
import EnrollableAction from './EnrollableAction';

interface Props {
  experienceResource: ExperienceResource;
  productDetails: GroupCoachingProductDetails;
}

const beginnableButtonProps: ButtonProps = {
  className: 'min-w-full bg-white sm:min-w-48',
  radius: 'md',
  variant: 'text',
  size: 'lg',
};

function ExperienceActions({ experienceResource, productDetails }: Props) {
  switch (experienceResource.payload.kind) {
    case 'enrollable':
    case 'commerce-enrollable':
      return <EnrollableAction productDetails={productDetails} />;
    case 'beginnable':
      return (
        <div className="mt-10">
          <BeginnableAction
            experience={experienceResource.payload}
            buttonProps={beginnableButtonProps}
          />
        </div>
      );
    case 'returnable':
    case 'resumable':
    case 'not-enrollable':
    case 'not-licensed':
    case 'graduated':
    case 'upcoming':
      return emptyFragment();
  }
}
export default observer(ExperienceActions);
