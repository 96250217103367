import { hours } from '@execonline-inc/time';
import { observer } from 'mobx-react';
import { InactivityLogoutTracker } from '../../../Inactivity/Core';
import SegmentStore from '../../../SegmentStore';
import Loader from '../../Loader';
import DownloadButton from './DownloadableButton';
import SegmentDetail from './SegmentDetail';
import * as style from './style.module.css';

interface Props {
  store: SegmentStore;
}

function SegmentContent({ store }: Props) {
  InactivityLogoutTracker.useInactivityExtension(hours(1));

  switch (store.state.kind) {
    case 'ready':
    case 'processing-request':
    case 'completing':
    case 'reporting-results':
    case 'auto-saving-form-fields':
    case 'schedule-session':
      return (
        <>
          <DownloadButton segmentResource={store.state.segmentResource} />
          <SegmentDetail segmentResource={store.state.segmentResource} store={store} />
          <DownloadButton segmentResource={store.state.segmentResource} />
        </>
      );
    case 'completing-and-advancing':
    case 'submitting-and-advancing':
    case 'advancing':
    case 'advancing-to':
      return (
        <>
          <div className={style.reloaderLightTheme}>
            <Loader color="black" type="spin" />
          </div>
          <div className={style.shadeLightTheme} />
        </>
      );
    case 'error':
    case 'waiting':
    case 'loading':
    case 'loaded':
      return <Loader color="black" type="spin" />;
  }
}

export default observer(SegmentContent);
