import { mergeObjectDecoders, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, date, field, maybe, number, oneOf, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { attachmentResourceDecoder } from '../../ResourceStore/Decoders';
import {
  AssignmentBase,
  AssignmentParts,
  AssignmentResource,
  Document,
  ProcessingStatus,
  ResourceResource,
  TeamDiscussionParts,
  TeamResource,
  TeamResourceResource,
  TeamResourcesResource,
  UserSegmentParts,
} from '../Types';

const processingStatusDecoder: Decoder<ProcessingStatus> = oneOf([
  stringLiteral<ProcessingStatus>('completed'),
  stringLiteral<ProcessingStatus>('processing'),
]);

const baseResourceDecoder: Decoder<Document> = succeed({})
  .assign('kind', field('kind', stringLiteral('document')))
  .assign('id', field('id', number))
  .assign('title', field('title', string))
  .assign('processingStatus', field('processing_status', processingStatusDecoder))
  .assign('createdAt', field('created_at', date))
  .assign('attachment', field('attachment', attachmentResourceDecoder));

export const resourceResourceDecoder: Decoder<ResourceResource> = resourceDecoder(
  baseResourceDecoder
);

const baseAssignmentDecoder: Decoder<AssignmentBase> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', string))
  .assign('label', field('label', string))
  .assign('content', field('content', string))
  .assign('studentName', field('student', maybe(string)))
  .assign('resources', field('resources', array(resourceResourceDecoder)));

const userSegmentPartsDecoder: Decoder<UserSegmentParts> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('user-segment'))
);

const teamDiscussionPartsDecoder: Decoder<TeamDiscussionParts> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('team-discussion'))
);

export const assignmentPartsDecoder: Decoder<AssignmentParts> = oneOf<AssignmentParts>([
  userSegmentPartsDecoder.map<AssignmentParts>(identity),
  teamDiscussionPartsDecoder.map<AssignmentParts>(identity),
]);

export const userSegmentDecoder = mergeObjectDecoders(
  baseAssignmentDecoder,
  userSegmentPartsDecoder
);

export const assignmentDecoder = mergeObjectDecoders(baseAssignmentDecoder, assignmentPartsDecoder);

export const assignmentResourceDecoder: Decoder<AssignmentResource> = resourceDecoder(
  assignmentDecoder
);

const teamResourceDecoder: Decoder<TeamResource> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', string))
  .assign('assignments', field('assignments', array(assignmentResourceDecoder)));

const teamResourceResourceDecoder: Decoder<TeamResourceResource> = resourceDecoder(
  teamResourceDecoder
);

export const teamResourcesResourceDecoder: Decoder<TeamResourcesResource> = resourceDecoder(
  array(teamResourceResourceDecoder)
);
