import { observer } from 'mobx-react';
import { Button } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';
import { useMediaQuery } from '../../../../../../../components/UI';
import { useExperienceEnrollmentContext } from '../../../../../Common/ProgramExperienceEnrollment';
import { getButtonSize } from './Types';

function GetStartedButton() {
  const { onOpen } = useExperienceEnrollmentContext();
  const breakPoint = useMediaQuery();

  return (
    <Button
      className="min-w-full bg-white sm:min-w-48"
      color="gray"
      radius="md"
      variant="text"
      size={getButtonSize(breakPoint)}
      onClick={onOpen}
    >
      <T kind="Get Started" />
    </Button>
  );
}

export default observer(GetStartedButton);
