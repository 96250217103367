import { observer } from 'mobx-react';
import * as React from 'react';
import { emptyFragment } from '../../../../../EmptyFragment';
import ProgramStore from '../../../../../ProgramStore';
import { CoachingProductDetails } from '../../../../../ProgramStore/Types';
import ModalStore from '../../../../Modal/Store';
import * as style from '../../style.module.css';
import CoachInfo from '../CoachInfo';
import ProgramAccessUntil from '../ProgramAccessUntil';
import ProgramTitle from '../ProgramTitle';
import ScheduleSessionButton from '../ScheduleSessionButton';
import SessionsHistory from '../SessionsHistory';
import UpcomingSessions from '../UpcomingSessions';
import CoachViewProfileModal from './CoachViewProfileModal';
import { whenGt } from '@execonline-inc/numbers';
import { Maybe } from 'maybeasy';

interface Props {
  programStore: ProgramStore;
  coachingProductDetails: CoachingProductDetails;
  modalStore: ModalStore;
}

const whenUserHasActiveLicense = (programStore: ProgramStore): Maybe<number> =>
  programStore.remainingDays.andThen(whenGt(0));

const CompletedOrientation: React.FC<Props> = ({
  programStore,
  coachingProductDetails,
  modalStore,
}) => {
  return (
    <div className={style.programTile}>
      {programStore.title.map((title) => <ProgramTitle title={title} />).getOrElse(emptyFragment)}
      <div className={style.programTileContent}>
        <div className={style.flexWrapper}>
          <UpcomingSessions
            coachingProductDetails={coachingProductDetails}
            programStore={programStore}
          />
          <div className={style.flexColumn}>
            <div className={style.flexWrapper}>
              {coachingProductDetails.selectedCoach
                .map((coachProfile) => (
                  <CoachInfo
                    coachProfile={coachProfile}
                    modalStore={modalStore}
                    programStore={programStore}
                  />
                ))
                .getOrElse(emptyFragment)}
              <SessionsHistory coachingProductDetails={coachingProductDetails} />
            </div>
            <ProgramAccessUntil programStore={programStore} />
          </div>
        </div>
        {coachingProductDetails.selectedCoach
          .map((coachProfile) => (
            <CoachViewProfileModal coachProfile={coachProfile} modalStore={modalStore} />
          ))
          .getOrElse(emptyFragment)}
      </div>
      {whenUserHasActiveLicense(programStore)
        .map(() => <ScheduleSessionButton programStore={programStore} />)
        .getOrElse(emptyFragment)}
    </div>
  );
};
export default observer(CompletedOrientation);
