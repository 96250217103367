import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import * as React from 'react';
import { emptyFragment } from '../../../EmptyFragment';
import { programsStore } from '../../../ProgramsStore';
import { upcomingProgramResource, visibleProgramResource } from '../../../ProgramsStore/Functions';
import { T } from '../../../Translations';
import ProgramDashboards from '../ProgramDashboard/ProgramDashboards';
import * as style from '../style.module.css';

const UpcomingPrograms: React.FC<{}> = () => {
  return (
    <div className={style.upcoming} data-test-upcoming-programs={true}>
      {programsStore.resource
        .map(({ payload }) =>
          payload.programs.filter(upcomingProgramResource).filter(visibleProgramResource),
        )
        .andThen(fromArrayMaybe)
        .map((upcomingPrograms) => (
          <>
            <h2 data-test-upcoming-programs-title="Upcoming">
              <T kind="Upcoming" />
            </h2>
            <ProgramDashboards programs={upcomingPrograms} />
          </>
        ))
        .getOrElse(emptyFragment)}
    </div>
  );
};

export default observer(UpcomingPrograms);
