import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { emptyFragment } from '../../../EmptyFragment';
import { T } from '../../../Translations';
import CheckmarkIcon from '../../Cutesies/CheckmarkIcon';
import { WaffleIconListItem } from '../Types';
import * as style from './style.module.css';

interface Props {
  item: WaffleIconListItem;
}

const WaffleListDropdownItem: React.FC<Props> = ({ item }) => {
  const itemAttrs = when(item.check, item)
    .map<React.AnchorHTMLAttributes<HTMLAnchorElement>>(() => ({
      onClick: (e) => e.preventDefault(),
    }))
    .getOrElse(() => ({
      href: item.href,
      rel: 'noopener noreferrer',
    }));

  return (
    <li className={style.waffle}>
      <a
        {...itemAttrs}
        className={clsx(
          style.waffleItemLink,
          'justify-items-start',
          'px-3',
          'flex justify-around',
          'text-xs font-medium leading-6 text-gray-900-old',
        )}
      >
        {item.icon}
        <span className={'pl-3 text-lg'}>
          <T kind={item.name} />
        </span>
        {when(item.check, {})
          .map(() => (
            <div className={clsx('pl-24 pr-4')}>
              <CheckmarkIcon />
            </div>
          ))
          .getOrElse(emptyFragment)}
      </a>
    </li>
  );
};

export default observer(WaffleListDropdownItem);
