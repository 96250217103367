import { observer } from 'mobx-react';
import Image from '../Image';
import SizedImage from '../SizedImage';
import { proportionalImageWidth } from '../SizedImage/SizedImageStore/Types';

interface Props {
  href: string;
  alt: string;
  height: number;
}

const ProportionalImage: React.FC<Props> = ({ href, alt, height }) => (
  <SizedImage uri={href}>
    {(src, dimensions) => (
      <Image
        style={{ height, width: proportionalImageWidth(dimensions, height) }}
        className="max-w-full object-contain"
        src={src}
        alt={alt}
      />
    )}
  </SizedImage>
);

export default observer(ProportionalImage);
