import * as React from 'react';
import Confirmation from './Confirmation';
import Schedule from './Schedule';
import FreeBusyTestStore from '../../FreeBusyTestStore';
import { observer } from 'mobx-react';

interface Props {
  store: FreeBusyTestStore;
}

const FreeBusyTestView: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'waiting':
    case 'error':
      return <></>;
    case 'loading':
    case 'initializing':
    case 'ready':
      return <Schedule />;
    case 'submitted':
      return <Confirmation />;
  }
};

export default observer(FreeBusyTestView);
