import { observer } from 'mobx-react';
import NotSupportedInLanguageStore from '../../../../../NotSupportedInLanguageStore';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import LanguageUnsupportedMessage from './LanguageUnsupportedMessageSwitcher';

interface Props {
  experienceNotSupportedInLanguageStore: NotSupportedInLanguageStore;
}

function ExperienceNotSupportedInLanguageMessage({ experienceNotSupportedInLanguageStore }: Props) {
  switch (experienceNotSupportedInLanguageStore.state.kind) {
    case 'ready':
      return (
        <LanguageUnsupportedMessage
          experienceNotSupportedInLanguageStore={experienceNotSupportedInLanguageStore}
        />
      );
    case 'hidden':
      return emptyFragment();
  }
}

export default observer(ExperienceNotSupportedInLanguageMessage);
