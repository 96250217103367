import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserContext } from '..';
import ErrorBoundary from '../../../ErrorHandling/ErrorBoundary';
import Children from './Children';
import { onError } from './Functions';
import { Props } from './Types';

class WithCurrentUser extends React.Component<Props> {
  render() {
    return (
      <ErrorBoundary onError={onError}>
        <CurrentUserContext.Consumer
          children={(ctx) => <Children ctx={ctx} children={this.props.children} />}
        />
      </ErrorBoundary>
    );
  }
}

export default observer(WithCurrentUser);
