import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { dashboardUrl } from '../../../ClientRoutes';
import EditPasswordStore from '../../../EditPasswordStore';
import { T } from '../../../Translations';
import Button from '../../Form/Button';
import Input from '../../Form/Input';
import * as style from '../../ResetPassword/style.module.css';

interface Props {
  store: EditPasswordStore;
}

const Form: React.FC<Props> = ({ store }) => {
  const setPassword = (event: React.FormEvent<HTMLInputElement>) => {
    store.setPassword(event.currentTarget.value);
  };

  const setPasswordConfirmation = (event: React.FormEvent<HTMLInputElement>) => {
    store.setPasswordConfirmation(event.currentTarget.value);
  };

  const submit = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    store.creating();
  };

  switch (store.state.kind) {
    case 'creating':
    case 'ready':
    case 'ready-with-client-error':
    case 'ready-with-server-error':
      return (
        <>
          <div>
            <form className={style.editPasswordForm}>
              <div className={style.field}>
                <label className={style.label} htmlFor="password">
                  <T kind="Password" />
                </label>
                <Input
                  autoFocus={true}
                  type="password"
                  onChange={setPassword}
                  name="password"
                  id="password"
                  data-test-input-email={true}
                  className={style.input}
                  value={store.password.getOrElseValue('')}
                />
              </div>
              <div className={style.field}>
                <label className={style.label} htmlFor="password_confirmation">
                  <T kind="Confirm Password" />
                </label>
                <Input
                  type="password"
                  onChange={setPasswordConfirmation}
                  name="passwordConfirmation"
                  id="passwordConfirmation"
                  data-test-input-email={true}
                  className={style.input}
                  value={store.passwordConfirmation.getOrElseValue('')}
                />
              </div>
              <div className={style.actions}>
                <Button
                  type="submit"
                  value="Set New Password"
                  onClick={submit}
                  className={style.button}
                  data-test-action-reset-password={true}
                />
              </div>
            </form>
          </div>
          <br />
          <div>
            <Link to={dashboardUrl} className={style.link} data-test-login-link={true}>
              <T kind="Sign In" />
            </Link>
          </div>
        </>
      );
    case 'loading':
    case 'waiting':
    case 'error':
      return <></>;
  }
};
export default observer(Form);
