import { observer } from 'mobx-react';
import { ExperienceResource } from '../../Common/Experience/Types';
import EnrollmentStore from '../../DiscoveryPortal/Enrollment/Store';
import ExperienceDetailViewStore from '../ExperienceDetailViewStore';
import ExperienceDetailsBody from './ExperienceDetailsBody';
import { ExperienceContextProvider } from '../../Common/Experience/ExperienceContext';

interface Props {
  experienceResource: ExperienceResource;
  experienceDetailViewStore: ExperienceDetailViewStore;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
}

function ExperienceDetailsBodyFactory({
  experienceResource,
  experienceDetailViewStore,
  invitationUuid,
  enrollmentStore,
}: Props) {
  return (
    <div className="bg-white">
      <div className="mx-auto xl:container">
        <ExperienceContextProvider value={{ invitationUuid, experienceResource }}>
          <ExperienceDetailsBody experienceResource={experienceResource} />
        </ExperienceContextProvider>
      </div>
    </div>
  );
}

export default observer(ExperienceDetailsBodyFactory);
