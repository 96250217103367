import { assertNever } from '@kofno/piper';
import PromotablesStore, { State } from './PromotablesStore';
import { AppyError, callApi } from '../../../../Appy';
import { promotablesResourceDecoder } from './Decoders';
import ReactionComponent, { RCProps } from '../../../../ReactionComponent';
import { handleError } from '../../../../ErrorActionableReaction';
import { warnAndNotify } from '@execonline-inc/logging';
import { when } from '@execonline-inc/maybe-adapter';
import { equals } from '@execonline-inc/collections';
import { nothing } from 'maybeasy';

const endPoint = callApi(promotablesResourceDecoder, {});

interface Props extends RCProps<PromotablesStore> {
  store: PromotablesStore;
}

const whenPromotablesNotFound = (error: AppyError) => {
  switch (error.kind) {
    case 'bad-status':
      return when(equals(error.response.status, 404), {});
    case 'bad-payload':
    case 'bad-url':
    case 'missing-api-compatibility':
    case 'missing-application-id':
    case 'network-error':
    case 'timeout':
      return nothing();
  }
};
const handleRequestError = (store: PromotablesStore) => (error: AppyError) =>
  whenPromotablesNotFound(error)
    .do(store.noPromotables)
    .elseDo(() => {
      warnAndNotify(store.error);
      return handleError(store, error);
    });
class PromotablesReactions extends ReactionComponent<PromotablesStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'loading':
        endPoint(store.link).fork(handleRequestError(store), store.ready);
        break;
      case 'ready':
      case 'no-promotables':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default PromotablesReactions;
