import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import CardBody from './CardBody';
import CardHeader from './CardHeader';
import CardFooter from './CardFooter';
import CardTitle from './CardTitle';
import CardSubtitle from './CardSubtitle';

interface Props extends HTMLAttributes<HTMLDivElement> {}

const Card: React.FC<Props> = ({ className, children, ...others }) => {
  return (
    <div
      className={twMerge(
        clsx('box-border flex flex-col px-2.5 py-2 lg:px-[18px] lg:py-4', className),
      )}
      {...others}
    >
      {children}
    </div>
  );
};

export default Object.assign(Card, {
  Header: CardHeader,
  Title: CardTitle,
  Subtitle: CardSubtitle,
  Body: CardBody,
  Footer: CardFooter,
});
