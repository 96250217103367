import { observer } from 'mobx-react';
import * as React from 'react';
import Footer from '../Footer';
import ThemeContext from '../../Themes/ThemeContext';
import clsx from 'clsx';
import * as style from './style.module.css';
interface Props {
  children: JSX.Element | JSX.Element[];
}

const WithBackgroundImage: React.FC<Props> = ({ children }) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <div
          className={clsx('bg-cover bg-fixed', 'bg-center min-h-screen w-screen')}
          style={{
            backgroundImage: `url("/p3/${theme.images['login-bg']}")`,
            backgroundColor: theme.colors['body-bg'],
          }}
        >
          <div className={clsx(style.content, 'flex justify-center')}>
            <div className={clsx('p-12 text-center')}>{children}</div>
          </div>
          <Footer />
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default observer(WithBackgroundImage);
