import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import { emptyFragment } from '../../../EmptyFragment';
import { findLink } from '../../../LinkyLinky';
import { T } from '../../../Translations';
import * as style from '../style.module.css';

const ContactSupport: React.FC = () => (
  <WithCurrentUser
    children={(currentUserResource) =>
      findLink('support', currentUserResource.links)
        .map((l) => {
          return (
            <>
              <p>
                <T kind="Still having problems?" />
              </p>
              <div className={style.contactSupportButtonWrapper}>
                <a
                  href={l.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-test-conacat-support={true}
                >
                  <button type="button" className={style.contactSupportButton}>
                    <T kind="Contact Support" />
                  </button>
                </a>
              </div>
            </>
          );
        })
        .getOrElse(emptyFragment)
    }
  />
);

export default observer(ContactSupport);
