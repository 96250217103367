import { observer } from 'mobx-react';
import * as React from 'react';
import CountryOptionsStore from '../../../../CountryOptionsStore';
import { emptyFragment } from '../../../../EmptyFragment';
import ProfileFormStore from '../../../../ProfileFormStore';
import CountrySelector from './CountrySelector';
import * as style from './style.module.css';

interface Props {
  profileStore: ProfileFormStore;
  countryOptionsStore: CountryOptionsStore;
}

const CountrySelectContainer: React.FC<Props> = ({ profileStore, countryOptionsStore }) => (
  <div className={style.labelAndInput}>
    {countryOptionsStore.countryOptions
      .map((countries) => <CountrySelector profileStore={profileStore} countries={countries} />)
      .getOrElse(emptyFragment)}
  </div>
);

export default observer(CountrySelectContainer);
