import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { GraduatedExperience } from '../../../../Common/Experience/Types';
import { T } from '../../../../../../Translations';
import { dateToLocalDateTime } from '../../../HeroSection/CurrentlyEnrolledSection/ConvertDate';

interface Props {
  experience: GraduatedExperience;
}

function GraduatedCardHeader({ experience }: Props) {
  return (
    <>
      <Typography variant="h5" color="white">
        <T kind="Graduated" />
      </Typography>
      <Typography className="mt-3" color="white" variant="bodySmall">
        <T
          kind="Graduated from <span>{{date}}</span> cohort."
          date={dateToLocalDateTime(experience.startOn)}
          span={(value) => (
            <Typography color="white" variant="bodySmall" fontWeight="bold" as="span">
              {value}
            </Typography>
          )}
        />
      </Typography>
    </>
  );
}

export default observer(GraduatedCardHeader);
