import { observer } from 'mobx-react';
import * as React from 'react';
import PaymentConfirmationStore from '../Store';
import Error from './Error';
import PaidAndEnrolled from './PaidAndEnrolled';
import Processing from './Processing';
import Redirect from './Redirect';
import StillProcessing from './StillProcessing';

interface Props {
  store: PaymentConfirmationStore;
}

const Confirm: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'initializing':
      return <></>;
    case 'error':
      return (
        <Error
          message={store.state.message}
          detail={'Something went wrong. Please contact support@execonline.com for assistance'}
        />
      );
    case 'pusher-error':
    case 'enrollment-error':
    case 'payment-error':
      return <Error message={store.state.message} detail={store.state.detail} />;
    case 'waiting-for-message':
    case 'request-payment-status':
    case 'payment-status-requested':
    case 'message-received':
      return <Processing store={store} />;
    case 'payment-still-processing':
      return <StillProcessing store={store} />;
    case 'paid-and-enrolled':
      return <PaidAndEnrolled store={store} paymentIntentId={store.state.paymentIntentId} />;
    case 'ready-to-redirect':
      return <Redirect store={store} />;
  }
};

export default observer(Confirm);
