import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useContext } from 'react';
import { emptyFragment } from '../../EmptyFragment';
import { TeamsStoreContext } from '../../TeamsStore/TeamsStoreContext';
import { NotTranslated } from '../../Translations';
import ContentFaker from '../ContentFaker';
import TeamMembersContent from './TeamMembersContent';

interface Props {
  teamId: Maybe<number>;
}

const TeamMembersListView: React.FC<Props> = ({ teamId }) => {
  const teamsStore = useContext(TeamsStoreContext);

  switch (teamsStore.state.kind) {
    case 'ready':
    case 'loading-more': {
      return teamId.map((id) => <TeamMembersContent teamId={id} />).getOrElse(emptyFragment);
    }
    case 'loading':
    case 'waiting': {
      return (
        <>
          <ContentFaker />
        </>
      );
    }
    case 'error': {
      return (
        <div>
          <NotTranslated text={teamsStore.state.message} />
        </div>
      );
    }
  }
};

export default observer(TeamMembersListView);
