import React, { HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface Props extends HTMLAttributes<HTMLParagraphElement> {}

const ListItemText: React.FC<Props> = ({ className, children, ...others }) => {
  return (
    <div className={twMerge(clsx('min-w-0 flex-auto', className))} {...others}>
      {children}
    </div>
  );
};

export default observer(ListItemText);
