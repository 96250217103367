import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '../../components/UI';
import Main from '../../BrowserSupport/Main';
import Title from '../../Title';
import { T } from '../../Translations';
import CenteredLogo from '../../components/CenteredLogo';
import LinkedMobileApp from '../../components/LinkedMobileApp';
import WithBackgroundImage from '../../components/WithBackgroundImage';
import { LoginStore } from '../Store';
import Notification from './Notification';

interface Props {
  store: LoginStore;
}

const LoginFormWrapper: React.FC<Props> = ({ store, children }) => (
  <WithBackgroundImage>
    <Main className="mt-12">
      <Title title="Login" />
      <Notification store={store} />
      <CenteredLogo />
      <Typography
        className="mb-8"
        variant="h4"
        component="h1"
        fontWeight="medium"
        data-test-title={true}
      >
        <T kind="Login to Your Account" />
      </Typography>
      {children}
      <LinkedMobileApp />
    </Main>
  </WithBackgroundImage>
);

export default observer(LoginFormWrapper);
